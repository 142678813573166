.input-element {
  font-size: $font-xs;
  font-weight: $weight-normal;
  border: $border-clear;
  border-radius: $radius-s;
  padding: 1.55rem 1.6rem;
  line-height: 1;

  &.input-element-select {
    @include flex-between;
    cursor: pointer;
    &::after {
      content: "\FA2C";
      display: inline-block;
      font-family: kfont;
      font-size: $font-xxs;
      color: $principale;
      transform: rotate(-0.75turn);
      transition: color .3s ease-in-out;
    }
  }

  &::placeholder {
    color: $principale;
    opacity: 1;
  }

  &[type="checkbox"] {
    position: relative;
    appearance: none;
    min-width: 1.8rem;
    height: 1.8rem;
    background-color: $blanc;
    border: $border-grey;
    border-radius: $radius-xs;
    outline: none;
    padding: 0;
    cursor: pointer;
  }

  &[type="checkbox"]:checked::before {
    content: "\FA2B";
    font-family: kfont;
    font-size: .8rem;
    border-radius: .2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

//Custom select
.custom-select {
  width: 20rem;
  position: relative;
  background-color: $blanc;
  border-radius: $radius-s;

  select {
    display: none;
  }

  .input-element {
    min-height: 5rem;
  }

  /* Style items (options): */
  .select-items {
    position: absolute;
    background-color: $blanc;
    top: 100%;
    left: 0;
    right: 0;
    border: $border-clear;
    border-radius: $radius-s;
    z-index: 99;

    /* style the items (options), including the selected item: */
    .option-item {
      font-size: $font-xs;
      padding: .8rem 1.6rem;
      cursor: pointer;
      &:not(:last-child) {
        border-bottom: $border-clear;
      }
      &:hover, &.same-as-selected{
        background-color: $neutre_gris_300;
        &:first-child {
          border-top-left-radius: .3rem;
          border-top-right-radius: .3rem;
        }
        &:last-child {
          border-bottom-left-radius: .3rem;
          border-bottom-right-radius: .3rem;
        }
      }
    }
  }
  /* Hide the items when the select box is closed: */
  .select-hide {
    display: none;
  }
}

/* Custom filtre select */
.custom-filtre-select{
  display: inline-block;
  width: auto;
  background-color: $neutre_gris_100;
  border-color: $neutre_gris_300;
  border-radius: 4.8rem;
  .input-element {
    border-radius: 4.8rem;
    padding: 1.2rem 1.6rem;
    min-height: 0;
    &::after {
      margin-top: .4rem;
      margin-left: 1.2rem;
    }
  }
}

.custom-switch {
  position: relative;
  width: 4.4rem;
  height: 2.6rem;
  -webkit-appearance: none;
  background: #c6c6c6;
  outline: none;
  border-radius: 20px;
  transition: 0.7s;
  cursor: pointer;

  &:checked {
    background-color: $principale;
  }

  &::before {
    content: '';
    position: absolute;
    width: 2rem;
    height: 2rem;
    border-radius: 20px;
    top: 50%;
    left: .3rem;
    background: $blanc;
    transform: scale(1.1);
    transform: translateY(-50%);
    transition: .3s;
  }

  &:checked::before {
    left: 2.1rem;
  }
}
