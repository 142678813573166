/* NEW */
.espace-client {
  display: flex;
  justify-content: space-between;
  gap: 4rem;

  @media (max-width: $breakpoint-lg) {
    gap: 3.4rem;
  }

  @media (max-width: $breakpoint-md) {
    flex-direction: column;
  }

  @media (max-width: $breakpoint-sm) {
    margin-top: -4rem;
  }

  hr {
    margin: 0;
  }

  .bloc-gris .h5 {
    margin-bottom: 0;
  }

  .col-droite-menu-ec {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    width: calc(100% - 50rem);

    & > .container > h2 {
      margin-bottom: 1.6rem;
    }

    @media (max-width: $breakpoint-md) {
      width: 100%;
    }

    .maj-infos {
      @include flex-between;
      gap: 1.6rem;
      @include grey-box;
      width: 100%;
      padding: 1.6rem;

      .icon-souligne {
        position: relative;
        @include flex-between;
        margin: unset;

        &::before {
          font-size: 3rem;
          padding-right: 1.6rem;
        }

        &::after {
          left: .4rem;
          bottom: -.5rem;
        }
      }
    }

    .infos-suggestion {
      @include grey-box;
      width: 100%;
      padding: 1.6rem;

      .declaration {
        display: flex;
        gap: 1.6rem;

        .icon-souligne {
          position: relative;
          margin: unset;
          height: fit-content;

          &::before {
            padding-right: 0;
          }
        }

        hr {
          margin: .8rem 0;
        }

        .maj-infos-rappel {
          display: flex;
          gap: .8rem;
        }

        .lien-infos {
          text-decoration: underline;
          margin-top: .6rem;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .blocs-prescripteur {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
    }
  }

  .cta-container {
    display: flex;
    justify-content: center;
    gap: 3rem;
  }
}

.deco-ec-mobile {
  display: none;
  position: absolute;
  right: 1rem;
  bottom: 5.5rem;
  @media (max-width: $breakpoint-md) {
    display: block;
  }
}

.espace-client.espace-client-mdp {
  max-width: 100rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;

  input[type="text"],
  input[type="password"] {
    padding-top: .7rem;
  }
}

.suggestion-page {
  position: relative;
  @include flex-between;
  width: 100%;
  @include beige-box;
  margin-bottom: 1.6rem;

  &.hidden {
    display: none;
  }

  .infos {
    position: relative;
    padding: 1.6rem;
  }

  .button-alt {
    border-color: $neutre_gris_300;
    height: 4rem;
    padding: .8rem 1.6rem;
    width: fit-content;
    margin-top: 1.6rem;

    &::after {
      content: "\EA08";
      font-size: 1.4rem;
    }
  }

  .img-suggestion {
    position: relative;
    max-height: 18.3rem;
    max-width: 47rem;
    overflow: hidden;

    @media (max-width: $breakpoint-sm) {
      display: none;
    }

    .toogle-favoris {
      position: absolute;
      bottom: .8rem;
      right: .8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4rem;
      height: 4rem;
      font-size: 2rem;
      border-radius: 50%;
      background-color: #fff;
    }

    img {
      width: 47rem;
      height: auto;
      border-radius: 0 $radius-s $radius-s 0;
      object-fit: cover;
      @media (max-width: $breakpoint-xl) {
        width: 30rem;
      }
      @media (max-width: $breakpoint-sm) {
        width: 25rem;
      }
    }


    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      background: linear-gradient(to right, $neutre_beige_300 5%, rgba(0, 0, 0, .08) 36%, rgba(0, 0, 0, 0) 98.1%);
    }
  }
}

.col-gauche-menu-ec {
  flex-grow: 1;
  min-width: 28.2rem;
  max-width: 28.2rem;
  width: 100%;

  @media (max-width: $breakpoint-lg) {
    min-width: 22rem;
    max-width: 22rem;
  }

  @media (max-width: $breakpoint-md) {
    min-width: 0;
    max-width: none;
  }

  .card-menu-c {
    position: sticky;
    top: 8rem;
    @media (max-width: $breakpoint-md) {
      position: static;
      top: auto;
    }
  }

  .card-menu {
    position: relative;
    margin-bottom: 1.6rem;

    .initiales {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -60%);
      margin-bottom: -3.8rem;

      &::before {
        position: relative;
        content: '';
        display: inline-block;
        height: 8.8rem;
        background-image: url('../img/theme/graphiti/graphiti_serre_bleu.svg');
        background-repeat: no-repeat;
        background-size: contain;
        aspect-ratio: 100 / 70;
        margin-left: -2rem;
      }

      .rond {
        position: absolute;
        top: 1rem;
        left: .5rem;
        @include flex-center;
        background-color: $blanc;
        border-radius: 50rem;
        width: 9.6rem;
        height: 9.6rem;
      }
    }

    .informations-menu {
      @include grey-box;
      width: 100%;
      padding: 5.4rem 0 0;
      overflow: hidden;

      @media (max-width: $breakpoint-md) {
        background-color: transparent;
        border: none;
        overflow: visible;
      }

      .h4 {
        text-align: center;
      }

      .statut {
        @include flex-center;
        flex-wrap: wrap;
        padding: 0 4rem;
        margin: .8rem 0;
        @media (max-width: $breakpoint-md) {
          padding: 0 1.5rem;
        }

        p {
          font-size: 1.4rem;
          width: fit-content;

          &:not(:last-of-type) {
            padding-right: .8rem;
            border-right: $border-clear;
          }

          &:not(:first-child), &:not(:last-child) {
            padding-left: .8rem;
          }
        }
      }
    }

    .menu-ec {
      .link-element.current,
      .link-element:hover {
        border-left-color: $neutre_bleu_300;
        text-decoration: none;
      }

      .link-element.current {
        background-color: $neutre_gris_300;
        font-weight: 700;
      }

      @media (max-width: $breakpoint-md) {
        display: none;
      }
    }
  }

  .espace-client-delegue {
    .card-delegue {
      @include grey-box;
      width: 100%;
      padding: 1.6rem;
      margin-top: .8rem;

      & > *:not(:last-child) {
        margin-bottom: .8rem;
      }

      .button {
        margin: 0 auto;
      }

      .mail {
        text-decoration: underline $principale;
      }
    }
  }

  .button-danger {
    width: 100%;
    justify-content: space-between;
    margin-top: 1.6rem;
    @media (max-width: $breakpoint-md) {
      display: none;
    }
  }
}

.menu-ec,
.menu-ec-mobile {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .link-element {
    justify-content: left;
    flex-direction: row-reverse;
    gap: .5rem;
    font-weight: $weight-normal;
    width: 100%;
    padding: 1.6rem;
    border-left: 3px solid;
    border-left-color: transparent;
    transition: border-left-color .3s ease-in-out;

    @media (max-width: $breakpoint-lg) {
      padding: 1.6rem .6rem;
    }

    .icon {
      position: relative;

      &::before {
        position: relative;
        font-size: 1.8rem;
        width: 3rem;
        text-align: center;
        z-index: 1;
      }

      &.icon-gsn-favoris, &.icon-gsn-marque_page, &.icon-gsn-accueil {
        &::before {
          font-size: 2rem;
        }
      }

      &.icon-gsn-prescripteur, &.icon-gsn-contacts {
        &::before {
          font-size: 2.2rem;
        }
      }

      .surligne {
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}


// Card de l'espace client
.ec-card {
  border-radius: 2.4rem;
  background-color: $neutre_gris_50;
  border: .1rem solid $neutre_gris_300;
  padding: 4rem;
  margin-bottom: 2.4rem;
  @media (max-width: $breakpoint-md) {
    padding: 2rem;
  }

  .graphiti-small {
    margin-left: 6rem;
    @media (max-width: $breakpoint-md) {
      margin-left: 0;
    }
  }
}

.ec-card-small {
  display: flex;
  gap: .6rem;
  border-radius: .8rem;
  border: 1px solid #e0e4e8;
  background-color: #fbfbfb;
  padding: 1.6rem;

  .icon-souligne {
    position: relative;
    margin: 0;

    &::before {
      padding-right: 1rem;
    }

    &::after {
      left: .3rem;
      color: $enseignant;
    }
  }

  a {
    text-decoration: underline;
    margin-top: .6rem;

    &:hover {
      text-decoration: none;
    }
  }
}


/* Page de connexion */
.ec-card .liste-pictos {
  display: flex;
  justify-content: space-between;
  text-align: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  gap: 0 1.5rem;

  @media (max-width: $breakpoint-sm) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem 0;
    width: 100%;
  }


  .item-picto {
    width: 20%;
    @media (max-width: $breakpoint-sm) {
      width: calc(100% / 3 - 1rem);
    }
    @media (max-width: $breakpoint-xs) {
      width: calc(50% - 1rem);
    }
  }

  .graphiti-serre {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.6rem;
  }
}

.page-connexion #contenu {
  margin-bottom: 8rem;
  padding-top: 8rem;
}

/* Espace personnel, tableaux */
.table-espace-client {
  width: 100%;
  margin-top: 3rem;

  thead {
    background-color: #999;
  }

  tr {
    text-align: center;
    height: 5.5rem;
    border-bottom: .2rem solid #999;
  }

  tr:nth-child(2n) {
    background-color: #999;
  }

  th, td {
    text-align: center;
    padding: 1rem .5rem;
  }

  .icon-eye {
    font-size: 2.8rem;
    line-height: 1;
  }

  .detail-nom {
    text-align: left;
    padding-left: 1.5rem;
    text-transform: uppercase;

    a {
      color: $principale;
      font-size: 1.6rem;
      font-weight: 700;
    }

    .ref {
      font-size: 1.4rem;
      font-weight: 600;
      color: #777;
    }
  }

  .total-detail {
    font-weight: 700;
  }
}

/* Page mon compte, modification des informations */

.form-modif-infos {
  #div_options_list_form {
    display: flex;
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    flex-wrap: wrap;

    input[type="checkbox"] ~ label {
      width: calc(100% - 4rem);
      margin-bottom: 1rem;
    }
  }
}

.text-footer-modif-infos {
  font-size: 1.2rem;
}

/* Page détail de commande */
.totalshopline {
  text-align: right;
  margin-top: 1.5rem;
  font-weight: 700;
  font-size: 2.2rem;

  span + span {
    margin-left: 1.5rem;
  }
}

.list-achat-card {
  margin-top: 1.5rem;

  > div {
    margin-top: 1.5rem;
  }
}

.achat-card {
  height: 100%;
  padding: 1.5rem;
  background-color: #999;

  .h3 {
    text-align: center;
  }

  .button-red {
    padding: 1rem 2.5rem;
  }

  .achat-paiement-cta {
    margin-top: 1.5rem;
    text-align: center;
  }
}

/* Page paniers sauvegardés */
.paniers-rec {
  .liste-paniers-rec {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    max-width: 50rem;
    margin-right: auto;
    margin-left: auto;

    li {
      @include flex-between;
      height: 6rem;
      padding-left: 2rem;
      padding-right: 2rem;
      margin-bottom: 1rem;
      background-color: #999;

      .liste-paniers {
        @include flex-center;

        a.icon-link {
          color: #999;
          font-size: 2.6rem;
          margin-left: 1.5rem;

          &:hover {
            color: $principale;
          }

          + a {
            margin-left: 1.5rem;
          }
        }
      }
    }
  }
}

/* Module questions */
.list-questions {
  list-style-type: none;
}

.reponse {
  background-color: $blanc;
}


.erreur {
  color: #dd2a00;
  font-weight: 400;
}

@media (max-width: $breakpoint-xs) {
  button.submit,
  input[type=button],
  input[type=submit] {
    min-width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .formline,
  .submitline {
    line-height: 3rem;
  }

}

/* Page commande */
.infos-detail-commande {
  .h6 {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  .icon {
    margin-right: 1rem;
  }

  .info-text {
    line-height: 1.5;
  }
}

/* Tableau espace client */
.table-ec {
  &.table-350w {
    max-width: 35rem;
  }

  table {
    width: 100%;
    border: .1rem solid $neutre_gris_300;
    background-color: $neutre_gris_50;
    border-radius: .8rem;
    border-spacing: 0;
    border-collapse: separate;
    overflow: hidden;

    th,
    td {
      text-align: center;

      &.textleft {
        text-align: left;
      }
    }

    thead th {
      padding: 1.2rem;
      font-weight: 400;
    }

    tbody tr {
      background-color: #fff;

      td {
        padding: 1.2rem;
        border-top: .1rem solid $neutre_gris_300;
      }
    }

    .flex-table {
      display: flex;
      gap: 1.6rem;
      justify-content: flex-end;
    }

    .total-table {
      color: #fff;
      background-color: $principale;
    }

    .tag-element {
      height: 2.5rem;
    }

    .button {
      height: 3.4rem;
    }
  }

  &.table-total {
    thead tr th:first-child,
    tbody tr td:first-child {
      text-align: left;
      padding-left: 3rem;
    }
  }
}

/* Page contact */
.page-espace-client-mes-contacts {
  .col-droite-menu-ec {
    .graphiti-small {
      display: flex;
      align-items: center;
    }
  }
}

/* Page favoris */
.page-espace-client-mes-favoris {
  @media (max-width: $breakpoint-lg) {
    .card-compagnon .fav-button {
      bottom: 5rem;
    }
  }

  .multi-slider {
    .swiper-container {
      padding-top: 0;

      .swiper-wrapper {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .produits-fav-ec,
  .articles-fav-ec,
  .collections-fav-ec,
  .sites-fav-ec {
    margin-bottom: 1.6rem;
  }

  .auteurs-fav-ec {
    margin-left: 0;
    margin-right: 0;
    max-width: unset;
  }
}

/* Page compte */
.page-profile-espace-client {
  .infos-ec-compte {
    .h6 {
      gap: .8rem;
      margin-bottom: .8rem;
    }
  }

  .submit-fake {
    margin-bottom: 0;
  }
}

/* Menu mobile EC */
.menu-ec-mobile {
  display: none;
  width: 100%;
  max-width: 100%;
  margin-top: 1.6rem;

  @media (max-width: $breakpoint-md) {
    display: block;
  }

  .custom-accordeon {
    .accordeon-header {
      cursor: pointer;

      &.open {
        .accordeon-title .bouton-accordeon-mobile {
          border-radius: $radius-s $radius-s 0 0;

          & > .icon:before {
            transform: rotate(270deg);
          }
        }
      }

      .accordeon-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        line-height: 1;

        .bouton-accordeon-mobile {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: space-between;
          border-radius: $radius-s;
          border: .1rem solid $neutre_gris_600;
          background: #fff;
          transition: border-radius .3s;

          .link-element {
            font-weight: 700;
            text-decoration: none;
          }

          .icon-gsn-chevron {
            position: absolute;
            top: 50%;
            right: 1.5rem;
            transform: translateY(-50%);
            transform-origin: right;

            &::before {
              line-height: 1.2;
              transform: rotate(90deg);
            }
          }
        }
      }
    }

    .accordeon-body {
      display: none;
      background-color: #fff;
      border-bottom-left-radius: .4rem;
      border-bottom-right-radius: .4rem;
    }
  }

  .menu-mobile-contenu {
    border-radius: 0 0 .8rem .8rem;
    border: .1rem solid $neutre_gris_600;
    border-top-color: $neutre_gris_300;
    background: #fff;
    margin-top: -.1rem;
  }
}

/* Page prescripteur */
.page-prescripteur-espace-client {
  .bloc-prescripteur {
    padding: 0;

    .bloc-visible-content {
      padding: 1.6rem;
      @media (max-width: $breakpoint-sm) {
        flex-direction: column;
      }
    }

    .see-more {
      max-width: none;
      width: fit-content;
      min-width: fit-content;
    }

    .bloc-blanc-content {
      width: 100%;
      @media (max-width: $breakpoint-sm) {
        flex-direction: column;
      }

      .lien-prescripteur {
        margin-bottom: .8rem;
      }
    }

    .bloc-extra-content {
      padding-top: 0;
      border-radius: 0 0 .8rem .8rem;
    }

    .bloc-extra-content-inside {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 1.6rem 2.4rem;
      padding: 1.6rem;
      background: $neutre_gris_50;
      @media (max-width: $breakpoint-sm) {
        gap: 1.2rem;
      }

      .bloc-blanc {
        display: flex;
        gap: 1.6rem;
        width: calc(50% - 1.2rem);
        margin-bottom: 0;
        @media (max-width: $breakpoint-lg) {
          width: 100%;
        }
        @media (max-width: $breakpoint-sm) {
          flex-direction: column;
        }

        .h6 {
          margin-bottom: .8rem;
        }

        .col-gauche {
          @media (max-width: $breakpoint-sm) {
            text-align: center;
          }

          .icon {
            font-size: 4.5rem;
            width: 5.6rem;
            text-align: center;

            &.icon-gsn-compagnon {
              font-size: 5.6rem;
            }
          }
        }
      }

      .bandeau-offre-prescipteur {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 2.4rem;
        border: .1rem solid $neutre_bleu_300;
        background-color: $neutre_bleu_100;
        border-radius: .8rem;
        @media (max-width: $breakpoint-sm) {
          flex-direction: column;
          gap: .5rem;
        }
      }
    }
  }
}