#slider-videos {
  .swiper-button-next,
  .swiper-button-prev {
    top: 15rem;
  }
}

.video-card {
  display: flex;
  flex-direction: column;

  .frame-video {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0;

    .video-img-c {
      position: relative;
      display: block;
      flex-grow: 1;
      margin-bottom: 1.6rem;
      width: 100%;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: .8rem;
        background-color: rgba(0, 0, 0, .3);
        transition: background-color .3s;
      }

      &::after {
        content: "\FC36";
        font-family: kfont;
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 6.4rem;
        height: 6.4rem;
        font-size: 2.6rem;
        padding-left: .6rem;
        border-radius: 50%;
        background-color: #fff;
        transform: translate(-50%, -50%);
        transition: opacity .3s;
      }

      &.produit {
        margin-bottom: 0;
      }
    }

    &:hover {
      .video-img-c {
        &::before {
          background-color: transparent;
        }

        &::after {
          opacity: 0;
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: .8rem;
      min-height: 20rem;
      aspect-ratio: 16/9;
    }

    .h6 {
      display: inline-block;
      height: 100%;
      margin-top: 1.6rem;
      margin-bottom: 2rem;
      text-align: left;
    }
  }

  .transcription-video {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: $principale;
    width: 100%;
    padding-right: 0;
    transition: text-decoration;
    margin-top: 1.6rem;
    @media (max-width: $breakpoint-sm) {
      flex-wrap: wrap;
    }

    .icon-souligne {
      line-height: 0;
      margin-left: .6rem;
      margin-right: 0;
      font-size: 1.1rem;
      position: relative;

      &::before {
        padding-right: 0;
        font-size: 1.6rem;
      }

      &::after {
        top: 1.3rem;
        left: auto;
        right: 0;
        font-size: .8rem;
      }
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.iframe {
  padding: 3rem 1.6rem 1.4rem 1.6rem;
  @media (max-width: $breakpoint-sm) {
    width: 100%;
    padding: 3rem .8rem .6rem .8rem;
  }

  iframe {
    aspect-ratio: 16 / 9;
    width: 90rem;
    height: 100%;
    @media (max-width: $breakpoint-xl) {
      width: 60rem;
    }
    @media (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }

  button {
    width: 3.2rem;
    height: 3.2rem;
    padding: 0 !important;
    margin: 0 !important;
  }
}

.bloc-carrousel-videos {
  .swiper-wrapper {
    margin: 0 !important;
  }
}