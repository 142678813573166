.page-auteur {
  #contenu section:not(.hero) {
    margin-bottom: 12rem;
    @media (max-width: $breakpoint-lg) {
      margin-bottom: 7.2rem;
    }
  }

  .intro-auteur {
    .container {
      .box {
        display: flex;
        gap: 2rem;
        @media (max-width: $breakpoint-sm) {
          align-items: center;
        }

        .auteur-img {
          position: relative;
          height: fit-content;

          &:not(.no-photo) {
            &::before {
              position: relative;
              content: '';
              display: inline-block;
              height: 16rem;
              background-image: url('../img/theme/graphiti/graphiti_serre_neutre.svg');
              background-repeat: no-repeat;
              background-size: contain;
              aspect-ratio: 225 / 190;
              z-index: -1;
              margin-left: -2rem;
              @media (max-width: $breakpoint-sm) {
                height: 10.5rem;
                margin-left: 0;
              }
            }

            img {
              position: absolute;
              top: .5rem;
              left: 4.2rem;
              object-fit: cover;
              @media (max-width: $breakpoint-sm) {
                width: 8rem;
                height: 8rem;
              }
            }
          }

          &.no-photo {
            object-fit: unset;
            @media (max-width: $breakpoint-sm) {
              margin-bottom: 1.6rem;
            }

            &::before {
              content: "\FC42";
              font-family: kfont;
              position: absolute;
              bottom: 0;
              left: 50%;
              display: inline-block;
              font-size: 5rem;
              color: $grand_public;
              transform: translateX(-50%);
              z-index: -1;
              @media (max-width: $breakpoint-sm) {
                bottom: 0;
                font-size: 4rem;
              }
            }

            img {
              width: 10rem;
              min-width: 10rem;
              height: 10rem;
              @media (max-width: $breakpoint-sm) {
                width: 8rem;
                min-width: 8rem;
                height: 8rem;
              }
            }
          }
        }
      }

      .infos-auteur, .infos-auteur-mobile {
        & > *:not(:last-child) {
          margin-bottom: .8rem;
        }

        h1 {
          @media (max-width: $breakpoint-xs) {
            font-size: 2.4rem;
          }
        }

        .auteur-st {
          font-weight: $weight-bold;
        }
      }

      .infos-auteur {
        .texte {
          @media (max-width: $breakpoint-sm) {
            display: none;
          }
        }
      }

      .infos-auteur-mobile {
        display: none;
        @media (max-width: $breakpoint-sm) {
          display: block;
        }
      }
    }
  }

  .ouvrages-auteur {
    h2 {
      margin-bottom: 2.4rem;
      @media (max-width: $breakpoint-xs) {
        font-size: 2rem;
      }
    }

    .liste-produits {
      margin-bottom: 2.4rem;
    }

    .button {
      margin-left: auto;
      margin-right: auto;
    }
  }
}