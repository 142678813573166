/* HEADER */
#header {
  position: relative;
  top: 0;
  right: 0;
  width: 100%;
  background-color: $blanc;
  box-shadow: 0 .2rem 3.6rem 0 rgba(0, 0, 0, 0.08);
  z-index: 10000;

  .header-content {
    .container {
      position: relative;
      @include flex-between;
      gap: 1.5rem;

      @media (max-width: 1500px) {
        padding-right: 8rem;
      }

      @media (max-width: $breakpoint-lg) {
        padding-right: 6.4rem;
      }

      .header-logo {
        @include flex-center;
        padding: 1.6rem 0;
        height: 8rem;

        img {
          width: 15rem;
          @media (max-width: $breakpoint-xl) {
            width: 12rem;
          }
          @media (max-width: $breakpoint-sm) {
            min-width: 10rem;
          }
        }
      }

      .search-header {
        position: relative;
        max-width: calc(100% - 50rem);
        width: 100%;
        @media (max-width: $breakpoint-xl) {
          max-width: calc(100% - 45rem);
        }
        @media (max-width: $breakpoint-lg) {
          max-width: calc(100% - 25rem);
        }

        .search-input {
          background-color: $neutre-gris_100;
          font-size: $font-s;
          font-weight: $weight-normal;
          border: $border-clear;
          outline: 3px solid transparent;
          border-radius: 10rem;
          width: 100%;
          padding: 1.45rem 2rem;
          line-height: 1;
          transition: outline-color .3s ease-in-out;

          &::placeholder {
            color: $principale;
            opacity: 1;
          }

          @media (max-width: $breakpoint-xs) {
            width: calc(100% - 2rem);
            padding-right: 6rem;
          }
        }

        .icon-gsn-recherche {
          position: absolute;
          top: 50%;
          right: 1.4rem;
          transform: translateY(-50%);
          line-height: 0;
          cursor: pointer;
          transition: color .3s;
          @media (max-width: $breakpoint-xs) {
            right: 2rem;
          }

          &:hover {
            color: $maison_nathan;
          }

          &::before {
            font-size: 2.2rem;
            @media (max-width: $breakpoint-xs) {
              font-size: 1.6rem;
            }
          }
        }

        .icon-gsn-fermer {
          display: none;
          position: absolute;
          right: 5rem;
          top: 50%;
          transform: translateX(-50%);
          font-size: 1.2rem;
          &:hover {
            color: $maison_nathan;
          }
          @media (max-width: $breakpoint-xs) {
            display: block;
          }
          &::before {
            line-height: 0;
          }
          &::after {
            content: '';
            position: absolute;
            right: -.9rem;
            top: -1.1rem;
            transform: translateX(-50%);
            display: block;
            width: .1rem;
            height: 2.6rem;
            background-color: $neutre_gris_600;
          }
        }

        @media (max-width: $breakpoint-xs) {
          display: none;
          .icon-desktop {
            display: none;
          }
        }
        @media (max-width: $breakpoint-xs) {
          position: absolute;
          width: 100%;
          max-width: none;
          background-color: #fff;
          left: 0;
          right: 0;
          z-index: 9;
          form {
            display: flex;
            justify-content: center;
          }
          .search-input-tablette {
            margin-left: 1.5rem;
            margin-right: 1.5rem;
            width: calc(100% - 4.5rem);
          }
        }
      }

      .icon-mobile {
        display: none;
        font-size: 2.5rem;
        margin-left: auto;
        margin-right: .4rem;
        margin-top: .2rem;
        cursor: pointer;
        transition: color .3s;

        &:hover {
          color: $maison_nathan;
        }
      }

      @media (max-width: $breakpoint-xs) {
        .icon-mobile {
          display: block;
        }
      }

      .connexion-panier {
        @include flex-between;
        gap: 4.8rem;
        @media (max-width: $breakpoint-xl) {
          gap: 2.5rem;
        }

        .connected-container, .panier-header-container {
          a, .lien-off, button, .link-element {
            @include flex-center;
            gap: 1.4rem;
            font-size: $font-s;
            font-weight: $weight-medium;

            .icon {
              position: relative;

              &::before {
                position: relative;
                z-index: 1;
              }

            }

            .text {
              text-align: left;
            }

            @media (max-width: $breakpoint-lg) {
              .text {
                display: none;
              }
            }
          }

          &.status-connected, &.gros-panier {
            a, .lien-off, button, .link-element {
              gap: 2.4rem;
            }
          }
        }

        .connected-container {
          .icon {
            position: relative;
            font-size: 2.8rem;

            .surligne {
              left: -.2rem;
              z-index: -1;
            }

            .tag-element {
              position: absolute;
              top: -.4rem;
              left: 1.4rem;
            }
          }
        }

        .panier-header-container {
          position: relative;

          .icon {
            font-size: 3rem;

            .surligne {
              left: 0;
            }
          }

          .nb-articles-panier {
            position: absolute;
            top: -.4rem;
            left: 1.8rem;
          }
        }
      }
    }
  }

  .sub-header-content {

    @media (max-width: $breakpoint-xs) {
      color: #fff;
      background-color: $cta;
    }

    .container {
      @include flex-between;
      padding-top: .6rem;
      padding-bottom: 2rem;
      @media (max-width: $breakpoint-sm) {
        padding-top: 1rem;
        padding-bottom: 1.6rem;
      }

      @media (max-width: $breakpoint-xs) {
        padding-top: .5rem;
        padding-bottom: .5rem;
      }

      .main-menu-header, .alt-menu-header {
        @media (max-width: $breakpoint-xs) {
          width: 100%;
        }
        ul {
          @include flex-between;
          margin: 0;
          padding: 0;

          @media (max-width: $breakpoint-sm) {
            gap: 1.6rem;
          }

          li {
            .surligne {
              font-size: 1rem;
            }
          }
        }
      }

      .main-menu-header {
        @media (max-width: $breakpoint-xs) {
          display: none;
        }

        ul {
          gap: 4rem;
          @media (max-width: $breakpoint-lg) {
            gap: 2rem;
          }

          li {
            .link-element {
              position: relative;

              @media (max-width: $breakpoint-sm) {
                font-size: 1.2rem;
              }

              .value {
                font-weight: $weight-bold;
                cursor: pointer;
              }

              &.gp {
                .surligne::after {
                  color: $grand_public;
                }
              }

              &.enseignants {
                .surligne::after {
                  color: $enseignant;
                }
              }
            }
          }
        }
      }

      .alt-menu-header {
        ul {
          li {
            padding: .5rem 0;

            &:not(:first-child) {
              padding-left: 1.6rem;
              @media (max-width: $breakpoint-md) {
                display: none;
              }
            }

            &:not(:last-child) {
              border-right: $border-clear;
              padding-right: 1.6rem;
              @media (max-width: $breakpoint-lg) {
                padding-right: .6rem;
              }

              .link-element {
                font-size: $font-xs;
                font-weight: $weight-normal;
              }
            }

            @media (max-width: $breakpoint-md) {
              padding-left: .5rem;
              padding-right: .5rem;
              &:not(:first-child) {
                padding-left: .5rem;
              }
              &:not(:last-child) {
                padding-right: .5rem;
                border-right: none;
              }
            }

            .font-alt {
              font-family: 'LaMaisonNathan', sans-serif;
              font-size: $font-xl;
              height: 2rem;

              .surligne::after {
                color: $rouge_nathan;
              }
            }

            &.pages-perso-header {
              font-size: 1.4rem;
              font-weight: 400;
              list-style-type: none;
              padding-top: 0;
              padding-bottom: 0;
              cursor: pointer;
              @media (max-width: $breakpoint-sm) {
                font-size: 1.2rem;
              }

              @media (max-width: $breakpoint-xs) {
                width: 100%;
              }

              .icon {
                margin-left: .4rem;
                margin-bottom: .1rem;
                transition: transform .2s;
                font-size: 1.4rem;
                @media (max-width: $breakpoint-xs) {
                  margin-left: auto;
                }
              }

              &.tooltip-visible {
                .icon {
                  transform: rotate(90deg);
                }
              }

              .tooltip-init {
                padding-top: .5rem;
                padding-bottom: .5rem;

                @media (max-width: $breakpoint-xs) {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  &::before {
                    content: "\FA2E";
                    font-family: kfont;
                    margin-right: 1rem;
                    font-size: 2.4rem;
                  }
                  &:hover::before {
                    text-decoration: none;
                  }
                }

                &:hover {
                  text-decoration: underline;
                }
              }
            }

          }
        }
      }
    }
  }
}

/* Sticky */
//#header.sticky .menu-item a,
//#header.sticky .menu-item .lien-off,
//#header.sticky .header-content {
//  height: 5rem;
//}
