/* Surlignage d'icône standart */

.surligne {
  position: absolute;
  bottom: -.2rem;
  font-size: 1.2rem;

  &::after {
    content: "\FC42";
    font-family: kfont;
    color: $neutre_bleu_300;
    transition: color .3s;
  }

  &.surligne-important::after {
    color: $neutre_bleu_300!important;
  }

  &.surligne-enseignants {
    &::after {
      color: $enseignant;
    }
    &.surligne-enseignants-important::after {
      color: $enseignant!important;
    }
  }

  &.surligne-gp::after {
    color: $grand_public;
  }

  &:hover::after {
    //color: $rouge_nathan;
  }

  &.surligne-after {
    right: -.5rem;
  }

  &.surligne-before {
    left: -.5rem;
  }

  &.surligne-home {
    &::after {
      color: $neutre_bleu_300;
    }
  }
}

/* Surlignage d'icône serre */

.graphiti-serre {
  position: relative;
  width: fit-content;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -.5rem;
    transform: translateY(-50%);
    display: inline-block;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 10rem;
    height: 7rem;
    background-image: url('../img/theme/graphiti/graphiti_serre_neutre.svg');
  }

  img {
    position: relative;
    width: 4rem;
    height: auto;
    z-index: 1;
  }

  &.graphiti-serre-gp {
    &::after {
      background-image: url('../img/theme/graphiti/graphiti_serre_corail.svg');
    }
  }

  &.graphiti-serre-enseignants {
    &::after {
      background-image: url('../img/theme/graphiti/graphiti_serre_vert.svg');
    }
    &.graphiti-serre-enseignants-important::after {
      background-image: url('../img/theme/graphiti/graphiti_serre_vert.svg')!important;
    }
  }
}


/* Soulignage titre */

.souligne {
  flex-direction: column;
  gap: 0;
  width: fit-content;
  margin: 0 auto;
  text-align: center;

  &::after {
    font-family: kfont;
    font-size: 3.2rem;
    margin-top: -1.2rem;
  }

  &.souligne-gauche {
    margin: unset;
    align-items: start;

    &::after {
      margin-left: -1rem;
    }
  }

  &.souligne-xs {
    &::after {
      content: "\F115";
    }
  }

  &.souligne-s {
    &::after {
      content: "\F113";
    }
  }

  &.souligne-m {
    &::after {
      content: "\F112";
    }
  }

  &.souligne-l {
    &::after {
      content: "\F111";
    }
  }

  &.souligne-xl {
    &::after {
      content: "\F114";
    }
  }

  &.souligne-nathan {
    &::after {
      color: $rouge_nathan;
    }
  }

  &.souligne-gp {
    &::after {
      color: $grand_public;
    }
  }

  &.souligne-enseignants {
    &::after {
      color: $enseignant;
    }
  }
}

/* Soulignage icone */

.icon-souligne {
  justify-content: center;
  width: fit-content;
  margin: 0 auto;

  &.h3 {
    align-items: flex-start;
    margin-bottom: 2rem;
  }

  &::before {
    content: "\FD71";
    position: relative;
    font-family: kfont;
    font-size: 3rem;
    font-weight: 400;
    padding-right: 1.8rem;
    z-index: 1;
    @media (max-width: $breakpoint-md) {
      padding-right: 1.2rem;
    }
  }

  &::after {
    content: "\FC42";
    font-family: kfont;
    position: absolute;
    top: 2rem;
    left: -.2rem;
    font-size: 1.4rem;
    color: $neutre-gris_300;
  }

  &.picto-color-enseignant::after {
    color: $enseignant;
  }

  &.picto-numerique {
    &::before {
      content: "\FC31";
      font-size: 2.6rem;
    }

    &::after {
      left: -.1rem;
    }
  }

  &.icon-souligne-gauche {
    margin: unset;
  }

  &.picto-presentation::before {
    content: "\F0CA";
    font-size: 2.8rem;
  }

  &.picto-livre {
    &::before {
      content: "\FA3F";
    }

    &::after {
      top: 2.3rem;
      left: -.1rem;
    }
  }

  &.picto-info {
    &::before {
      content: "\FA3B";
      font-size: 2.8rem;
    }

    &::after {
      left: -.1rem;
    }
  }

  &.picto-ressources {
    &::before {
      content: "\FC3C";
      font-size: 2.6rem;
    }

    &::after {
      left: 0;
    }
  }

  &.picto-ressources-supp {
    &::before {
      content: "\F41D";
      font-size: 2.8rem;
    }

    &::after {
      left: -.1rem;
    }
  }

  &.picto-trombone {
    &::before {
      content: "\FC46";
      font-size: 2.8rem;
    }
  }

  &.picto-ecolier {
    &::before {
      content: "\FA31";
      font-size: 2.8rem;
    }

    &::after {
      left: .3rem;
    }
  }

  &.picto-cadenas {
    &::before {
      content: "\FA02";
      font-size: 2.8rem;
    }

    &::after {
      left: -.2rem;
    }
  }
}


/* Graphiti titre */

.graphiti-small {
  align-items: flex-start !important;
  gap: 1.2rem;
  @media (max-width: $breakpoint-md) {
    gap: .8rem;
  }
  @media (max-width: $breakpoint-sm) {
    flex-direction: column;
  }

  &::before {
    content: '';
    display: inline-block;
    background-image: url('../img/theme/graphiti/graphiti_small_neutre.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 6.8rem;
    min-width: 6.8rem;
    height: 2.5rem;
  }

  //position
  &.graphiti-small-absolute {
    &::before {
      position: absolute;
      left: -8rem;
      top: .6rem;
      @media (max-width: $breakpoint-md) {
        top: .2rem;
      }
      @media (max-width: $breakpoint-sm) {
        position: unset;
      }
    }

    &.graphiti-inline {
      align-items: flex-start;
      margin-left: 6rem;

      &::before {
        position: absolute;
        top: .6rem;
      }
    }
  }

  //couleurs
  &.graphiti-small-gp {
    &::before {
      background-image: url('../img/theme/graphiti/graphiti_small_corail.svg') !important;
    }
  }

  &.graphiti-small-enseignants {
    &::before {
      background-image: url('../img/theme/graphiti/graphiti_small_vert.svg') !important;
    }
  }

  &.graphiti-small-red {
    &::before {
      background-image: url('../img/theme/graphiti/graphiti_small_rouge.svg') !important;
    }
  }
}

.graphiti-big {
  display: flex;

  &::before {
    content: '';
    display: inline-block;
    background-image: url('../img/theme/graphiti/graphiti_big.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 14.3rem;
    height: 5.3rem;
    margin-right: 2rem;
  }
}

.stick-border-left {
  display: flex;
  gap: .8rem;

  &::before {
    content: '';
    display: block;
    border-radius: 50rem;
    min-width: .4rem;
  }
}

/* Couleurs en fonction du type de page (Enseignant / GP / Neutre) */
.page-type-enseignant {
  .graphiti-small::before,
  .form-modif-infos #div_institution_ens h2::before,
  .form-modif-infos #div_login h2::before,
  .form-modif-infos #div_password h2::before,
  .form-modif-infos #div_personal_information h2::before,
  .form-modif-infos #div_profil_ens_subform h2::before,
  .form-modif-infos #per-connect-layout-group-subscription h2::before,
  .form-modif-infos .per-connect-widget-text h2::before,
  .page-espace-client-register #div_institution_ens h2::before,
  .page-espace-client-register #div_login h2::before,
  .page-espace-client-register #div_password h2::before,
  .page-espace-client-register #div_personal_information h2::before,
  .page-espace-client-register #div_profil_ens_subform h2::before,
  .page-espace-client-register #per-connect-layout-group-subscription h2::before,
  .page-espace-client-register .per-connect-widget-text h2::before {
    background-image: url('../img/theme/graphiti/graphiti_small_vert.svg');
  }

  .souligne::after,
  .bloc-auteurs .card.no-photo::before,
  .bloc-avis .list-3-cards .card .icon-gsn-citation::before,
  .surligne::after,
  .icon-souligne::after {
    color: $enseignant;
  }

  .menu_hub .liste_menu_hub li a::after {
    background-color: $enseignant;
  }

  .col-gauche-menu-ec .card-menu .menu-ec .link-element.current,
  .col-gauche-menu-ec .card-menu .menu-ec .link-element:hover {
    border-left-color: $enseignant;
  }

  .bloc-auteurs .card:not(.no-photo)::before,
  .graphiti-serre::after {
    background-image: url(../img/theme/graphiti/graphiti_serre_vert.svg);
  }

}

.page-type-grand-public {

  .graphiti-small::before,
  .form-modif-infos #div_institution_ens h2::before,
  .form-modif-infos #div_login h2::before,
  .form-modif-infos #div_password h2::before,
  .form-modif-infos #div_personal_information h2::before,
  .form-modif-infos #div_profil_ens_subform h2::before,
  .form-modif-infos #per-connect-layout-group-subscription h2::before,
  .form-modif-infos .per-connect-widget-text h2::before,
  .page-espace-client-register #div_institution_ens h2::before,
  .page-espace-client-register #div_login h2::before,
  .page-espace-client-register #div_password h2::before,
  .page-espace-client-register #div_personal_information h2::before,
  .page-espace-client-register #div_profil_ens_subform h2::before,
  .page-espace-client-register #per-connect-layout-group-subscription h2::before,
  .page-espace-client-register .per-connect-widget-text h2::before {
    background-image: url('../img/theme/graphiti/graphiti_small_corail.svg');
  }

  .souligne::after,
  .bloc-auteurs .card.no-photo::before,
  .bloc-avis .list-3-cards .card .icon-gsn-citation::before,
  .surligne::after,
  .icon-souligne::after {
    color: $grand_public;
  }

  .menu_hub .liste_menu_hub li a::after {
    background-color: $grand_public;
  }

  .col-gauche-menu-ec .card-menu .menu-ec .link-element.current,
  .col-gauche-menu-ec .card-menu .menu-ec .link-element:hover {
    border-left-color: $grand_public;
  }

  .bloc-auteurs .card:not(.no-photo)::before,
  .graphiti-serre::after {
    background-image: url(../img/theme/graphiti/graphiti_serre_corail.svg);
  }

}

.page-minisite {

  .graphiti-small::before,
  .form-modif-infos #div_institution_ens h2::before,
  .form-modif-infos #div_login h2::before,
  .form-modif-infos #div_password h2::before,
  .form-modif-infos #div_personal_information h2::before,
  .form-modif-infos #div_profil_ens_subform h2::before,
  .form-modif-infos #per-connect-layout-group-subscription h2::before,
  .form-modif-infos .per-connect-widget-text h2::before,
  .page-espace-client-register #div_institution_ens h2::before,
  .page-espace-client-register #div_login h2::before,
  .page-espace-client-register #div_password h2::before,
  .page-espace-client-register #div_personal_information h2::before,
  .page-espace-client-register #div_profil_ens_subform h2::before,
  .page-espace-client-register #per-connect-layout-group-subscription h2::before,
  .page-espace-client-register .per-connect-widget-text h2::before {
    background-image: url('../img/theme/graphiti/graphiti_small_rouge.svg');
  }

  .souligne::after,
  .bloc-auteurs .card.no-photo::before,
  .bloc-avis .list-3-cards .card .icon-gsn-citation::before,
  .surligne::after,
  .icon-souligne::after {
    color: $rouge_nathan;
  }

  .connexion-panier .surligne::after {
    color: $neutre_bleu_300;
  }

  &.page-type-grand-public .connexion-panier .surligne::after {
    color: $grand_public;
  }

  &.page-type-enseignant .connexion-panier .surligne::after {
    color: $enseignant;
  }

  .menu_hub .liste_menu_hub li a::after {
    background-color: $rouge_nathan;
  }

  .col-gauche-menu-ec .card-menu .menu-ec .link-element.current,
  .col-gauche-menu-ec .card-menu .menu-ec .link-element:hover {
    border-left-color: $rouge_nathan;
  }

  .bloc-auteurs .card:not(.no-photo)::before,
  .graphiti-serre::after {
    background-image: url(../img/theme/graphiti/graphiti_serre_rouge.svg);
  }

}