.tooltip-hub {
  position: relative;
  background-color: $blanc;
  font-size: $font-xs;
  text-align: left;
  border: $border-clear;
  border-radius: $radius-s;
  padding: 1.2rem;

  &.no-user {
    display: inline-flex;
  }

  button:has(.icon-gsn-fermer) {
    position: absolute;
    top: 0;
    right: 0;

    .icon::before {
      font-size: 1.4rem !important;
    }
  }

  .icon {
    &::before {
      font-size: 2rem;
    }
  }

  p {
    margin-top: .5rem;
  }
}

.tooltip.show {
  opacity: 1;
  z-index: 10001
}

.tooltip-arrow::before {
  display: none;
}

/* Utilisé pour les pages personnalisées dans le header */
.tooltip-custom {
  .tooltip-hub {
    position: relative;
    padding: .8rem;
    padding-right: 2.8rem;
    margin-top: 2rem;
    border-radius: 0 0 .8rem .8rem;

    @media (max-width: $breakpoint-xs) {
      margin-top: 1rem;
    }

    p {
      white-space: nowrap;
      margin-top: 0;
    }

    .triangle {
      position: absolute;
      top: -1.4rem;
      left: 6rem;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 9.5px 14px 9.5px;
      border-color: transparent transparent #e0e4e8 transparent;

      &::before {
        content: '';
        position: absolute;
        top: .2rem;
        left: -.8rem;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8.5px 13px 8.5px;
        border-color: transparent transparent #fff transparent;
      }
    }

    &.user-connect {
      padding: 1.6rem 1rem .8rem;
      @media (max-width: $breakpoint-md) {
        padding-right: 2.8rem;
      }

      &.no-fav {
        padding-right: 2.8rem;
      }

      .h6 {
        margin-bottom: .6rem;
      }

      ul {
        margin-bottom: 0;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }
}