.gamme_numerique {
  @include flex-between;
  gap: 11rem;
  @media (max-width: $breakpoint-lg) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    gap: 3.2rem;
  }

  .contenu-gamme {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1.6rem;

    & > *:not(:last-child) {
      margin-bottom: .8rem;
    }

    h2 {
      text-align: left !important;
    }

    .img-gamme {
      max-width: 24rem;
      height: 100%;
      object-fit: contain;
      margin-right: auto;
    }

    .flex-button {
      display: flex;
      flex-wrap: wrap;
      gap: .8rem;
    }
  }

  .ratio-gamme {
    aspect-ratio: 810/490;
    width: 100%;
    max-width: 81rem;
    object-fit: cover;
    border-radius: $radius-ml;
    @media (max-width: $breakpoint-xxxl) {
      max-width: 70rem;
    }
    @media (max-width: $breakpoint-xl) {
      max-width: 52rem;
    }
    @media (max-width: $breakpoint-lg) {
      max-width: 60rem;
      margin-right: auto;
    }
    @media (max-width: $breakpoint-sm) {
      max-width: 100%;
    }
  }
}
