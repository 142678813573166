.mp-menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  z-index: 10003;
  width: 61rem;
  height: 100vh;
  background: $blanc;
  transform: translateX(-100%);
  @media (max-width: $breakpoint-lg) {
    width: 38rem;
  }
  @media (max-width: $breakpoint-sm) {
    width: 32rem;
  }
  @media (max-width: $breakpoint-xs) {
    width: 100%;
    height: 100%;
  }

  .left-col {
    width: 20rem;
    margin-top: 4.4rem;
    margin-right: 4rem;
    @media (max-width: $breakpoint-lg) {
      display: none;
    }
  }

  .right-col {
    width: calc(100% - 24rem);
    margin-top: 4.4rem;
    @media (max-width: $breakpoint-lg) {
      width: 100%;
      margin-top: 1.6rem;
      .mobile-header {
        padding-left: 1.6rem;
      }
    }

    .logo-mh {
      display: flex;
      justify-content: space-between;
      @media (max-width: $breakpoint-lg) {
        img {
          height: 3.6rem;
          width: auto;
        }
      }

      .close-menu-mobile {
        display: none;
        margin-right: 1rem;
        @media (max-width: $breakpoint-lg) {
          display: block;
        }
      }
    }
  }

  .mp-level-1 {
    margin-top: 4rem;
    width: calc(100% - .4rem);
    max-height: calc(100% - 9.6rem);
    transform: translateX(-100%);
    overflow: hidden auto;
    padding-right: .4rem;
    @media (max-width: $breakpoint-lg) {
      max-height: calc(100% - 5.2rem);
      border-top: .1rem solid $neutre_gris_300;
      margin-top: 1.6rem;
    }
    @media (max-width: $breakpoint-sm) {
      max-height: calc(100% - 5rem);
    }

    > ul {
      position: relative;
      @media (max-width: $breakpoint-lg) {
        margin-top: 2rem;
      }
    }

    .h3 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4rem;
      width: 100%;
      text-align: left;
      padding: 0;
      @media (max-width: $breakpoint-md) {
        font-size: 2.6rem;
        margin-bottom: 3rem;
      }
      @media (max-width: $breakpoint-xs) {
        font-size: 2rem;
        margin-bottom: 2rem;
      }
      @media (max-width: $breakpoint-lg) {
        padding-left: 1.6rem;
        &.menu-container {
          font-size: 2.4rem;
          margin-bottom: 1.6rem;
        }
      }
    }

    .h5 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 3rem;
      @media (max-width: $breakpoint-md) {
        margin-bottom: 2rem;
      }
      @media (max-width: $breakpoint-lg) {
        font-size: 1.6rem;
        margin-bottom: 2.8rem;
        margin-left: 1.6rem;
      }

      &.lien-off:hover span {
        text-decoration: underline;
      }

      &.button {
        display: flex;
        color: #fff;
        height: auto;
        font-size: 1.6rem;
        font-weight: 400;
        width: 29rem;
        padding-right: 1rem;
        transition: all .3s;

        &:focus,
        &:hover {
          margin-left: 0;
          color: $cta;
          text-decoration: none;
          @media (max-width: $breakpoint-lg) {
            margin-left: 1.6rem;
          }
        }
      }
    }

    button.h5 {
      padding-left: 0;
      padding-right: 0;
    }

    .lien-off.has-sub-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      @media (max-width: $breakpoint-lg) {
        width: calc(100% - 1.6rem);
      }

      &::after {
        content: "\EA08";
        font-family: kfont;
        font-size: 1.4rem;
        font-weight: 400;
        margin-right: 1rem;
      }

      &:hover {
        margin-left: 0;
        @media (max-width: $breakpoint-lg) {
          margin-left: 1.6rem;
        }
      }
    }

  }
}

.left-col {
  .button-nav-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-top: .6rem;

    .icon {
      margin-bottom: 1rem;
      font-size: 1.4rem;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .back-menu-button {
    opacity: 0;
    visibility: hidden;
    margin-top: 4.4rem;
    transition: visibility .3s, opacity .3s;
  }
}

/* Menu Niveau 2 */
.mp-level.mp-level-2 {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: #fff;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  transition: transform .5s ease-in-out;

  li:has(.separation) {
    display: flex;
    align-items: center;
    border-top: .1rem solid $neutre_gris_300;
    border-bottom: .1rem solid $neutre_gris_300;
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    margin-bottom: 3.2rem;

    .separation {
      margin-bottom: 0;
    }
  }

  a,
  .lien-off {
    display: inline-block;
    transition: margin-left .3s;
    line-height: 1;

    &:hover {
      text-decoration: none;
      margin-left: .6rem;
      @media (max-width: $breakpoint-lg) {
        margin-left: 2.6rem;
      }
    }
  }

  @media (max-width: $breakpoint-sm) {
    > ul {
      overflow-y: auto;
      max-height: calc(100vh - 12.5rem);
      margin-top: -1.5rem;
      padding-top: 2rem;
    }
  }
}

.mp-level-2-open {
  .mp-level-1 {
    height: calc(100vh - 13rem);
    max-height: 100%;
    overflow: hidden;
    @media (max-width: $breakpoint-lg) {
      height: calc(100vh - 6.8rem);
    }
  }

  .back-menu-button {
    opacity: 1;
    visibility: visible;
  }

  .mp-level-2.mp-level-open {
    height: calc(100vh - 13rem);
    overflow-y: auto;
    @media (max-width: $breakpoint-lg) {
      height: calc(100vh - 6.9rem);
      margin-top: -2rem;
      padding-top: 2rem;
    }
    @media (max-width: $breakpoint-sm) {
      overflow: hidden;
    }
  }
}

.mp-level {
  transform: translateX(-100%);

  &.mp-level-open {
    transform: translateX(0);
  }
}

/* Level 2 si sous menu (picto arrow) */
.m-event:after {
  content: "\EA08";
  font-family: 'kfont';
  font-size: 1.4rem;
  font-weight: 400;
  margin-right: 1rem;
}

hr.tablette {
  margin-bottom: 2rem;
  @media (max-width: $breakpoint-xs) {
    margin-bottom: 1.5rem;
  }
}

/* lists links */

#mp-menu .mp-level ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

/* Burger */

nav:has(.menu-launcher-c) {
  position: fixed;
  top: 0;
  left: 8rem;
  width: 8rem;
  height: 8rem;
  z-index: 9;
  @media (max-width: $breakpoint-xxl) {
    left: 2rem;
  }
  @media (max-width: 1600px) {
    left: 0;
  }
  @media (max-width: 1500px) {
    position: absolute;
    right: 2rem;
    left: auto;
    width: auto;
    height: auto;
  }
  @media (max-width: $breakpoint-lg) {
    right: .5rem;
  }

  .menu-launcher-c {
    @include flex-center;
    width: 8rem;
    height: 8rem;
    @media (max-width: 1500px) {
      width: auto;
      height: auto;
    }
  }
}


.menu-launcher {
  position: relative;
  width: 4rem;
  height: 3.6rem;
  margin-top: -1.3rem;
  cursor: pointer;
  z-index: 9999999;
  @media (max-width: 1500px) {
    margin-top: 1.5rem;
  }
  @media (max-width: $breakpoint-lg) {
    margin-top: 2.4rem;
  }

  .libelle-menu-launcher {
    position: absolute;
    bottom: -2rem;
    left: 0;
    display: block;
    @media (max-width: $breakpoint-lg) {
      display: none;
    }
  }
}

.picto-menu {
  position: absolute;
  top: 1.6rem;
  left: .8rem;
  width: 2.4rem;
  height: .3rem;
  background: $principale;
  display: inline-block;
  transition: all .3s ease-in-out 0s;

  &::before,
  &::after {
    background: $principale;
    content: "";
    display: inline-block;
    height: .3rem;
    left: 0;
    position: absolute;
    transform-origin: center center 0;
    transition: all .3s ease-in-out 0s;
    width: 2.4rem;
  }

  &::before {
    top: .9rem;
  }

  &::after {
    top: -.9rem;
  }
}

/* Overlay global */

body::before {
  content: '';
  position: absolute;
  display: block;
  z-index: 10002;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 180vh;
  background: transparent;
  pointer-events: none;
  transition: all .5s ease-in-out, height 10s ease-in-out;
}

/* Compensation scrollbar + affichage overlay */
body.menu-visible,
body.register-visible,
body.panier-visible,
body.autocomplete-search-visible {
  position: relative;
  overflow-y: hidden;
  @media (min-width: $breakpoint-lg) {
    padding-right: 1.7rem;
  }

  &::before {
    pointer-events: all;
    background: rgba(0, 0, 0, .5);
    transition: background .5s ease-in-out;
  }

  .gotop {
    right: 5.7rem;
  }
}

body.filtres-visible {
  &::before {
    pointer-events: all;
    background: rgba(0, 0, 0, .5);
    transition: background .5s ease-in-out;
    width: calc(100% - 52rem);
    height: 180vh;
    @media (max-width: $breakpoint-sm) {
      width: calc(100% - 42rem);
    }
    @media (max-width: $breakpoint-xs) {
      width: 5rem;
    }
  }

  #header {
    z-index: 2;
  }

  .panneau-recherche-c {
    z-index: -1;
  }
}

body.overflow-lateral {
  position: relative;
  overflow-y: hidden;
  @media (min-width: $breakpoint-lg) {
    padding-right: 1.7rem;
  }

  #header {
    z-index: 2;
  }
}

/***************/
/* Menu ouvert */
/***************/

body.menu-visible {
  &::before {
    z-index: 10001;
  }

  .full-menu {
    display: block;
  }

  .mp-menu {
    transform: translateX(0);
  }

  .picto-menu {
    background: transparent none repeat scroll 0 0;

    &::before,
    &::after {
      top: 0;
      transform-origin: 50% 50% 0;
    }

    &::before {
      height: .2rem;
      transform: rotate(45deg);
    }

    &::after {
      height: .2rem;
      transform: rotate(-45deg);
    }
  }
}

/* Full menu pour afficher niveau 3 et 4 */
.full-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 61rem;
  display: none;
  width: calc(100% - 61rem);
  height: 100vh;
  padding-top: 12.8rem;
  background-color: #fff;
  opacity: 0;
  z-index: 10002;
  transform: translateX(-100%);
  transition: all .5s ease-in-out;
  @media (max-width: $breakpoint-lg) {
    left: 38rem;
    width: calc(100% - 38rem);
    padding-top: 0;
  }
  @media (max-width: $breakpoint-sm) {
    left: 32rem;
    width: calc(100% - 32rem);
  }
  @media (max-width: $breakpoint-xs) {
    top: 6.9rem;
    left: 0;
    width: 100%;
    z-index: 99999;
  }

  &.full-menu-open {
    opacity: 1;
    transform: translateX(0);
  }

  .full-menu-content {
    height: 100%;
    padding: 0 3.2rem 4rem;
    border-left: .2rem solid $neutre_gris_300;
    overflow-x: auto;
    @media (max-width: $breakpoint-xxl) {
      padding: 0 2.2rem 4rem;
    }
    @media (max-width: $breakpoint-lg) {
      padding-top: 3rem;
      padding-left: 1.2rem;
      padding-right: 1.2rem;
    }
    @media (max-width: $breakpoint-sm) {
      .img-menu-carrefour {
        display: none;
      }
    }
    @media (max-width: $breakpoint-xs) {
      padding-bottom: 8rem;
    }
  }

  .h5 {
    margin-bottom: 4rem;
    @media (max-width: $breakpoint-lg) {
      justify-content: center;
    }
    @media (max-width: $breakpoint-xs) {
      display: flex;
      justify-content: space-between;
      text-align: center;
      .fake-div-smenu-mobile {
        width: 5rem;
      }
    }
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  .level-3-container {
    display: flex;
    gap: 2rem;
    @media (max-width: $breakpoint-md) {
      flex-wrap: wrap;
    }
  }

  .mp-level-3 {
    &.mp-level-3-principal {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0 3rem;
      width: calc(100% - 25rem);
    }

    &.mp-level-3-annexe {
      width: 25rem;
      display: flex;
      flex-direction: column;
    }

    @media (max-width: $breakpoint-md) {
      &.mp-level-3-principal,
      &.mp-level-3-annexe {
        width: 100%;
      }
    }
    @media (max-width: $breakpoint-sm) {
      &.mp-level-3-principal {
        grid-template-columns: 1fr;
      }
    }

    li {
      margin-bottom: 2.4rem;
    }

  }

  .mp-level-3 .h6,
  .mp-level-4 li {
    margin-bottom: 1.4rem;
  }

  .lien-off {
    display: inline-block;

    &:hover {
      text-decoration: underline;
    }
  }
}

/* Menu en mobile */
.minisite-menu-container {
  //position: absolute;
  //bottom: 0;
  //left: 0;
  //right: 0;
  padding-bottom: 1rem;
  background-color: #fff;
  @media (max-width: $breakpoint-md) {
    display: block;
  }
}

.mp-menu .maison-nathan-menu {
  display: block;
  border-radius: .8rem;
  border: .1rem solid $neutre_gris_300;
  background-color: $neutre_gris_50;
  margin-left: 0;
  margin-right: 1.2rem;
  margin-top: 3.2rem;
  padding: 1.8rem 1rem 1.8rem 7.8rem;
  transition: background-color .3s, border-color .3s;
  @media (max-width: $breakpoint-lg) {
    max-width: calc(100% - 2rem);
    margin-left: auto;
    margin-right: auto;
  }

  .h3 {
    flex-direction: row !important;
    font-family: 'LaMaisonNathan', sans-serif;
    font-size: 3.5rem;
    margin-bottom: 0;
    font-weight: 400;
    @media (max-width: $breakpoint-md) {
      font-size: 2.8rem;
      margin-left: 0;
      justify-content: flex-start;
      align-items: center;

      &::before {
        position: relative;
        top: .4rem;
        left: 0;
      }
    }
  }

  &:hover {
    border-color: $neutre_gris_800;
    background-color: $neutre_gris_100;
  }

  @media (max-width: $breakpoint-md) {
    margin-top: 1.2rem;
    margin-left: 1.2rem;
    padding-left: 0;
  }

  @media (max-width: $breakpoint-sm) {
    margin-bottom: 3.2rem;
  }

}

.menu-back-mobile {
  font-family: "Inter", sans-serif;
  font-size: 1.6rem;
  text-align: center;
  font-weight: 400;
  margin-right: 1rem;
  cursor: pointer;
  @media (max-width: $breakpoint-xs) {
    line-height: .7;
  }

  .icon {
    display: block;
    font-size: 1.2rem;
    margin-bottom: .8rem;
  }

  &.mobile {
    display: none;
    @media (max-width: $breakpoint-xs) {
      display: block;
    }
  }
}

.fake-div-menu-mobile {
  width: 6.6rem;
}

.links-menu-mobile {
  margin-left: 1.6rem;
  margin-bottom: 2.4rem;

  .lien-off,
  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

/* Menu sans JS */
.mp-menu-nojs {
  max-width: 1rem;
  max-height: 1rem;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0;

  .mp-menu {
    position: static;
    transform: translateX(0);
    opacity: 1;

    ~ .full-menu {
      position: static;
      display: block;
      transform: translateX(0);
      opacity: 1;
    }
  }
}