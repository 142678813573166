.bloc-promo-produit {
  display: flex;
  align-items: center;
  gap: 18rem;
  @media (max-width: $breakpoint-xl) {
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 2.4rem;
  }

  &:not(.gauche) {
    @media (max-width: $breakpoint-xl) {
      flex-direction: column-reverse;
    }
  }

  &.gauche {
    @media (max-width: $breakpoint-xxl) {
      gap: 16rem;
    }
    @media (max-width: $breakpoint-xl) {
      justify-content: flex-end;
      gap: 2.4rem;
    }

    .bloc-promo-content {
      order: -1;
      margin-left: 0;

      .h3 {
        @media (max-width: $breakpoint-xxl) {
          margin-left: unset;
        }
      }
    }

    .bloc-liste-produit .list-3-cards {
      margin-left: -12rem;
      margin-right: 4rem;
    }
  }

  .slider-promo-container {
    max-width: 100%;
  }

  .bloc-liste-produit {
    padding: 2rem 0 2rem 3.4rem;
    border-radius: 2.4rem;
    background-color: $neutre_beige_300;
    @media (max-width: $breakpoint-md) {
      background-color: unset;
      padding: 0;
      width: 100%;
    }

    .list-3-cards {
      margin-right: -8rem;
      @media (max-width: $breakpoint-md) {
        display: none;
      }

      .swiper-slide {
        width: 26rem;
      }
    }

    #slider-promo-produit {
      display: none;
      @media (max-width: $breakpoint-md) {
        display: block;
      }

      .swiper-container {
        padding-top: 1.6rem;

        .swiper-wrapper {
          justify-content: left;
          padding-top: 0;
        }
      }
    }
  }

  .bloc-promo-content {
    .h3 {
      margin-left: -4.3rem;
      margin-bottom: 1.6rem !important;
      @media (max-width: $breakpoint-xl) {
        margin-left: unset;
      }

      &::before {
        font-size: 2.6rem;
      }

      &::after {
        left: -.1rem;
        top: 2rem;
      }
    }

    .button {
      margin-top: 1.6rem;
    }
  }
}