.gotop {
  position: fixed;
  bottom: 10rem;
  right: 4rem;
  border: $border-invisible;
  border-radius: 50%;
  color: $blanc;
  background-color: $cta;
  opacity: .85;
  width: 4rem;
  height: 4rem;
  line-height: 2.2;
  text-align: center;
  z-index: 65;
  cursor: pointer;
  font-size: 1.8rem;
  transition: background-color .3s, color .3s, border .3s ease-in-out;
  border: none;

  @media (max-width: $breakpoint-sm) {
    right: 3rem;
    bottom: 3rem;
  }

  @media (max-width: $breakpoint-xs) {
    right: 1rem;
    bottom: 2rem;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(270deg);
    width: 4rem;
    height: 4rem;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:hover {
    background-color: $blanc;
    color: $principale;
    border: $border-main;
  }
}


