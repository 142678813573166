.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;

  li {
    margin-right: .5rem;
    margin-left: .5rem;
    list-style-type: none;

    &:not(.navig) {
      a {
        font-weight: bold;
        display: inline-block;
        padding: .7rem 1.7rem;
        font-size: 2rem;
        text-align: center;
        color: $noir;
        border-radius: .5rem;
        background: $noir;
      }

      a:hover,
      &.current a {
        color: $blanc;
        background: $principale;
      }
    }

    &.navig {
      margin-right: .8rem;
      margin-left: .8rem;

      a {
        font-size: 2.5rem;
        color: $noir;

        &:hover {
          color: $principale;
        }

        .icon {
          @include flex-center;
        }
      }
    }
  }
}
