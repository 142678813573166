/*---------------*/
/***** Bubba *****/
/*---------------*/

.effect-bubba {
  background : #999;
  img {
    opacity: 0.9;
    transform: scale(1.12);
    transition: opacity 0.35s, transform 0.35s, filter 0.35s;
  }
  .overlay{
    &::before,
    &::after{
      content: '';
      position: absolute;
      top: 1.5rem;
      right: 2rem;
      bottom: 2rem;
      left: 1.5rem;
      opacity: 0;
      transition: opacity 0.35s, transform 0.35s;
    }
    &::before{
      border-top: .1rem solid #fff;
      border-bottom: .1rem solid #fff;
      transform: scale(0,1);
    }
    &::after{
      border-right: .1rem solid #fff;
      border-left: .1rem solid #fff;
      transform: scale(1,0);
    }
  }
  .title {
    position : absolute;
    width : calc(100% - 5rem);
    bottom : 60%;
    left : 2.5rem;
    margin-top : -3rem;
    opacity: 0;
    transition: opacity 0.35s, transform 0.35s;
    transform: translate3d(0,2rem,0);
    @media (max-width: $breakpoint-sm) {
      top: 40%;
      bottom: auto;
    }
    @media (max-width: $breakpoint-xs) {
      top: 50%;
      &:hover .title{
        transform: translate3d(0, 50%, 0);
      }
    }
  }
  .description {
    position : absolute;
    width : calc(100% - 5rem);
    top : 40%;
    left : 2.5rem;
    margin-top : 1rem;
    text-align : center;
    opacity: 0;
    transition: opacity 0.35s, transform 0.35s;
    transform: translate3d(0,2rem,0);
  }
}

.effect-bubba:hover{
  img {
    opacity: 0.2;
    transform: scale(1);
    filter: blur(.4rem);
  }
  .overlay::before,
  .overlay::after{
    opacity: 1;
    transform: scale(1);
  }
  .title,
  .description {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}
