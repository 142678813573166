.type-accordeon {
  position: relative;
  margin-bottom: 1.6rem;
  border-radius: 1.6rem;
  padding: 2rem;
  background: #eee;
  @media (max-width: $breakpoint-xs) {
    padding: 1rem;
  }

  .accordeon-header {
    margin-bottom: 3rem;
    border-bottom: .1rem solid #aaa;
    cursor: pointer;
    transition: opacity .3s;

    .accordeon-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 3rem;
      padding-right: 3rem;
      line-height: 1;
      margin-top: 1rem;
      @media (max-width: $breakpoint-xs) {
        padding-left: 1rem;
      }

      .icon {
        transition: transform .3s;
        font-size: 3rem;
        margin-right: -3rem;
      }
    }

    &:hover {
      opacity: .8;
    }

    &.open {
      .accordeon-title .icon {
        transform: rotate(90deg);
      }
    }
  }

  .accordeon-body {
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

