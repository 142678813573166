.swiper-container {
  position: relative;
  overflow: hidden;
  padding-top: .5rem;
}

/* Slider avec slide affichée 1 par 1 */
.single-slider,
.single-slider .slide img {
  /*height: 50rem;*/
}

.desc-slider {
  margin-top: 1.2rem;
}

.single-slider {
  padding-left: 0;
  margin-bottom: 0;

  .slide {
    position: relative;

    img {
      width: 100%;
      object-fit: cover;
    }

    .islide-content {
      @include absolute-container;
      @include flex-center;

      .islide {
        width: 45%;
        padding: 4rem;
        text-align: center;
        background-color: rgba(255, 255, 255, .5);

        .h1 {
          margin-bottom: 1.5rem;
        }

        .h3,
        .desc {
          margin-bottom: 3rem;
        }

        .button {
          cursor: pointer;

          &:hover {
            color: $principale;
            background-color: $blanc;
          }
        }
      }
    }
  }

  .slick-prev {
    left: 3.5rem;
  }

  .slick-next {
    right: 3.5rem;
  }

  .slick-next::before,
  .slick-prev::before {
    color: $cta;
  }

  .slick-dots {
    bottom: 1.5rem;

    li button::before {
      background-color: $cta;
    }
  }
}

.swiper-pagination {
  bottom: -2.4rem !important;

  .swiper-pagination-bullet {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50rem;
    opacity: 1;
    transition: width .3s, background-color .3s ease-in-out;
    background-color: $neutre_gris_600;

    &:hover {
      background-color: $principale;
    }

    &-active {
      width: 3.2rem;
      background-color: $principale;
    }
  }
}

/* Navigation globale : scrollbar */
.swiper-scrollbar {
  display: flex;
  align-items: center;
  background-color: $neutre_gris_300;
  opacity: .8;
}

.swiper-scrollbar-drag {
  background-color: $principale;
  opacity: 1;
  transition: height .3s ease-in-out;
  &:hover, &:focus {
      height: 1rem !important;
  }
}

/* Slider avec plusieurs slides affichées SWIPER */
.multi-slider {
  position: relative !important;
  float: none !important;

  &:has(.swiper-scrollbar-horizontal) {
    margin-bottom: 3rem;
  }

  .swiper-wrapper {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .swiper-slide {
    height: auto;

    .slider-card {
      display: flex;
      flex-direction: column;
      height: 100%;

      .top-card-content,
      .bottom-card-content {
        padding: .5rem 1rem 1rem;
      }

      .bottom-card-content {
        margin-top: auto;
      }

      .desc {
        @include line-clamp(3);
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    @include flex-center;
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
    border: .1rem solid $neutre_gris_300;
    box-shadow: $box-shadow-black;
    color: $principale;
    background-color: #fff;
    transition: background-color .3s;

    &::after {
      font-family: kfont;
      content: "\FA37";
      font-size: 2rem;
    }

    &:hover {
      background-color: $neutre_bleu_100;
    }
  }

  .swiper-button-next {
    right: -2.5rem;
  }

  .swiper-button-prev {
    left: -2.5rem;

    &::after {
      transform: rotate(180deg);
    }
  }

  .swiper-scrollbar-horizontal {
    bottom: -1.2rem;
    height: .6rem;
    max-width: 57.8%;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }

  @media (max-width: $breakpoint-sm) {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }

  &.no-control {
    .swiper-button-disabled {
      display: none;
    }

    .swiper-wrapper {
      width: fit-content;
      margin: 0 auto;
    }
  }
}

.swiper-button-lock {
  display: none !important;
}

/* Slider avec des vignettes (page article et news) */

.slider-single,
.slider-single-news {
  .slider-nav-container {
    position: relative;

    .slider-nav-content,
    figure {
      @include absolute-container;
      /*@include box-shadow;*/
      @include flex-center;
      cursor: pointer;
    }
  }
}

.slider-nav,
.slider-nav-news {
  .slick-track {
    margin: 0 1rem 1rem;

    .slick-slide {
      margin-right: 1.5rem;

      img {
        object-fit: cover;
        /*@include box-shadow-light;*/
        cursor: pointer;
        border: .2rem solid transparent;
        padding: .2rem;
      }
    }
  }

  .slick-slide.slick-current img {
    border-color: $principale;
  }
}

.fancybox-thumbs__list a::before {
  border: 4px solid $principale;
}

.tag-actu {
  display: inline-block;
  color: $blanc;
  background-color: $principale;
  padding: .1rem .3rem;
  margin-bottom: .4rem;
}

@each $type in produits, articles, videos {
  .bloc-carrousel-#{$type}:has(.multi-slider:not(.no-control)) {
    padding-bottom: 2rem;
  }
}
