.page-article {
  .hero {
    padding-bottom: 7rem;
  }
  #contenu {
    margin-bottom: 6rem;
    padding-top: 6rem;
  }
  .article-source {
    text-align: right;
    font-style: italic;
    margin-bottom: 4rem;
  }
  .article-container {
    display: flex;
    .article-contenu {
      max-width: 100%;
    }
    &.bloc-ean{
      gap: 4rem;
      .article-contenu {
        max-width: calc(100% - 34rem);
      }
    }
    &.restricted {
      pointer-events: none;
      margin-bottom: 20rem;
      .grid-builder {
        position: relative;
        //max-height: 45%;
        overflow: hidden;
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          display: block;
          height: 80%;
          background: rgb(255,255,255);
          background: linear-gradient(180deg,rgba(255,255,255,0) 0,rgba(255,255,255,.6) 65%,#fff 66%,#fff 100%);        }
      }
    }
  }
  .main-img {
    img {
      width: 100%;
      object-fit: cover;
      border-radius: 1.6rem;
    }
    .legende-img {
      margin-top: .8rem;
      margin-bottom: 4rem;
    }
  }
  .bloc-ressources {
    margin-top: 12rem;
    @media (max-width: $breakpoint-sm) {
      margin-top: 8rem;
    }
  }
  .bloc-agenda {
    display: flex;
    > div {
      width: 50%;
    }
    .col-date {
      display: flex;
      align-items: center;
      margin-bottom: auto;
    }
    .col-lieu {
      display: flex;
      padding-left: 1.6rem;
      border-left: .1rem solid $neutre_gris_300;
      p {
        margin-bottom: .8rem;
      }
    }
    @media (max-width: $breakpoint-md) {
      flex-direction: column;
      > div {
        width: 100%;
      }
      .col-lieu {
        border-top: .1rem solid $neutre_gris_300;
        border-left: 0;
        padding-left: 0;
        padding-top: 1rem;
        margin-top: 1rem;
      }
    }
    .icon {
      font-size: 3.2rem;
      margin-right: .8rem;
    }
    .highlight {
      font-weight: 700;
    }
  }
  .card-ean-article {
    position: sticky;
    top: 3rem;
    border: .1rem solid $neutre_gris_300;
    border-radius: .8rem;
    background-color: $neutre_gris_50;
    padding: 1.7rem 1.5rem;
    width: 30rem;
    .head-ean {
      text-align: center;
      font-style: italic;
      margin-bottom: 1.4rem;
    }
    .text-ean {
      text-align: center;
      margin-top: 2.4rem;
      margin-bottom: 2.4rem;
    }
    .button {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .block-beige-full {
    position: relative;
    background-color: $collection_fond;
    padding-top: 3rem;
    padding-bottom: 3rem;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: calc(-50vw + 25rem);
      right: 100%;
      bottom: 0;
      background-color: $collection_fond;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 100%;
      right: calc(-50vw + 26rem);
      bottom: 0;
      background-color: $collection_fond;
    }
  }
}

/* Popin connexion */

.popin-connexion-article {
  position: absolute;
  z-index: 1;
  left: 50%;
  bottom: -17rem;
  transform: translateX(-50%);
  max-width: 73rem;
  width: calc(100% - 3rem);
  padding: 2.4rem 2.2rem;
  border-radius: 1.6rem;
  border: .1rem solid $neutre_gris_300;
  background: #fff;
  box-shadow: 0 .2rem 2rem 0 rgba(0, 0, 0, .1);
  @media (max-width: $breakpoint-sm) {
    padding: 1.5rem;
  }

  .popin-connexion-ressources {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin-top: 1.2rem;
      margin-left: .8rem;
    }
    @media (max-width: $breakpoint-xs) {
      flex-direction: column;
    }
  }
  hr.light {
    height: .2rem;
  }
  .cta-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3.5rem;
    gap: 1.5rem;
    @media (max-width: $breakpoint-sm) {
      flex-direction: column;
      gap: 1.5rem;
    }
    .button {
      font-weight: 500;
      margin-left: auto;
      margin-right: auto;
    }

  }
  .popin-connexion-cta {
    display: flex;
    align-items: center;
    gap: .6rem;
    @media (max-width: $breakpoint-xs) {
      flex-direction: column;
    }
  }

}

.connexion-popin-c {
  .fancybox-bg {
    background: transparent;
  }
}