.page-produit {
  .espace-accordeon {
    @media (max-width: $breakpoint-md) {
      display: block;
      margin: 0 !important;
      padding: 0 !important;
      height: 7.2rem;
      width: 100%;
      transition: height .3s ease-in-out;
    }

    &-s {
      @media (max-width: $breakpoint-md) {
        display: block;
        margin: 0 !important;
        padding: 0 !important;
        width: 100%;
        height: 2.4rem;
      }
    }
  }

  /////////////////// Menu Galaxie ////////////////////

  .menu-produit {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
    display: flex;
    justify-content: center;
    overflow-y: hidden;
    @media (max-width: $breakpoint-sm) {
      top: -1.2rem;
    }

    ul {
      display: flex;
      align-items: flex-end;
      margin: 0;
      padding: 0;

      li {
        list-style: none;

        &.swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          width: unset;
          height: unset;
        }

        &:not(.swiper-slide-active) {
          .entite-produit {
            opacity: .5;
          }
        }

        .entite-produit {
          position: relative;
          @include flex-center;
          gap: .8rem;
          background-color: $neutre_gris_100;
          border: $border-clear;
          border-radius: $radius-s $radius-s 0 0;
          box-shadow: 1px 4px 10px 0 rgba(0, 0, 0, 0.09);
          padding: .8rem 1.6rem;
          transition: background-color .3s, border .3s, opacity .3s ease-in-out;
          @media (max-width: $breakpoint-sm) {
            justify-content: left;
            background-color: $blanc !important;
            border-radius: $radius-s;
            width: 75%;
          }
          @media (max-width: 400px) {
            width: 70%;
          }

          &:hover {
            background-color: $blanc;
            border: $border-invisible;
            opacity: 1;
          }

          .menu-produit-box {
            @include flex-center;
            background-color: $neutre_gris_100;
            height: 5.6rem;


            .menu-produit-img {
              aspect-ratio: 40/56;
              width: auto;
              height: 100%;
              object-fit: contain;
              border-radius: .2rem;
            }
          }

          p {
            @include line-clamp(3);
          }
        }

        &:first-child {
          .entite-produit {
            background-color: $blanc;
            border: $border-invisible;
            opacity: 1;

            &::after {
              opacity: 1;
            }
          }
        }
      }
    }

    .fondu-menu-produit-gauche, .fondu-menu-produit-droite {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      display: block;
      background: $neutre_beige_300;
      width: 30rem;
      height: 100%;
      opacity: 0;
      pointer-events: none;
      transition: opacity .3s ease-in-out;
      z-index: 1;
      @media (max-width: $breakpoint-md) {
        width: 10rem;
      }
      @media (max-width: $breakpoint-sm) {
        display: none;
      }
    }

    .fondu-menu-produit-gauche {
      left: -.1rem;
      background: linear-gradient(90deg, rgba(246, 240, 233, 1) 15%, rgba(0, 0, 0, 0) 100%);
    }

    .fondu-menu-produit-droite {
      right: -.1rem;
      background: linear-gradient(270deg, rgba(246, 240, 233, 1) 15%, rgba(0, 0, 0, 0) 100%);
    }

    .swiper-button-next, .swiper-button-prev {
      position: absolute;
      top: 49%;
      width: 4.8rem;
      height: 4.8rem;
      padding: 0;
      box-shadow: 0 .1rem 1.4rem 0 rgba(0, 0, 0, 0.13);
      opacity: 0;
      transition: opacity .3s ease-in-out;

      &.active {
        opacity: 1;
      }

      &.swiper-button-disabled {
        opacity: 0;
      }
    }

    .swiper-button-prev {
      left: 1rem;

      &::after {
        transform: rotate(180deg);
      }

      &:not(.swiper-button-disabled) ~ .fondu-menu-produit-gauche {
        &.active {
          opacity: 1;
        }
      }
    }

    .swiper-button-next {
      right: 1rem;

      &:not(.swiper-button-disabled) ~ .fondu-menu-produit-droite {
        &.active {
          opacity: 1;
        }
      }
    }
  }

  //////////// Partie haute du produit ////////////////

  .contenu-produit {
    margin-bottom: 3.2rem !important;

    .contenu-produit-flex {
      display: flex;
      justify-content: space-between;
      gap: 4rem;
      @media (max-width: $breakpoint-xl) {
        flex-wrap: wrap;
      }
      @media (max-width: $breakpoint-lg) {
        gap: 2.4rem;
        flex-direction: column;
      }

      .contenu-produit-flex-slider {
        position: relative;
        flex: 2;
        border: $border-clear;
        border-radius: $radius-s;
        width: 45rem;
        height: 100%;
        @media (max-width: $breakpoint-xl) {
          width: 35rem;
        }
        @media (max-width: $breakpoint-lg) {
          width: 45rem;
        }
        @media (max-width: $breakpoint-xs) {
          width: 100%;
        }

        .tag-element-red,
        .tag-element-orange {
          position: absolute;
          top: 0;
          left: 1.6rem;
          transform: translateY(-50%);
          z-index: 10;
        }

        .container-slide-thumps-produit {
          position: relative;
          background-color: $neutre_gris_100;
          padding: .8rem 5.5rem;
          overflow: hidden;

          .js-slide-thumps-produit {
            position: unset;
            height: 80%;
            width: 100%;

            .swiper-wrapper {
              position: unset;
              align-items: center;

              .swiper-slide {
                .box-visuel-produit {
                  aspect-ratio: 240 / 310;
                  height: 10rem;
                  margin: 0 auto;
                }

                img {
                  aspect-ratio: 240 / 310;
                  display: block;
                  cursor: pointer;
                  height: 100%;
                  background-color: $blanc;
                  margin: 0 auto;
                  border-radius: .2rem;
                  object-fit: contain;
                }
              }
            }

            .swiper-button-next, .swiper-button-prev {
              position: absolute;
              top: 50%;
              transform: translateY(50%);
              background-color: $white;
              border: $border-clear;
              border-radius: 50rem;
              width: 2.4rem;
              height: 2.4rem;

              &::after {
                content: "\FA2C";
                font-family: kfont;
                font-size: 1rem;
                color: $principale;
              }
            }

            .swiper-button-prev {
              &::after {
                transform: rotate(180deg);
              }
            }
          }
        }

        .js-slide-produit {
          width: 100%;
          height: 20%;
          box-sizing: border-box;
          padding: 4.4rem 2.4rem;

          .swiper-wrapper {
            align-items: center;

            .swiper-slide {
              height: 100%;

              .box-visuel-produit {
                aspect-ratio: 240 / 310;
                height: 45rem;
                width: 100%;

                .no-photo, img {
                  aspect-ratio: 240 / 310;
                  display: block;
                  height: 100%;
                  object-fit: contain;
                  margin: 0 auto;
                }

                img {
                  cursor: pointer;
                }
              }

              .flip-container {
                perspective: 100rem;

                .flipper {
                  transition: 0.6s;
                  transform-style: preserve-3d;
                  position: relative;

                  &.hover {
                    transform: rotateY(-15deg) !important;
                  }

                  &.active {
                    transform: rotateY(-180deg);
                  }

                  &.active.hover {
                    transform: rotateY(-165deg) !important;
                  }

                  .front,
                  .back {
                    backface-visibility: hidden;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    height: 100%;
                    margin: 0 auto;
                  }

                  .front {
                    z-index: 2;
                    transform: rotateY(0deg);
                  }

                  .back {
                    transform: rotateY(180deg);
                  }

                  img {
                    aspect-ratio: 240 / 310;
                    display: block;
                    height: 100%;
                    object-fit: contain;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }

        .edition-produit {
          position: absolute;
          top: .8rem;
          left: 50%;
          transform: translateX(-50%);
          z-index: 5;
        }

        .button-round-icon-retourner {
          position: absolute;
          top: 50%;
          right: .8rem;
          transform: translateY(-50%);
          width: 4.8rem;
          height: 4.8rem;
          box-shadow: none;
          z-index: 5;
          opacity: 0;
          pointer-events: none;
          transition: opacity .1s ease-in-out;

          &.active {
            opacity: 1;
            pointer-events: all;
          }
        }
      }

      .contenu-produit-flex-description {
        flex: 3;
        @media (max-width: $breakpoint-xl) {
          flex: unset;
          width: 60%;
        }
        @media (max-width: $breakpoint-lg) {
          width: 100%;
        }

        .informations-produit {
          margin-bottom: 2.4rem;

          & > *:not(:last-child) {
            margin-bottom: .8rem;
          }

          h1 {
            margin-bottom: 2rem !important;
          }

          .informations-produit-flex {
            display: flex;
            align-items: center;
            gap: .8rem;

            & > *:not(:first-child) {
              display: flex;
              gap: .8rem;

              &::before {
                content: '';
                display: block;
                background-color: $neutre_gris_300;
                min-width: .1rem;
              }
            }
          }
        }

        .liste-tags-produit {
          display: flex;
          gap: .4rem;
          flex-wrap: wrap;
          margin-bottom: 2.4rem;

          .collection-produit-lien {
            cursor: pointer;
          }

          .vide {
            line-height: 3rem;
          }
        }

        .description-produit-1, .description-produit-2 {
          & > *:not(:last-child) {
            margin-bottom: .8rem;
          }
        }

        .description-produit-2 {
          button {
            margin: 0 auto;
            padding: 1rem 2.4rem;
          }
        }

        .ressources-produit {
          display: flex;
          gap: .8rem;

          .icon {
            position: relative;
            height: fit-content;
            margin-top: .4rem;

            &::before {
              position: relative;
              font-size: 2rem;
              z-index: 1;
            }

            .surligne {
              left: -.1rem;
            }
          }

          .textes-ressources {
            & > *:not(:last-child) {
              margin-bottom: .8rem;
            }
          }
        }

        hr {
          margin: 2.4rem 0;
        }
      }

      .contenu-produit-flex-prix {
        flex: 1.5;
        max-width: 28.5rem;
        @media (max-width: $breakpoint-xl) {
          max-width: 50%;
          flex: unset;
        }
        @media (max-width: $breakpoint-sm) {
          max-width: 80%;
        }
        @media (max-width: $breakpoint-xs) {
          max-width: unset;
        }

        & > *:not(:last-child) {
          margin-bottom: .8rem;
        }

        .blocs-produit-prix {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: stretch;
          gap: 1.2rem;
          font-size: $font-xs;
          margin-bottom: 2.6rem;

          .flex-produit-prix {
            display: flex;
            align-items: stretch;
            gap: .8rem;

            &:has(.ttc:not(.active)) ~ .frais-de-port{
              display: none;
            }
          }

          .bloc-produit-prix {
            position: relative;
            display: flex;
            justify-content: space-between;
            background-color: $neutre_gris_100;
            width: 50%;
            height: 11rem;
            flex-direction: column;
            border: $border-clear;
            border-radius: $radius-s;
            padding: .8rem 3.2rem .8rem .8rem;
            cursor: pointer;
            transition: background-color .3s, border .3s ease-in-out;

            &:hover {
              background-color: $blanc;
            }

            &.active {
              border: $border-main;
              background-color: $blanc;
            }

            .prix-barre {
              color: $neutre_gris_800;
              font-size: $font-s;
              font-weight: 700;
              text-decoration: line-through;
            }

            .prix-actuel {
              font-size: $font-xxl;
              font-weight: 700;
              line-height: .9;
            }

            .icon {
              position: absolute;
              top: .8rem;
              right: .8rem;

              &::before {
                font-size: 2rem;
              }
            }
          }
        }

        .frais-de-port {
          display: flex;
          align-items: center;
          gap: .6rem;
          font-size: $font-xs;

          .icon-gsn-commandes {
            &::before {
              font-size: 1.6rem;
            }
          }

          .icon-gsn-info {
            &::before {
              font-size: 2rem;
              cursor: pointer;
            }
          }
        }

        .button {
          width: 100%;
          @media (max-width: $breakpoint-lg) {
            margin-left: 0;
          }
        }

        .tag-element-promo {
          width: 100%;
        }
      }
    }
  }

  .liens-produit {
    margin-bottom: 2.4rem !important;

    .liste-liens-produit {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      gap: 1.6rem;
      margin: 0;
      padding: 0;

      li {
        display: flex;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        gap: .8rem;
        list-style: none;
        width: 22rem;
        @media (max-width: $breakpoint-md) {
          width: calc(100% / 3 - 1.1rem);
        }
        @media (max-width: $breakpoint-sm) {
          width: calc(50% - .8rem);
        }
        @media (max-width: 400px) {
          width: 100%;
        }

        .lien-produit-principale {
          justify-content: space-between;
          gap: .8rem;
          font-weight: $weight-normal;
          text-align: center;
          background-color: $neutre_gris_50;
          border: $border-clear;
          border-radius: $radius-s;
          width: 100%;
          min-height: 6.4rem;
          padding: .8rem 2rem;

          &.no-link {
            &:hover {
              text-decoration: none;
            }
          }

          &.lien-livre-offert {
            img {
              width: 6rem;
              height: 4rem;
            }
          }

          .text-container {
            width: calc(100% - 3.5rem);
          }

          .icon {
            position: relative;

            &::before {
              position: relative;
              z-index: 1;
              font-size: 2.5rem;
            }

            .surligne {
              left: 50%;
              transform: translateX(-50%);

              &::after {
                position: relative;
                bottom: -.5rem;
              }
            }
          }

          .surligne {
            left: .9rem;
            bottom: .4rem;

            &::after {
              font-size: 1.5rem;
            }
          }
        }

        .icon-gsn-cadenas-ferme::before {
          font-size: 2rem !important;
        }

        .lien-esp {
          @include flex-center;
          gap: .8rem;
          font-size: $font-xs;
          font-weight: $weight-normal;
          text-align: center;

          .icon::before {
            font-size: 1.5rem;
          }
        }

        .lien-fav {
          @include flex-center;
          gap: .8rem;
          color: $principale;
          font-size: $font-xs;
          width: 100%;

          &:hover {
            .libelle {
              text-decoration: underline $principale;
            }
          }

          &.active {
            .icon::before {
              display: none;
            }
            .icon::after {
              content: "\EA2B";
              display: block;
              font-family: kfont;
              font-size: 1.5rem;
            }
          }

          .icon::before {
            font-size: 1.5rem;
          }

          .icon::after {
            display: none;
          }
        }

        .lien-compagnon {
          background-color: #F2FCF4;

          .icon::before {
            font-size: 3.5rem;
          }

          .surligne {
            bottom: 1rem;
          }
        }
      }
    }
  }

  .prescripteur-produit {
    margin-bottom: 3.4rem !important;

    .bloc-prescripteur {
      @include flex-between;
      gap: 1.2rem;
      background-color: $neutre_gris_50;
      border: $border-clear;
      border-radius: $radius-s;
      padding: 3.2rem 9.2rem;

      @media (max-width: $breakpoint-md) {
        flex-direction: column;
        gap: 2.5rem;
        padding-left: 6rem;
        padding-right: 6rem;
      }

      @media (max-width: $breakpoint-xs) {
        flex-direction: column;
        gap: 2.5rem;
        padding-left: 2rem;
        padding-right: 2rem;
      }

      h2 {
        margin-bottom: .8rem;
      }
    }
  }

  .pub-produit {
    @include flex-center;
    margin-bottom: 3.6rem !important;
    @media (max-width: $breakpoint-md) {
      margin-bottom: 7.2rem !important;
    }

    a {
      background-color: $neutre_gris_50;
      border: $border-clear;
      border-radius: $radius-s;
      width: 100%;

      img {
        width: 100%;
        border-radius: $radius-s;
      }
    }
  }

  #contenu section {
    &.margin-large {
      margin-bottom: 10rem;
      @media (max-width: $breakpoint-md) {
        margin-bottom: 0;
      }
      @media (min-width: $breakpoint-md) {
        display: block;
      }
    }

    &:not(.margin-large) {
      @media (max-width: $breakpoint-md) {
        margin-bottom: 7.2rem;
      }
    }

    .sticky-mobile {
      &.collapse:not(.show) {
        @media (min-width: $breakpoint-md) {
          display: block;
        }
      }
    }

    h2.icon-souligne {
      justify-content: left;
      width: initial;

      &::before {
        @media (min-width: $breakpoint-md) {
          font-size: 3.4rem;
        }
      }

      &::after {
        font-size: 1.8rem;
        @media (max-width: $breakpoint-md) {
          font-size: 1.4rem;
        }
      }
    }

    .titre-desktop {
      @media (max-width: $breakpoint-md) {
        display: none;
      }
    }
  }

  .h4 {
    @media (max-width: $breakpoint-md) {
      font-size: 2rem;
    }
  }

  ///////////// Partie basse du produit ///////////////

  .contenu-descriptif {
    .container section.show + .espace-accordeon-s {
      display: none;
    }

    .bouton-accordeon-mobile {
      display: none;
      background-color: $blanc;
      padding: 2.4rem 0;
      @media (max-width: $breakpoint-md) {
        display: flex;
      }

      &:not(.collapsed) {
        position: sticky;
        top: -.1rem;
        z-index: 20;

        .icon {
          transform: rotate(90deg);
        }
      }

      .container {
        @include flex-between;
        gap: .8rem;
        text-align: left;

        h2, p.h3 {
          margin: 0;
          @media (max-width: $breakpoint-md) {
            align-items: center;
            font-size: 2.4rem;
          }

          @media (max-width: $breakpoint-xs) {
            font-size: 2rem;
          }
        }

        .icon {
          transition: all .3s ease-in-out;

          &::before {
            font-size: 2rem;
          }
        }
      }
    }

    .nav-ancre-produit {
      position: sticky;
      top: 0;
      background-color: $blanc;
      margin-bottom: 4.8rem !important;
      z-index: 99;
      @media (max-width: $breakpoint-md) {
        display: none;
      }

      nav {
        padding: 0;

        ul {
          display: flex;
          flex-wrap: wrap;
          list-style-type: none;
          padding: 0;
        }

        li {
          padding-top: 2.2rem;

          &:not(:last-child) {
            margin-right: 20px;
          }

          .link-element {
            cursor: pointer;
            border-bottom: .4rem solid;
            border-color: transparent;

            &:hover {
              .surligne {
                opacity: 1;
              }
            }

            .surligne {
              opacity: 0;
              transition: opacity .3s ease-in-out;
            }
          }
        }

        .link-element {
          @include flex-center;
          gap: .8rem;
          font-size: $font-m;
          font-weight: $weight-normal;
          text-decoration: none;
          padding: 1.6rem 3.2rem;

          .icon {
            position: relative;

            &::before {
              position: relative;
              z-index: 1;
              font-size: 1.9rem;
            }

            .surligne {
              left: -.3rem;
              bottom: .1rem;
            }

            &.icon-gsn-info, &.icon-gsn-ressources {
              .surligne {
                left: -.2rem;
              }
            }
          }
        }
      }

      .nav-ancre-soulignage {
        position: absolute;
        top: 2.2rem;
        border-bottom: .4rem solid;
        z-index: -1;
        opacity: 0;
        transition: all .35s ease-in-out;
      }

      hr {
        margin: 0;
        margin-top: -1rem;
      }
    }

    .presentation-produit {
      margin-top: 4.8rem;
      @media (max-width: $breakpoint-md) {
        margin-top: 0;
      }

      .sticky-mobile {
        & > *:not(:last-child) {
          margin-bottom: 4.8rem;
          @media (max-width: $breakpoint-md) {
            margin-bottom: 2.4rem;
          }
        }

        @media (max-width: $breakpoint-md) {
          & > *:nth-last-child(-n+2) {
            margin-bottom: 0;
          }
        }

        .picto-presentation {
          &::after {
            top: 2.4rem;
            @media (max-width: $breakpoint-md) {
              top: 1.6rem;
            }
          }
        }

        .accroche-bold {
          font-weight: $weight-bold;
        }

        .flex-points-forts {
          display: flex;
          justify-content: space-between;
          gap: 6rem;
          @media (max-width: $breakpoint-xl) {
            gap: 2.4rem;
          }
          @media (max-width: $breakpoint-lg) {
            flex-direction: column;
          }

          & > * {
            flex: 1;
          }

          .blocs-flex-points-forts {
            display: flex;
            align-items: stretch;
            gap: 2.6rem;
            @media (max-width: $breakpoint-sm) {
              flex-direction: column;
            }

            & > * {
              background-color: $neutre_gris_50;
              border: $border-clear;
              border-radius: $radius-ml;
              width: 35rem;
              max-width: 35rem;
              padding: 2rem;
              @media (max-width: $breakpoint-xs) {
                width: 100%;
              }
            }

            .nathan-live {
              display: flex;
              justify-content: space-between;
              flex-direction: column;
              gap: 1.2rem;

              .flex-nathan-live {
                display: flex;
                justify-content: space-between;
                gap: 1rem;

                .h5 {
                  font-size: $font-s;
                  margin-bottom: .8rem;
                }

                .desc {
                  font-size: $font-xs;
                }

                img {
                  width: 6rem;
                  height: 6rem;
                  border-radius: $radius-m;
                }
              }

              .button {
                margin: 0 auto;
                @media (max-width: $breakpoint-lg) {
                  font-size: $font-xs;
                }
                @media (max-width: $breakpoint-md) {
                  font-size: $font-s;
                }
              }
            }

            .acces-direct {
              .flex-acces-direct {
                display: flex;
                justify-content: space-between;
                gap: 1rem;

                .h5 {
                  font-size: $font-s;
                  margin-bottom: .8rem;
                }

                img {
                  width: 6rem;
                  height: 6rem;
                }
              }

              .desc {
                font-size: $font-xs;
              }
            }
          }
        }

        .descriptif-produit {
          overflow: hidden;

          &.fade-presentation {
            position: relative;

            &::after {
              content: '';
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              height: 8rem;
              background: rgb(255, 255, 255);
              background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 110%);
            }
          }

          .link-dans-texte-produit {
            text-decoration: underline;
          }
        }

        .button-icon-caret-bottom {
          display: none;
          margin-top: 1.6rem;
          margin-left: auto;
          margin-right: auto;
        }

        #slider-videos .swiper-button-next, #slider-videos {
          .swiper-button-prev, .swiper-button-next {
            top: 50%;
          }
        }
      }
    }

    .infos-detail-produit {
      .sticky-mobile {
        & > *:not(:last-child) {
          margin-bottom: 4.8rem;
          @media (max-width: $breakpoint-md) {
            margin-bottom: 2.4rem;
          }
        }

        @media (max-width: $breakpoint-md) {
          & > *:nth-last-child(-n+2) {
            margin-bottom: 0;
          }
        }

        .picto-info {
          &::after {
            top: 2.2rem;
            left: 0;
            @media (max-width: $breakpoint-md) {
              top: 1.6rem;
            }
          }
        }

        .infos-detail {
          display: flex;
          justify-content: space-between;
          gap: 2.4rem;
          @media (max-width: $breakpoint-sm) {
            flex-direction: column;
            gap: 0;
          }

          .col-infos-produit {
            width: 100%;

            &:first-of-type {
              @media (max-width: $breakpoint-sm) {
                margin-bottom: 1.6rem;
              }
            }

            .info-produit {
              display: flex;
              gap: .8rem;
              padding: .8rem 0;
              border-bottom: $border-clear;
              @media (max-width: $breakpoint-sm) {
                gap: 4rem;
              }

              &:not(:last-child) {
                margin-bottom: 1.6rem;
              }

              p {
                &:first-of-type {
                  font-size: $font-xs;
                  font-weight: $weight-medium;
                  width: calc(100% / 3);
                  @media (max-width: $breakpoint-xs) {
                    width: 40%;
                  }
                }

                &:last-of-type {
                  font-size: $font-xs;
                  width: calc((100% / 3) * 2);
                  @media (max-width: $breakpoint-xs) {
                    width: 60%;
                  }
                }
              }
            }
          }
        }

        .configuration_r {
          button {
            @include flex-between;
            color: $principale;
            font-size: $font-xs;
            font-weight: $weight-medium;
            border-bottom: $border-clear;
            padding: .8rem 0;
            width: 100%;
            text-align: left;

            .icon {
              padding-right: .8rem;

              &::before {
                font-size: 1.2rem;
                transform: rotate(90deg);
              }
            }
          }

          #configuration {
            .info-produit {
              display: flex;
              gap: .8rem;
              padding: .8rem 0;
              border-bottom: $border-clear;
              @media (max-width: $breakpoint-sm) {
                gap: 4rem;
              }

              &:not(:last-child) {
                margin-bottom: 1.6rem;
              }

              p {
                &:first-of-type {
                  font-size: $font-xs;
                  font-weight: $weight-medium;
                  width: calc(100% / 3);
                  @media (max-width: $breakpoint-xs) {
                    width: 40%;
                  }
                }

                &:last-of-type {
                  font-size: $font-xs;
                  width: calc((100% / 3) * 2);
                  @media (max-width: $breakpoint-xs) {
                    width: 60%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .ress-comp-produit {
    .sticky-mobile {
      & > *:not(:last-child) {
        margin-bottom: 4.8rem;
        @media (max-width: $breakpoint-md) {
          margin-bottom: 2.4rem;
        }
      }

      @media (max-width: $breakpoint-md) {
        & > *:nth-last-child(-n+2) {
          margin-bottom: 0;
        }
      }

      .picto-ressources {
        justify-content: left;
        margin-left: 0;

        &::before {
          font-size: 3.6rem !important;
          @media (max-width: $breakpoint-md) {
            font-size: 2.6rem !important;
          }
        }

        &::after {
          top: 2.4rem;
          font-size: 2rem;
          left: 0;
          @media (max-width: $breakpoint-md) {
            top: 1.8rem;
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  .collection-produit {
    .sticky-mobile {
      & > *:not(:last-child) {
        margin-bottom: 4.8rem;
        @media (max-width: $breakpoint-md) {
          margin-bottom: 2.4rem;
        }
      }

      @media (max-width: $breakpoint-md) {
        & > *:nth-last-child(-n+2) {
          margin-bottom: 0;
        }
      }

      .bloc-carrousel-produits:has(.swiper-pagination-lock) {
        margin-bottom: 0;
      }

      .picto-ressources-supp {
        &::after {
          bottom: -.6rem;
          @media (max-width: $breakpoint-md) {
            bottom: -.4rem;
          }
        }
      }

      .flex-collection-slider {
        @include flex-between;
        flex-wrap: wrap;
        gap: 2.4rem 10rem;

        .img-collection-produit {
          position: relative;
          height: 100%;
          width: 47rem;
          @media (max-width: $breakpoint-sm) {
            width: 100%;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            box-shadow: $box-shadow-dark;
            border: $border-clear;
            border-radius: $radius-s;

            &.no-photo {
              aspect-ratio: 1;
            }
          }

          .button {
            position: absolute;
            right: 1.6rem;
            bottom: 1.6rem;

            @media (max-width: $breakpoint-xs) {
              position: unset;
              right: auto;
              left: auto;
              margin-top: 1.6rem;
            }
          }
        }

        .bloc-carrousel-produits {
          width: calc((100% - 57rem) - .1rem);
          @media (max-width: $breakpoint-lg) {
            width: 100%;
          }

          .swiper-wrapper {
            justify-content: unset;
          }
        }

        .voir-tous {
          margin: 1.6rem auto 0 auto;
        }
      }
    }
  }

  .bloc-auteurs {
    .gallery {
      margin-bottom: 1.2rem;
    }

    .flex-auteurs {
      display: flex;
      gap: .4rem;
      @media (max-width: $breakpoint-sm) {
        flex-direction: column;
      }

      .bold {
        white-space: nowrap;
      }
    }
  }

  .mentions-produit {
    .mention {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      border: $border-clear;
      border-radius: $radius-s;
      box-shadow: $box-shadow-alt-black;
      max-width: 45rem;
      padding: 1.6rem;

      &:last-of-type {
        margin-right: 0 !important;
      }

      & > *:first-of-type {
        & > *:not(:last-child) {
          margin-bottom: .8rem;
        }
      }

      .flex-tag {
        @include flex-between;
      }

      .icon::before {
        font-size: 2rem;
      }

      .texte-clamp {
        @include line-clamp(3);
        height: 6.4rem;
      }
    }
  }

  .lateral-panier {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    z-index: 10002;
    background-color: white;
    width: 65rem;
    height: 100vh;
    transform: translateX(100%);
    padding-right: .4rem;
    //transform: translateX(0);
    transition: transform .5s ease-in-out;

    @media (max-width: $breakpoint-md) {
      width: 58rem;
    }

    @media (max-width: $breakpoint-sm) {
      width: 50rem;
    }

    @media (max-width: $breakpoint-xs) {
      width: 32rem;
    }

    .box {
      width: 100%;
      padding: 4.8rem;
      overflow-y: auto;
      border-top: .1rem solid $neutre_gris_300;
      transition: all .5s;

      @media (max-width: $breakpoint-lg) {
        padding: 2rem;
      }

      .ajout {
        @include flex-center;
        gap: .8rem;
        font-weight: $weight-bold;
        width: 100%;
        color: $succes;
        background-color: $succes_fond;
        padding: .8rem;
        border-radius: $radius-xs;
        margin-bottom: 3.2rem;

        &::before {
          content: '\FA2B';
          font-family: kfont, sans-serif;
        }
      }

      .card-product-lateral {
        display: flex;
        gap: 5rem;

        @media (max-width: $breakpoint-md) {
          gap: 2rem;
        }

        @media (max-width: $breakpoint-xs) {
          flex-direction: column;
        }

        .img-c {
          position: relative;
          display: flex;
          height: 100%;
          min-width: 22rem;
          padding: 2.4rem 1rem;
          background-color: $neutre_gris_50;
          border: $border-clear;
          border-radius: $radius-xs;

          .tag-element-red {
            position: absolute;
            top: 0;
            left: .8rem;
            transform: translateY(-50%);
            z-index: 10;
          }

          img {
            max-height: 28.5rem;
            width: 100%;
            object-fit: contain;
            border-radius: $radius-xs;
          }
        }

        .textes {
          & > *:not(:last-child) {
            margin-bottom: .8rem;
          }

          .titre {
            font-size: 1.8rem;
            font-weight: 700;
            margin-bottom: 1.2rem;
          }

          .categorie {
            display: flex;
            align-items: center;
            gap: .8rem;
            font-weight: 500;

            & > *:not(:first-child) {
              display: flex;
              gap: .8rem;

              &::before {
                content: '';
                display: block;
                background-color: $neutre_gris_300;
                min-width: .1rem;
              }
            }
          }

          .prix-barre {
            color: $neutre_gris_800;
            font-weight: 700;
            text-decoration: line-through;
          }

          .prix-actuel {
            font-size: 3.2rem;
            font-weight: 700;
            line-height: .9;
          }
        }
      }

      hr {
        margin: 3.2rem 0;
      }

      .buttons {
        @include flex-between;
        gap: 2.4rem;

        @media (max-width: $breakpoint-xs) {
          flex-direction: column;
          gap: 1rem;
        }

        .button{
          width: calc(50% - 1.2rem);

          @media (max-width: $breakpoint-xs) {
            width: 100%;
          }

          &:first-child{
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}

.panier-visible {
  @media (min-width: $breakpoint-lg) {
    #header {
      width: calc(100% + 1.7rem);
      padding-right: 1.7rem;
    }
  }

  .lateral-panier {
    transform: translateX(0);
  }
}

.fancybox-slide:has(.zoom-popin-c) {
  overflow: visible !important;
}

.zoom-popin-c {
  opacity: 0;

  .fancybox-slide {
    overflow: visible !important;
  }

  .contenu-produit-flex-slider-zoom {
    position: static;
    background-color: unset;
    cursor: pointer;
    height: 100vh;
    @media (max-width: $breakpoint-sm) {
      padding: 4.4rem 1rem 1rem 1rem;
    }

    .js-slide-produit-zoom {
      margin-bottom: 5.6rem;
      height: 70%;
      @media (max-width: $breakpoint-sm) {
        height: 60%
      }

      &:not(.swiper-initialized) {
        height: 90%;
        margin-bottom: 0;

        .swiper-button-next,
        .swiper-button-prev {
          display: none;
        }
      }


      .swiper-wrapper {
        .box-visuel-produit {
          aspect-ratio: 552/772;
          height: 100%;
          width: 100%;

          img {
            aspect-ratio: 552/772;
            display: block;
            height: 100%;
            background-color: $blanc;
            object-fit: contain;
            border-radius: $radius-xs;
            margin: 0 auto;
          }
        }
      }
    }

    .container-slide-thumps-produit-zoom {
      max-width: 100%;
      max-height: 30%;
      @media (max-width: $breakpoint-sm) {
        max-height: 40%
      }

      .swiper-wrapper {
        width: fit-content;

        .swiper-slide {
          opacity: .6;
          transition: opacity .3s ease-in-out;

          &.swiper-slide-thumb-active {
            opacity: 1;
          }
        }

        .box-visuel-produit {
          aspect-ratio: 240 / 310;
          max-height: 14rem;
          width: 100%;

          img {
            aspect-ratio: 240 / 310;
            display: block;
            height: 100%;
            background-color: $blanc;
            object-fit: contain;
            border-radius: $radius-xs;
            margin: 0 auto;
          }
        }
      }
    }

    .fancybox-button {
      @include flex-center;
      gap: 1rem;
      width: 2.8rem;
      height: 2.8rem;
      color: $cta;
      background-color: $blanc;
      border: $border-clear;
      box-shadow: $box-shadow-normal;
      border-radius: 50%;
      cursor: pointer;
      transition: color .3s, background-color .3s, border .3s, box-shadow .3s ease-in-out;
      line-height: 1;
      top: 1rem;
      right: 1rem;
      opacity: 1;

      &:hover,
      &:focus {
        color: $cta;
        background-color: $blanc;
        border: $border-dark;
      }

      &::after {
        content: "\FA35";
        display: inline-block;
        font-family: kfont;
        font-size: 1.2rem;
        color: $cta;
        transition: color .3s ease-in-out;
      }

      svg {
        display: none;
      }
    }
  }
}

.bloc-produit-numerique-gratuit {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  max-width: 28.5rem;
  height: 100%;
  padding: 1.6rem;
  border-radius: .4rem;
  border: .1rem solid $principale;
  background-color: #fff;

  .button {
    width: 100%;
    justify-content: space-between;
  }
}
