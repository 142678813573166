.img-pleine-largeur {
  &.builder-style-none img {
    border: none;
  }

  .img-pleine-largeur-btn {
    width: 100%;
  }

  img {
    border: $border-clear;
    width: 100%;
    max-height: 44rem;
    object-fit: contain;
    border-radius: $radius-ml;

    &.hauteur-debride {
      max-height: unset;
    }
  }

  p {
    margin-top: .8rem;
    font-style: italic;
  }
}