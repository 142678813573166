.bloc-liste-produits-push {
  .liste-produits {
    display: flex;
    gap: 2.4rem;
    flex-wrap: wrap;
    @media (max-width: $breakpoint-xl) {
      gap: 2.4rem 1.2rem;
    }

    .card-product {
      width: calc(100% / 5 - 1.92rem);
      height: auto;
      @media (max-width: $breakpoint-xl) {
        width: calc(100% / 4 - .9rem);
      }
      @media (max-width: $breakpoint-md) {
        width: calc(100% / 3 - .8rem);
      }
      @media (max-width: $breakpoint-sm) {
        width: calc(100% / 2 - .6rem);
      }
    }

    .card-article {
      width: calc(100% / 2.5 - 1.44rem);
      height: auto;
      max-height: 52.1rem;
      @media (max-width: $breakpoint-xl) {
        width: calc(100% / 2 - .6rem);
      }
      @media (max-width: $breakpoint-md) {
        width: calc(100% / 1.5 - .4rem);
      }
      @media (max-width: $breakpoint-sm) {
        width: 100%;
      }
    }

    &:has(.tag-promo) {
      margin-top: 3rem;
    }
  }
}