.page-espace-client-register,
.form-modif-infos {
  #ajax-loader {
    display: none;
  }

  #contenu {
    padding-top: 4rem;
    @media (max-width: $breakpoint-sm) {
      padding-top: 6rem;
    }

    .required-mention {
      display: block;
      margin-top: .8rem;
    }
  }

  #div_personal_information,
  #div_login,
  #div_password,
  #div_institution_ens,
  #div_profil_ens_subform,
  .per-connect-widget-text,
  #per-connect-layout-group-subscription {
    border: .1rem solid $neutre_gris_300;
    background-color: $neutre_gris_50;
    border-radius: .4rem;
    padding: 2.4rem;
    margin-top: 2.4rem;

    h2 {
      font-size: 1.8rem;
      margin-top: 0;
      margin-bottom: 1rem;
      margin-left: 6.4rem;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -6.5rem;
        transform: translateY(-50%);
        background-image: url('../img/theme/graphiti/graphiti_small_neutre.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 5.7rem;
        height: 2.5rem;
      }

      + p {
        margin-bottom: 2.4rem;
        font-size: 1.4rem;
      }
    }

    .field {
      margin-bottom: 1.5rem;
      @media (max-width: $breakpoint-xs) {
        padding-left: 0;
      }
    }

    .field li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      label {
        font-size: 1.4rem;
        font-weight: 600;
      }
    }

    @media (max-width: $breakpoint-xs) {
      .field li {
        flex-direction: column;

        label {
          margin-bottom: .4rem;
        }
      }
    }

    input,
    select {
      max-width: 36rem;
      margin-left: 1rem;
      padding-top: .7rem;
      @media (max-width: $breakpoint-sm) {
        max-width: 28rem;
      }
      @media (max-width: $breakpoint-xs) {
        max-width: none;
        margin-left: 0;
      }
    }
  }

  #pwd-pattern-description {
    font-size: 1.2rem;
    margin-bottom: 1rem;

    + p a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  #div_profil_ens_subform {
    border-bottom: none;
  }

  .per-connect-widget-text {
    border-top: none;
    margin-top: -1rem;
    font-size: 1.2rem;
  }

  li:has(#password) {
    position: relative;
  }

  #pwd-label,
  #password {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  #new_password_show,
  #defined_password_show,
  #password_show {
    position: absolute;
    top: 2rem;
    right: 1rem;
    z-index: 1;
    font-size: 3rem;

    @media (max-width: $breakpoint-xs) {
      top: auto;
      right: 1rem;
      bottom: 1.8rem;
    }

    &::after {
      content: "\EA34"; // A Modifier
      font-family: kfont;
    }
  }

  input[type="checkbox"] {
    position: relative;
    appearance: none;
    min-width: 1.8rem;
    height: 1.8rem;
    background-color: #fff;
    border: .1rem solid #52575f;
    border-radius: .4rem;
    outline: 0;
    padding: 0;
    cursor: pointer;

    &:checked::before {
      content: "\FA2B";
      font-family: kfont;
      font-size: .8rem;
      border-radius: .2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  #input_dept {
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1rem;

    label {
      width: calc(100% - 36.4rem);
      @media (max-width: $breakpoint-sm) {
        width: calc(100% - 28.4rem);
      }
      @media (max-width: $breakpoint-xs) {
        width: 100%;
        text-align: center;
      }
    }

    #institution_dept {
      display: inline-block;
      max-width: 35.9rem;
      @media (max-width: $breakpoint-sm) {
        max-width: 27.9rem;
      }
      @media (max-width: $breakpoint-xs) {
        max-width: none;
      }
    }

    .recherche-fake {
      position: absolute;
      right: .8rem;
      top: 1.4rem;
      z-index: 2;
      font-size: 2.5rem;
      @media (max-width: $breakpoint-xs) {
        top: auto;
        bottom: 1.2rem;
      }
    }
  }

  #institution_keyword,
  #institution_dept {
    margin-top: .4rem;
    margin-left: auto !important;
  }

  .recherche-ville-fake {
    margin-left: auto;
    margin-bottom: 1rem;
  }

  .recherche-adresse-fake {
    margin: 1.6rem auto;
  }

  #div_options_list_form {
    display: flex;
    align-items: center;
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;

    input[type="checkbox"] {
      margin-bottom: .8rem;
    }
  }

  .submit-fake {
    width: 100%;
    margin-bottom: 12rem;
  }

  .text-footer {
    font-size: 1.2rem;
    margin-top: 12rem;
    margin-bottom: 12rem;

    a {
      text-decoration: underline;
    }
  }

  #form_account_errors_display table {
    display: block;
    font-size: 1.2rem;
    color: #721c24;
    border: .1rem solid #f5c6cb;
    background-color: #f8d7da;
    border-radius: .4rem;
    padding: 1rem 1.4rem;
    margin-bottom: 2.4rem;
  }

  .field_choice_institution {
    table {
      width: 100%;
    }

    tbody {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      tr {
        border: .1rem solid $neutre_gris_300;
        width: calc(50% - 1.1rem);
        border-radius: 1rem;
        transition: border-color .3s;

        .input_radio {
          display: none;
        }

        &.active {
          background-color: $neutre_gris_300;

          &:hover {
            border-color: $neutre_gris_800;
          }
        }

        &:hover {
          border-color: $neutre_gris_800;
        }

        .final_institution {
          display: flex;
          width: 100%;
          height: 100%;

          label {
            width: 100%;
            height: 100%;
            cursor: pointer;
            padding: 0 .5rem .8rem;
          }
        }
      }
    }
  }

  .institution_name {
    font-weight: 700;

    &:first-child {
      display: block;
      margin-top: 1rem;
    }

    &:last-child {
      display: block;
    }
  }

  .password_edit_link,
  .institution_edit_link {
    text-align: right;

    a {
      font-weight: 700;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

/* Panneau latéral de connexion */
.client-connecte.register-visible #header {
  z-index: 9;
}

.lateral-register {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  z-index: 10002;
  width: calc(100% - 30rem);
  height: 100vh;
  transform: translateX(100%);
  transition: transform .5s ease-in-out;

  @media (max-width: $breakpoint-xl) {
    width: calc(100% - 15rem);
    .ec-card {
      padding: 2rem;
    }
  }

  @media (max-width: $breakpoint-lg) {
    width: calc(100% - 8rem);
    .ec-card .liste-pictos {
      flex-wrap: wrap;
      justify-content: center;
      gap: 3rem 0;

      .item-picto {
        width: calc(100% / 3 - 1rem);
      }
    }
  }

  @media (max-width: $breakpoint-md) {
    display: none;
  }

  .left-col {
    width: 65%;
    padding: 4.8rem 3rem 16rem;
    overflow-y: auto;
    background: #fff;
    transform: translateX(100%);
    opacity: 0;
    @include scrollbar();
    border-top: .1rem solid $neutre_gris_300;
    transition: all 1s;
    @media (max-width: 1499px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .right-col {
    position: relative;
    width: 35%;
    padding-left: 3rem;
    padding-right: 3rem;
    background-color: $neutre_beige_300;
    border-top: .1rem solid $neutre_gris_300;
    z-index: 2;
    @media (max-width: 1499px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    @media (max-width: $breakpoint-lg) {
      width: 35%;
    }

    .h2 {
      margin-top: 4.8rem;
    }
  }
}

.client-connecte .lateral-register {
  top: 0;
  width: 39rem;

  .right-col {
    width: 100%;
    background-color: #fff;
    padding: 6rem .4rem 1.6rem 1.5rem;
  }

  .col-gauche-menu-ec {
    max-width: none;
    overflow-y: auto;
    height: 100%;
    padding-top: 6rem;

    .card-menu-c {
      position: static;
      padding-right: .4rem;

      .card-menu .informations-menu {
        background-color: #fff;
        border: none;
      }
    }
  }
}

body.register-visible {
  #header {
    width: calc(100% + 1.7rem);
    padding-right: 1.7rem;
    @media (max-width: $breakpoint-lg) {
      width: 100%;
      padding-right: 0;
    }
  }

  .lateral-register {
    transform: translateX(0);
  }
}

#div_institution_ens .fieldset {
  position: relative;

  #institution_ens_first_step_submit {
    position: absolute;
    right: 1.8rem;
    bottom: 1.1rem;
    width: 2.5rem;
    background-color: transparent;
    border: none;
    background-image: url('../img/theme/fake-btn-register/recherche.svg');
    background-repeat: no-repeat;
    object-fit: contain;
  }

  #institution_ens_second_step_submit {
    position: absolute;
    right: -0.2rem;
    bottom: -1.6rem;
    width: 13rem;
    height: 5rem;
    padding: 0;
    background-color: transparent;
    border: none;
    background-image: url('../img/theme/fake-btn-register/valider.png');
    background-repeat: no-repeat;
    object-fit: contain;
  }

  #institution_ens_third_step_submit {
    position: absolute;
    right: -0.2rem;
    bottom: -3.6rem;
    width: 13rem;
    height: 5rem;
    padding: 0;
    background-color: transparent;
    border: none;
    background-image: url('../img/theme/fake-btn-register/valider.png');
    background-repeat: no-repeat;
    object-fit: contain;
  }

  .link_like_button {
    text-decoration: underline;
  }

  .field {

    li:has(#address2) {
      position: relative;
    }

    #address2 {
      margin-bottom: 1rem;
    }

    .infos_address2 {
      position: absolute;
      top: 5.8rem;
      right: 0;
      font-size: 1.2rem;
    }

  }

}

.field_choice_institution {
  max-height: 50rem;
  overflow: auto;

  table {
    height: 100%;
  }
}

#global_registration_ens_form {
  position: relative;

  #global_registration_ens_submit {
    position: absolute;
    left: 0;
    bottom: -7.6rem;
    width: 65rem;
    height: 4.8rem;
    padding: 0;
    background-color: transparent;
    border: none;
    background-image: url('../img/theme/fake-btn-register/inscrire.png');
    background-repeat: no-repeat;
    object-fit: contain;
  }
}

@keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Page inscription réussie */
.espace-client.inscription-reussie {
  .cta-container {
    @media (max-width: $breakpoint-xs) {
      flex-direction: column;
      gap: 1rem;
    }
  }
}

.bloc-success {
  @include flex-center;
  flex-direction: column;
  padding: 3rem 1.6rem;
  border-radius: .4rem;
  color: $succes;
  background: $succes_fond;

  h1, h2, h3, h4, h5, h6 {
    color: $succes;
    margin-bottom: 2rem;
  }

  &::before {
    content: "\FA2B";
    font-family: kfont;
    display: block;
    font-size: 4rem;
    margin-bottom: 3rem;
  }

  p {
    margin-bottom: 1.5rem;
  }
}

.bloc-error {
  @include flex-center;
  flex-direction: column;
  padding: 3rem 1.6rem;
  border-radius: .4rem;
  color: $danger;
  background: $danger_fond;

  h1, h2, h3, h4, h5, h6 {
    color: $danger;
    margin-bottom: 2rem;
  }

  &::before {
    content: "\F373";
    font-family: kfont;
    display: block;
    font-size: 4rem;
    margin-bottom: 3rem;
  }

  p {
    margin-bottom: 1.5rem;
  }
}