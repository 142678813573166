.page-references{
  .h1{
    margin-top: 3rem;
    margin-bottom: 2.5rem;
  }
}

.ref-filtre ul {
  @media (max-width: $breakpoint-xs) {
    padding-left: 0;
  }
  li{
    display: inline-block;
    margin-right: .5rem;
    margin-bottom: .8rem;
    @media (max-width: $breakpoint-xs) {
      margin-left: .2rem;
      margin-bottom: .5rem;
      a {
        padding: .8rem;
      }
    }
    .reference-filtre{
      display: inline-block;
      background-color: $principale;
      color: #ffffff;
      padding: .8rem 1.5rem;
      border-radius: .2rem;
      font-size: 1.4rem;
      line-height: 1rem;
      &:hover{
        background-color: $cta;
      }
    }
  }
}

.block-references-liste {
  margin: 0;
  padding: 0;
  list-style: none;
  background : #fff;
  .real-item {
    position : relative;
    width : calc(100% / 4);
    height : 0;
    margin: 0;
    padding: calc(100% / 4) 0 0;
    overflow : hidden;
    @media (max-width: $breakpoint-lg) {
      width: calc(100% / 3);
      padding-top: calc(100% / 5);
    }
    @media (max-width: $breakpoint-sm) {
      width: calc(100% / 2);
      padding-top: calc(100% / 4);
    }
    @media (max-width: $breakpoint-xs) {
      width: calc(100% / 1);
      padding-top: calc(100% / 2);
    }

  }
  li{
    img{
      display : block;
      width : 100%;
      aspect-ratio: 1;
      object-fit: cover;
    }
    .overlay {
      display : block;
      position : absolute;
      top : 0;
      left : 0;
      width : 100%;
      height : 100%;
      padding : 3rem;
      transition : background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
      background-color : rgba(241, 120, 75, 0);
      z-index : 10;
      .title{
        font-size : 2rem;
        color : #fff;
        font-weight : 700;
        text-align : center;
        margin-bottom: auto;
      }
      .description {
        color : #fff;
        font-size : 1.5rem;
        @media (max-width: $breakpoint-xs) {
          display: none;
        }
      }
    }
    &:hover .overlay{
      opacity : 1;
      visibility : visible;
    }
  }
}


