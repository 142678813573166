// Bouton principal
#cms .content .button,
.button {
  position: relative;
  @include flex-center;
  gap: 1rem;
  width: fit-content;
  color: $blanc;
  background-color: $cta;
  border: $border-invisible;
  outline: 2px solid transparent;
  border-radius: $radius-xxl;
  font-size: $font-s;
  font-weight: $weight-normal;
  height: 4.8rem;
  padding: 1.45rem 2.4rem;
  cursor: pointer;
  transition: color .3s, background-color .3s, border .3s, box-shadow .3s, width .1s ease-in-out;
  line-height: 1;

  @media (max-width: $breakpoint-xs) {
    width: 100%;
  }

  &:hover,
  &:focus {
    color: $cta;
    background-color: $blanc;
    border: $border-dark;

    &::before,
    &::after {
      color: $cta;
    }
  }

  &::before {
    font-family: kfont, sans-serif;
  }

  &::after {
    content: "\FA37";
    font-family: kfont;
    color: $blanc;
    transition: color .3s ease-in-out;
  }

  &.button-no-icon {
    color: $blanc;
    background-color: $cta;
    border: $border-main;

    &::after {
      display: none;
    }

    &:hover,
    &:focus {
      color: $cta;
      background-color: $blanc;
    }
  }

  &.button-no-icon-alt {
    color: $cta;
    background-color: $blanc;
    border: $border-main;

    &::after {
      display: none;
    }

    &:hover,
    &:focus {
      color: $blanc;
      background-color: $cta;
      border: $border-invisible;
    }
  }

  // Bouton secondaire
  &.button-alt {
    color: $cta;
    background-color: $blanc;
    border: $border-dark;

    &::after {
      color: $cta;
      transition: color .3s;
    }

    &:hover,
    &:focus {
      color: $blanc;
      background-color: $cta;
      border: $border-invisible;

      &::after {
        color: $blanc;
      }
    }
  }

  &.button-danger {
    color: $danger;
    background-color: $danger-fond;
    border: .1rem solid $danger;

    &::after {
      color: $danger;
    }

    &:hover,
    &:focus {
      color: $blanc;
      background-color: $danger;
      border: $border-invisible;

      &::after {
        color: $blanc;
      }
    }
  }

  // Bouton transparent
  &.button-transparent {
    color: $cta;
    background-color: transparent;
    border: $border-dark;

    &::after {
      color: $cta;
    }

    &:hover,
    &:focus {
      color: $neutre_bleu_100;
      background-color: $cta;
      border: $border-invisible;

      &::after {
        color: $neutre_bleu_100;
      }
    }
  }

  //Bouton désactivé
  &.button-disabled {
    color: #999;
    background-color: $neutre_gris_300;
    border-color: $neutre_gris_300;
    cursor: default;
    pointer-events: none;
    &::after {
      color: #999;
    }
  }

  //Bouton centré
  &.button-centre {
    margin-left: auto;
    margin-right: auto;
  }

  //Bouton droite
  &.button-droite {
    margin-left: auto;
  }

  // button avec un icone avant le libellé
  &.picto-before-gsn-recherche::before {
    content: "\F2C2";
    font-family: kfont;
    font-size: 2rem;
    color: $blanc;
    transition: color .3s ease-in-out;
  }


  // button avec un autre icone que la flèche après le libellé
  &.button-icon-externe {
    &::after {
      content: "\FA32";
    }
  }

  // Icone lien externe
  &.button-icon-telecharger {
    &::after {
      content: "\FC43";
    }
  }

  &.button-icon-info {
    &::after {
      content: "\FA3B";
    }
  }

  &.button-icon-caddie {
    &::after {
      content: "\FC02";
      font-size: 2rem;
    }
  }

  &.button-icon-smartphone {
    &::after {
      content: "\FCF6";
      font-size: 2.2rem;
    }
  }

  &.button-icon-plus::after {
    content: "\FC37";
  }

  &.button-icon-deco::after {
    content: "\FA30";
    font-size: 2rem;
  }

  &.button-icon-fleche::after {
    content: "\FA37";
    font-size: 2rem;
  }

  &.button-icon-cross::after {
    content: "\F373";
    font-size: 1.4rem;
  }

  &.button-icon-check::after {
    content: "\FA2B";
    font-size: 1.4rem;
  }

  &.button-icon-cadenas::after {
    content: "\FA2A";
    font-size: 2rem;
  }

  &.button-icon-chevron-bas::after {
    content: "\FA2C";
    font-size: 1.4rem;
    transform: rotate(90deg);
    margin-left: 1rem;
    margin-top: .2rem;
  }

  &.button-icon-caret-droite::after {
    content: "\EA08";
    font-size: 1.4rem;
  }

  &.button-icon-caret-bottom::after {
    content: "\EA12";
    font-size: 1.4rem;
    margin-top: .2rem;
  }

  &.button-icon-cadenas-plus {
    &::before {
      content: "\FC37";
      position: absolute;
      top: .8rem;
      right: 1.2rem;
      font-size: 1.2rem;
    }

    &::after {
      content: "\FA2A";
      font-size: 2rem;
    }
  }

  &.button-icon-marque-page {
    position: relative;
    font-weight: $weight-bold;
    background-color: $neutre_gris_100;
    color: $principale;
    border: $border-clear;
    box-shadow: $box-shadow-normal;
    width: 28rem;
    height: unset;
    padding: 1.6rem 3.2rem;
    text-wrap: nowrap;
    line-height: normal;

    &:hover {
      border: $border-dark;
      box-shadow: none;
    }

    &::after {
      display: none;
    }

    .texte-marque-actif {
      display: none;
    }

    &.active {
      background-color: $cta;
      border: $border-invisible;
      color: $blanc;
      width: 18.1rem;

      &:hover {
        box-shadow: $box-shadow-black;
      }

      &::before, &::after {
        color: $blanc;
      }

      .texte-marque-actif {
        display: inline-block;
      }

      .texte-marque {
        display: none;
      }

    }

    .hub-surligne-mp {
      position: relative;

      &::before {
        position: relative;
        font-size: 2rem;
        z-index: 1;
      }

      &::after {
        position: absolute;
        left: -.3rem;
        bottom: .2rem;
        content: "\FC42";
        font-family: kfont;
        font-size: 1rem;
        color: $grand_public;
        z-index: 0;
      }
    }

    .tooltip-icon {
      &::before {
        font-size: 2rem;
      }
    }
  }

  //Bouton recherche site
  &.button-recherche-site {
    &::before {
      content: "\F2C2";
      font-family: kfont;
      font-size: 2rem;
    }
  }

  &.button-centre {
    text-align: center;
  }

  p {
    margin-bottom: 0;
  }
}

button {
  color: $principale;
}

.button-round {
  @include flex-center;
  gap: 1rem;
  width: 4.8rem;
  height: 4.8rem;
  color: $cta;
  background-color: $blanc;
  border: $border-clear;
  box-shadow: $box-shadow-normal;
  border-radius: 50%;
  cursor: pointer;
  transition: color .3s, background-color .3s, border .3s, box-shadow .3s ease-in-out;
  line-height: 1;

  &:hover,
  &:focus {
    color: $cta;
    background-color: $blanc;
    border: $border-dark;
  }

  &::after {
    display: inline-block;
    font-family: kfont;
    font-size: 2rem;
    color: $cta;
    transition: color .3s ease-in-out;
  }

  &.button-round-left-icon-fleche {
    &::after {
      content: "\FA37";
      transform: rotate(-0.50turn);
    }
  }

  &.button-round-right-icon-fleche {
    &::after {
      content: "\FA37";
    }
  }

  &.button-round-icon-play {
    &::after {
      content: "\FC36";
      transform: translateX(.3rem);
    }
  }

  &.button-round-icon-retourner {
    &::after {
      content: "\FC3E";
    }
  }

  &.button-round-icon-fav {
    &::after {
      content: "\FA34";
    }
  }

  &.button-round-icon-partage {
    &::after {
      content: "\FC34";
    }
  }
}

.button-see-more {
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: $principale;
  padding: 0;

  &:hover {
    span {
      text-decoration: underline;
    }
  }

  &::after {
    content: "\FA2C";
    font-family: kfont;
    font-size: 1rem;
    transform: rotate(90deg);
  }

  button {
    font-size: $font-xs;
    font-weight: $weight-normal;
  }
}