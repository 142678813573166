/* /!\ Completer les couleurs dans "Visualiser" et "Variables" */
/* Couleur pour visualiser rapidement */

$blanc: #fff;
$noir: #000;
$rouge: #B01111;
$orange: #C25603;
$vert: #139B39;

$principale: #283244;
$cta: #2B3B58;

$maison_nathan: #79B0EA;
$rouge_nathan: #FF0000;

$enseignant: #9BC3A0;
$grand_public: #F7A08B;

$succes: #00543F;
$danger: #9B1C1C;
$succes_fond: #F3FAF7;
$danger_fond: #FDF2F2;

$collection: #C49C6E;
$collection_fond: #F6F0E9;

$prescripteur: #A75E08;
$prescripteur_fond: #fff;

$ressource: #249198;
$ressource_fond: #F1FEFF;

$compagnon: #2F4490;
$compagnon_fond: #ECEFFB;

$neutre_gris_50: #FBFBFB;
$neutre_gris_100: #F4F4F5;
$neutre_gris_300: #E0E4E8;
$neutre_gris_600: #9BA6B4;
$neutre_gris_800: #52575F;

$neutre_bleu_100: #F2F6F7;
$neutre_bleu_300: #C6D5E5;

$neutre_beige_300: #F6F0E9;
$neutre_beige_600: #EADAC7;

/* Variables couleurs */
$colors: (
        "blanc": #fff,
        "noir": #000,
        "rouge": #B01111,
        "orange": #C25603,
        "vert": #139B39,

        "principale": #283244,
        "cta": #2B3B58,

        "maison_nathan": #79B0EA,
        "rouge_nathan": #FF0000,

        "enseignant": #9BC3A0,
        "grand_public": #F7A08B,

        "succes": #00543F,
        "danger": #9B1C1C,
        "succes_fond": #F3FAF7,
        "danger_fond": #FDF2F2,

        "collection": #C49C6E,
        "collection_fond": #F6F0E9,

        "ressource": #249198,
        "ressource_fond": #F1FEFF,

        "compagnon": #2F4490,
        "compagnon_fond": #ECEFFB,

        "neutre_gris_50": #FBFBFB,
        "neutre_gris_100": #F4F4F5,
        "neutre_gris_300": #E0E4E8,
        "neutre_gris_600": #9BA6B4,
        "neutre_gris_800": #52575F,

        "neutre_bleu_100": #F2F6F7,
        "neutre_bleu_300": #C6D5E5,

        "neutre_beige_300": #F6F0E9,
        "neutre_beige_600": #EADAC7,
);

/* desactive pour le backo */
/* body:not([class*="breakpoint"]) {} */
body,
#cms .content {

  /* Genere automatiquement les styles generiques du projet en fonction des couleurs */
  @each $number-color, $color in $colors {

    /* color */
    .color#{$number-color},
    .color#{$number-color} a {
      color: $color;
    }

    /* background-color */
    .bg#{$number-color} {
      background-color: $color;
    }

    /* border color */
    .border#{$number-color} {
      border: .1rem solid $color;
    }

    /* separator color */
    .sep#{$number-color} {
      margin: 1.5rem 0;
      height: .1rem;
      background-color: $color;
    }

    /* button generique */
    /* Si blanc */
    @if ($color == $blanc or $color == #999 or $color == #999) {
      .button.button#{$number-color} {
        background-color: $color;
        color: #999;
        border: .1rem solid $color;

        &:hover, &:focus {
          background-color: #333;
          color: $color;
          border: .1rem solid $color;
        }
      }
    } @else {
      .button.button#{$number-color} {
        background-color: $color;
        color: $blanc;
        border: .1rem solid $color;

        &:hover, &:focus {
          background-color: $blanc;
          color: $color;
          border: .1rem solid $color;
        }
      }
    }
  }
}


