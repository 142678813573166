#map {

  .leaflet-popup,
  .leaflet-popup .leaflet-popup-content-wrapper,
  .leaflet-popup .leaflet-popup-content {
    /* Un seul site */
    width: 20rem !important;
    height: 12rem;
    margin-bottom: 0;

    a {
      color: $principale;
      &:hover,
      &:focus {
        color: $cta;
      }
    }

    .m-item-telephone,
    .m-item-horaire {
      margin-top: 1rem;
    }
  }

  &.extend-item {
    .leaflet-popup,
    .leaflet-popup .leaflet-popup-content-wrapper,
    .leaflet-popup .leaflet-popup-content {
      /* Agences */
      width: 30rem !important;
      height: 22rem;
    }
  }

  .leaflet-popup-content {
    padding: 1rem;
  }
}