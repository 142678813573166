.bloc-auteurs {
  max-width: 105rem;
  margin-left: auto;
  margin-right: auto;

  .h2.souligne {
    margin-bottom: 1.2rem;

    &::after {
      color: $grand_public;
    }
  }

  .list-3-cards {
    margin-bottom: 2.4rem;
  }

  .card {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: $neutre_gris_50;
    border-color: $neutre_gris_300;
    margin-top: 10rem;
    padding-top: 3.8rem;
    text-align: center;
    @media (max-width: $breakpoint-md) {
      width: calc(50% - 1.2rem) !important;
    }
    @media (max-width: $breakpoint-sm) {
      width: 100% !important;
    }

    &:not(.no-photo)::before {
      content: '';
      position: absolute;
      top: -10rem;
      left: 50%;
      display: inline-block;
      height: 16rem;
      background-image: url('../img/theme/graphiti/graphiti_serre_neutre.svg');
      background-repeat: no-repeat;
      background-size: contain;
      aspect-ratio: 228/178;
      z-index: -1;
      margin-left: -2rem;
      transform: translateX(-50%);
    }

    &.no-photo {
      &::before {
        content: "\FC42";
        font-family: kfont;
        position: absolute;
        top: -3.5rem;
        left: 50%;
        display: inline-block;
        font-size: 5rem;
        color: $neutre_bleu_300;
        transform: translateX(-50%);
      }

      .auteur-card-img {
        top: -8.5rem;

        img {
          width: 10rem;
          height: 10rem;
        }
      }
    }

    .auteur-card-img {
      position: absolute;
      top: -9rem;
      left: 50%;
      transform: translateX(-50%);
      width: 30rem;
      text-align: center;

      img {
        width: 12rem;
        height: 12rem;
        margin-left: auto;
        margin-right: auto;
        object-fit: cover;
      }
    }

    .bg-image-auteur {
      position: absolute;
      top: -2rem;
      left: 0;
    }

    .card-header {
      margin-bottom: .8rem;
    }

    .h6 {
      margin-bottom: .4rem;
    }

    .card-text {
      @include line-clamp(4);
      font-size: $font-xs;

      p {
        font-size: 1.4rem;
      }
    }

    .card-cta {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
      margin-left: auto;
    }
  }

  .auteurs-sans-bio {
    display: flex;
    flex-wrap: wrap;

    p {
      &:not(:last-of-type) {
        &::after {
          content: ',';
          margin-left: -.4rem;
          margin-right: .4rem;
        }
      }
    }
  }

  .swiper-backface-hidden .swiper-slide {
    transform: none;
  }
}