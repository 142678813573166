//@font-face {
//  font-family: 'Poppins';
//  src: local('Poppins Light'), local('Poppins-Light'),
//  url('../fonts/subset-Poppins-Light.woff2') format('woff2'),
//  url('../fonts/subset-Poppins-Light.woff') format('woff');
//  font-weight: 300;
//  font-style: normal;
//  font-display: swap;
//}
/* desactive pour le backo */
/*
html body:not([class*="breakpoint"]) {

  .roboto {
    font-family: 'Roboto', sans-serif;
  }
  .pressstyle {
    font-family: 'Press Style', fantasy, sans-serif;
  }

}
*/

@font-face {
  font-family: 'Inter';
  src: url('../fonts/InterVariable.woff2') format('woff2');
  font-weight: 100 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/InterVariableItalic.woff2') format('woff2');
  font-weight: 100 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'LaMaisonNathan';
  src: url('../fonts/LaMaisonNathan-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}