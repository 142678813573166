.multi-blocs {
  border-radius: 2.4rem;
  border: .1rem solid $neutre_gris_300;
  background-color: white;
  padding: 4rem;
  @media (max-width: $breakpoint-sm) {
    padding: 4rem 2rem;
  }

  .contenu-multi-blocs {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2.h3 {
      margin-bottom: 1.6rem;
      @media (max-width: $breakpoint-sm) {
        align-items: center !important;
      }
    }

    p {
      margin-bottom: 4rem;
      @media (max-width: $breakpoint-lg) {
        margin-bottom: 2rem;
      }
    }
  }

  .desc-bottom {
    margin-top: 4rem;

    &.no-slider {
      @media (max-width: $breakpoint-lg) {
        margin-top: 2rem;
      }
    }
  }

  .list-cards-multi-blocs {
    .box-card-multi-bloc {
      width: calc(25% - 1.8rem);
      height: 100%;
      @media (max-width: $breakpoint-xl) {
        width: calc(100% / 3 - 1.6rem);
      }
      @media (max-width: $breakpoint-md) {
        width: calc(50% - .8rem);
      }
      @media (max-width: $breakpoint-sm) {
        width: calc(50% - .4rem);
      }
      @media (max-width: $breakpoint-xs) {
        width: calc(100%);
      }

      & > p {
        margin-top: 1.6rem;
      }

      .card-multi-bloc {
        display: flex;
        align-items: center;
        flex-direction: column;
        border-radius: .8rem;
        border: .1rem solid $neutre_gris_300;
        background: #fff;
        box-shadow: 0 0 2rem 0 rgba(0, 0, 0, .07);
        padding: 3rem 1.6rem;
        row-gap: 1.6rem;
        @media (max-width: $breakpoint-lg) {
          padding: 2rem .8rem;
        }

        .h6 {
          text-align: center;
        }

        .img-c {
          display: flex;
          height: 7rem;

          img {
            max-height: 100%;
            object-fit: contain;
          }
        }

        .cta-c {
          display: flex;
          align-items: center;
          height: 4.8rem;
        }
      }
    }

    .flex-card {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 2.4rem;
      @media (max-width: $breakpoint-md) {
        gap: 1.6rem;
      }
      @media (max-width: $breakpoint-sm) {
        gap: 2.4rem .8rem;
      }
    }
  }
}


