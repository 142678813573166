body .bloc-ressources {
  .h3 {
    margin-bottom: 2.4rem;
  }
  .ressources-liste {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2.4rem;
    gap: 2.4rem;
  }
}