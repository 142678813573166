.bloc-univers {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 4rem 2.4rem;
  @media (max-width: $breakpoint-xs) {
    justify-content: center;
  }

  .carte-univers {
    display: flex;
    flex-direction: column;
    position: relative;
    width: calc(25% - 1.8rem);
    border: $border-clear;
    border-radius: $radius-s;
    max-width: 34rem;

    &:hover .link-element::after {
      transform: translateX(.3rem);
    }

    @media (max-width: $breakpoint-lg) {
      width: calc(100% / 3 - 1.6rem);
    }
    @media (max-width: $breakpoint-sm) {
      width: calc(50% - 1.2rem);
    }
    @media (max-width: $breakpoint-xs) {
      width: 100%;
    }

    .img-carte-univers {
      border-bottom: $border-clear;
      border-radius: .6rem .6rem 0 0;
      height: 17.7rem;
      width: 100%;
      object-fit: cover;
    }

    .textes-carte-univers {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
      padding: 1.6rem 1.2rem 4rem 1.2rem;

      h2 {
        @include line-clamp(2);
      }

      p {
        @include line-clamp(4);
      }

      .link-element {
        position: absolute;
        bottom: 1rem;
        right: 2rem;
      }
    }
  }
}