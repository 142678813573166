.newsletter {
  position: relative;
  background-color: $neutre_beige_300;
  border-top: $border-clear;
  border-left: $border-clear;
  border-right: $border-clear;
  border-radius: 4rem 4rem 0 0;
  margin-bottom: -4rem;

  .container {
    @include flex-center;
    flex-direction: column;
    gap: 2rem;
    padding-top: 3.5rem;
    padding-bottom: 7.5rem;
    max-width: 96rem;
    @media (max-width: $breakpoint-md) {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }

    .newsletter-title {
      display: flex;
      gap: 1.6rem;
      text-align: center;
      @media (max-width: $breakpoint-sm) {
        flex-direction: column;
        gap: .8rem;
      }

      &::before {
        content: "\FA29";
        font-family: kfont;
        font-size: 4rem;
        color: $principale;
      }
    }

    .newsletter-form {
      @include flex-between;
      flex-direction: column;
      gap: 2rem;

      .erreurs {
        font-size: $font-xs;
        color: $rouge;
        margin-right: auto;

        & > *:not(:last-child) {
          margin-bottom: .8rem;
        }
      }

      .newsletter-inputs {
        @include flex-between;
        flex-wrap: wrap;
        gap: .8rem;
        width: 100%;
        @media (max-width: $breakpoint-md) {
          flex-wrap: wrap;
          justify-content: center;
        }

        .obligatoire {
          width: 100%;
          font-size: $font-xxs;
        }

        .custom-select {
          max-width: 27rem;
          width: 100%;
          @media (max-width: $breakpoint-sm) {
            max-width: unset;
          }
        }

        .input-element[type="text"] {
          max-width: 37rem;
          width: 100%;
          @media (max-width: $breakpoint-sm) {
            max-width: unset;
          }
        }

        .button {
          @media (max-width: $breakpoint-xs) {
            width: 100%;
          }
        }
      }

      .newsletter-checkbox {
        display: flex;
        gap: .8rem;

        label {
          font-size: $font-xxs;
          cursor: pointer;
        }
      }
    }

    .bloc-success {
      display: none;
      padding: 1rem 1.6rem;
      border: .1rem solid $succes;
      border-radius: .8rem;

      &::before {
        display: inline-block;
        font-size: 2rem;
        margin-right: 1rem;
        margin-bottom: 0;
        vertical-align: sub;
      }
    }
  }

  .newsletter-links {
    @include flex-center;
    gap: 2.2rem;
    @media (max-width: $breakpoint-sm) {
      flex-direction: column;
      align-items: flex-start;
      gap: .8rem;
      width: 100%;
    }

    p {
      font-size: $font-xxs;
    }

    .link-element {
      font-weight: $weight-normal;
      font-size: $font-xxs;
      text-decoration: underline $principale;
    }
  }
}

#popin-newsletter {
  max-width: 80rem;
  margin: 0 1.6rem;
  a {
    text-decoration: underline $principale;
  }
}