.header-faq {
  position: relative;

  img {
    width: 100%;
    max-height: 30rem;
    min-height: 15rem;
    display: block;
    object-fit: cover;
    aspect-ratio: 1900/300;
  }

  .h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.faq_content {
  position: relative;
  min-height: 20rem;
  margin-top: 5rem;
  padding-bottom: 10rem;

  .collapsing {
    transition: height .2s ease-in-out;
    @media (max-width: $breakpoint-md) {
      transition: none;
    }
  }

  .container {
    @include flex-center;

    .pattern {
      @include flex-center;
      min-width: 8rem;
      min-height: 36rem;
      background: url("#{$base-img-url}/faq.svg") no-repeat center center;
      @media (max-width: $breakpoint-md) {
        display: none;
      }
    }

    .text_content {
      @include flex-center;
      flex-direction: column;
      flex: 1;

      ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 0 0 6rem;
        padding: 0;
        list-style: none;
        width: 100%;

        @media (max-width: $breakpoint-md) {
          margin: 0;
        }

        li {
          margin-bottom: 1.5rem;
          border-radius: 1.6rem;
          padding: 3rem;
          background: #eee;

          @media (max-width: $breakpoint-md) {
            padding: 1.5rem;
          }

          @media (max-width: $breakpoint-sm) {
            padding: 1rem;
          }

          .f-link {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #ccc;
            padding: 1.5rem 2rem 1.5rem 0;
            @media (max-width: $breakpoint-md) {
              padding-right: 1rem;
            }

            > .icon {
              transition: transform .3s ease-in-out;
            }

            &:not(.collapsed) > .icon {
              transform: rotate(45deg);
            }
          }

          ul {
            margin: 0;

            .faq_reponse {
              margin-top: 1.5rem;
            }
          }
        }
      }
    }
  }
}
