.maison-nathan {
  position: relative;
  background-color: $blanc;
  border-top: $border-clear;
  border-left: $border-clear;
  border-right: $border-clear;
  border-radius: 4rem 4rem 0 0;
  overflow: hidden;

  .container {
    position: relative;
    @include flex-center;
    flex-direction: column;
    gap: 2rem;
    padding: 3.5rem 0;
    max-width: 96rem;
    z-index: 2;
    @media (max-width: $breakpoint-md) {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }

    .mn-top {
      position: relative;
      @media (max-width: $breakpoint-sm) {
        @include flex-center;
        flex-direction: column;
      }

      .mn-titre {
        font-family: 'LaMaisonNathan', sans-serif;
        font-size: 4.8rem;
        font-weight: $weight-normal;
        @media (max-width: $breakpoint-md) {
          font-size: 3.2rem;
        }

        &::after {
          color: $rouge_nathan;
        }
      }

      .button {
        position: absolute;
        top: 50%;
        right: -22rem;
        transform: translateY(-50%);
        @media (max-width: $breakpoint-sm) {
          position: unset;
          transform: none;
        }
      }
    }

    .mn-desc {
      text-align: center;
      font-size: $font-xs;
    }

    .sociaux-footer {
      @include flex-center;
      flex-wrap: wrap;
      gap: 2rem;
    }
  }

  .mn-graphiti {
    position: absolute;
    top: -10rem;
    right: -15rem;
    min-width: 86rem;
    height: 55rem;
    @media (max-width: $breakpoint-xxl) {
      right: -30rem;
    }
    @media (max-width: $breakpoint-xl) {
      right: -35rem;
    }
    @media (max-width: $breakpoint-lg) {
      right: -40rem;
    }
    @media (max-width: $breakpoint-md) {
      right: -45rem;
    }
    @media (max-width: $breakpoint-sm) {
      right: -50rem;
    }
    @media (max-width: $breakpoint-xs) {
      right: -55rem;
    }
  }
}