/* Footer */
#footer {
  position: relative;
  content-visibility: visible;
  contain-intrinsic-height: auto 50rem;
  padding-bottom: 5.2rem;
  @media (max-width: $breakpoint-xs) {
    padding-bottom: 4.8rem;
  }

  .container {
    display: flex;
    justify-content: space-between;

    .menu-footer {
      display: flex;
      justify-content: space-between;
      border-top: $border-clear;
      width: 100%;
      padding: 4.8rem 0;
      @media (max-width: $breakpoint-md) {
        gap: 2.4rem;
      }
      @media (max-width: $breakpoint-xs) {
        flex-direction: column;
        padding-top: 1.2rem;
      }

      ul {
        flex: 1;
        max-width: 30rem;
        list-style-type: none;
        padding-left: 0;
        margin: 0;

        li {
          &:not(:last-child) {
            margin-bottom: 1.6rem;
          }

          .lien-off, a, button {
            font-size: 1.4rem;
            &:hover {
              text-decoration: underline $principale;
            }
          }
        }
      }

      .level1 {
        > li {
          > a, > .lien-off, > button {
            font-weight: $weight-semibold;
            padding-left: 0;
            text-align: left;
            @media (max-width: $breakpoint-xs) {
              pointer-events: all;
            }
          }
        }
        &.menu-last-col {
          > li {
            > a, > .lien-off, > button {
              pointer-events: all;
            }
          }
        }
      }

      .level2 {
        padding-top: 1.6rem;
      }
    }
  }
}

.sub-footer {
  @include flex-center;
  flex-direction: column;
  border-top: $border-clear;
  width: 100%;
  @media (max-width: $breakpoint-xs) {
    justify-content: left;
    align-items: flex-start;
  }

  .logo-inscription {
    width: 15rem;
    padding: 4.8rem 0;
    @media (max-width: $breakpoint-xs) {
      padding: 2.4rem 0;
    }
  }

  ul {
    list-style-type: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2.5rem 6.4rem;
    padding-left: 0;
    margin: 0;
    @media (max-width: $breakpoint-xl) {
      gap: 2.4rem;
    }
    @media (max-width: $breakpoint-xs) {
      justify-content: left;
      align-items: flex-start;
    }

    li {
      a, .lien-off {
        color: $principale;
        font-size: 1.4rem;
        font-weight: 400;
      }
    }
  }
}