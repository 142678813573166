/*** Style generique ***/
body,
button,
fieldset,
form,
html,
textarea {
  font-family: 'Inter', sans-serif;
  font-weight: $weight-normal;

  /* Google Fonts Lining Numbers */
  -webkit-font-feature-settings: "lnum";
  -moz-font-feature-settings: "lnum";
  -ms-font-feature-settings: "lnum";
  -o-font-feature-settings: "lnum";
  font-feature-settings: "lnum";

}

body,
html {
  scroll-behavior: smooth;
}

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  font-size: 1.6rem;
}

body,
body.mce-content-body {
  color: $principale;
}

/* Saut à la ligne CMS et BUILDER EditeurTexte */
.clear {
  clear: both;
}

.scroller {
  //overflow-x: hidden;
}

.aheight {
  display: inline-block;
  line-height: 1.2;
  vertical-align: middle
}

.mini,
.fontsize80 {
  font-size: 80%
}

.fontsize85 {
  font-size: 85%
}

.fontsize90 {
  font-size: 90%
}

.fontsize110 {
  font-size: 110%
}

.big,
.fontsize120 {
  font-size: 120%
}


.lowercase {
  text-transform: lowercase
}

.uppercase {
  text-transform: uppercase
}

.nocase {
  text-transform: none;
}

figure {
  margin: auto;
}

.hidden,
figcaption {
  display: none;
}

.desktop {
  display: block;
}

:-ms-input-placeholder,
::-moz-placeholder,
::-webkit-input-placeholder,
::placeholder {
  color: #91a1a1;
  font-style: italic;
  text-transform: none;
  font-weight: 700;
  font-size: 1.3rem;
}

img {
  max-width: 100%;
  height: auto;
}

.cms img {
  height: auto;
}

.icon,
.text {
  display: inline-block;
  vertical-align: middle;
}

// Liens
a, .lien-off {
  text-decoration: none;
}

.lien-off {
  cursor: pointer;
}

.cms a:not(.button),
.mce-content-body a:not(.button),
a:not(.button),
.cms .lien-off:not(.button),
.mce-content-body .lien-off:not(.button),
.lien-off:not(.button) {
  color: $principale;
  cursor: pointer;
}

.cms a:not(.button):focus,
.cms a:not(.button):hover,
.mce-content-body a:not(.button):focus,
.mce-content-body a:not(.button):hover,
a:focus,
a:hover,
.cms .lien-off:not(.button):focus,
.cms .lien-off:not(.button):hover,
.mce-content-body .lien-off:not(.button):focus,
.mce-content-body .lien-off:not(.button):hover,
.lien-off:focus,
.lien-off:hover {
  text-decoration: none;
}

// Listes
.cms ol,
.cms ul,
.faq-answer ol,
.faq-answer ul,
.mce-content-body ol,
.mce-content-body ul {
  line-height: 1.5;
  list-style-type: inherit;
}

.cms ol li,
.cms ul li,
.faq-answer ol li,
.faq-answer ul li,
.mce-content-body ol li,
.mce-content-body ul li {
  list-style-position: inside;
  margin: .4rem auto .4rem 2rem;
  @media (max-width: $breakpoint-sm) {
    margin-left: 0;
  }
}

.cms ol,
.faq-answer ol,
.mce-content-body ol {
  list-style-type: decimal;
}

.liste li {
  list-style-type: square;
  list-style-position: inside;
  margin-left: 1rem;
  padding: .2rem 0;
}

// Tables
.table {
  display: table;
  border: .1rem solid #e3e3e3;
  border-collapse: collapse;
  font-size: 1.1rem;
  background-color: $blanc;
  color: #3d3d3d;
}

.table-container {
  width: 87vw;
  overflow-y: auto;
  overflow-x: auto;
}

.table td,
.table th {
  border: .1rem solid #e3e3e3;
  padding: .5rem 1rem;
}

.table th {
  padding: 1rem;
}

.td {
  display: table-cell;
}

// Classes génériques + classe zoom pour fancybox
.cms {
  .box-shadow .content img {
    box-shadow: 0 .5rem 1.5rem rgba(0, 0, 0, .5);
  }

  .vertical-center .box {
    display: flex;
    align-items: center;
  }
}

/*** /Style generique ***/
.grid-builder {
  flex: 1 auto;
}

.grid-builder-content .row {
  gap: 3.2rem 0;
}

#cms .scroller > .content {
  margin-top: 5rem;
}

#cms #bloc {
  padding: 2rem 2rem 0;
}

#cms .content .container {
  margin: 0 auto;
  width: 114rem;
  overflow: hidden;
  background-color: $blanc;
  min-height: 35rem;
}

#cms a.bx-next,
#cms a.bx-prev {
  color: $blanc;
}

.page-cms .vertical-center {
  display: flex;
  align-items: center;

  > div {
    width: 100%;
  }
}

/* Vidéo */
.type-video {
  .ratio-video {
    aspect-ratio: 590/315;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

/* Tableau */
.type-texte table {
  tr td:first-child,
  tr:first-child {
    font-weight: 700;
    background-color: #ddd;
  }

  td {
    border: .1rem solid #aaa;
  }
}

/* Formulaire */
.type-formulaire {
  .row {
    margin: 0;
    padding: 0;
  }
}

/* Blocs buider pour rendu dans le BO */
.bloc-builder {
  .h6 {
    font-size: $font-m
  }

  p {
    font-size: $font-s
  }
}

.texte-bold {
  font-weight: $weight-bold;
}

/* Pages Univers */
.page-univers .h1 {
  margin-top: 2rem;
}

/* Pages Carrefour */
.page-univers .h1 {
  margin-top: 2rem;
}

/* Pageination */
.page-cms {
  .swiper-pagination {
    position: static;
  }
}

/* Icone souligne */
.page-cms {
  .swiper-pagination {
    position: static;
  }
}

/* Blocs buider bordure et couleur */

.builder-bgc-gris {
  background-color: $neutre_gris_50 !important;
}

.builder-bgc-bleu {
  background-color: $neutre_bleu_100 !important;
}

.builder-bgc-beige {
  background-color: $neutre_beige_300 !important;
}

.builder-style-none {
  border: none !important;
  border-radius: 0 !important;
  background-color: unset !important;
  padding: 0 !important;

  &:has(h2.graphiti-small) {
    padding-left: 8rem !important;
    @media (max-width: $breakpoint-sm) {
      padding-left: 0
    }
  }

  h2 {
    gap: 1rem;
    @media (max-width: $breakpoint-md) {
      gap: .8rem;
    }
  }
}


/* Blocs taille images */
.bloc-builder {
  &:has(.ratio-debride) {
    justify-content: space-between !important;
    align-items: center !important;
    @media (max-width: $breakpoint-lg) {
      justify-content: left !important;
      align-items: flex-start !important;
    }
  }

  .ratio-standart {
    aspect-ratio: 650/380;
    width: 100%;
    max-width: 65rem;
    object-fit: cover;
    margin-top: 4rem;
    border-radius: $radius-ml $radius-ml 0 0;
    @media (max-width: $breakpoint-xl) {
      max-width: 45rem;
    }
    @media (max-width: $breakpoint-lg) {
      max-width: 60rem;
      height: 30rem;
      margin-top: 0;
      border-radius: 0 0 $radius-ml $radius-ml;
    }
    @media (max-width: $breakpoint-sm) {
      height: 22rem;
    }
  }

  .ratio-carre {
    width: 30rem;
    height: 30rem;
    object-fit: contain;
    border-radius: $radius-s $radius-s 0 0;
    margin-top: 4rem;
    box-shadow: $box-shadow-dark;
    aspect-ratio: 1;

    @media (max-width: $breakpoint-lg) {
      border-radius: 0 0 $radius-s $radius-s;
      margin-top: 0;
    }
    @media (max-width: $breakpoint-xs) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .ratio-debride {
    border-radius: $radius-m;
    margin: 4rem 0;
    @media (max-width: $breakpoint-lg) {
      margin-bottom: 0;
    }
    @media (max-width: $breakpoint-sm) {
      margin-top: 2rem;
    }
    @media (max-width: $breakpoint-xs) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

/* Gestion colonne 2 */

.col-sm-5, .col-md-5, .col-xl-5 {
  .reseaux {
    .reseaux-liste .reseau {
      width: 100%;
      @media (max-width: $breakpoint-lg) {
        width: calc(50% - .8rem);
      }
      @media (max-width: $breakpoint-sm) {
        width: 100%;
      }
    }
  }

  .bloc-auteurs {
    .list-3-cards {
      flex-wrap: wrap;

      .card {
        width: 100%;
        @media (max-width: $breakpoint-lg) {
          width: calc(100% / 3 - .7rem);
        }
        @media (max-width: $breakpoint-md) {
          width: calc(50% - 1.2rem);
        }
        @media (max-width: $breakpoint-sm) {
          width: 100%;
        }
      }
    }
  }

  .bloc-avis {
    .list-avis-cards {
      flex-wrap: wrap;

      .card {
        width: 100%;
        @media (max-width: $breakpoint-lg) {
          width: calc(100% / 3 - 1.2rem);
        }
        @media (max-width: $breakpoint-md) {
          width: calc(100% / 1.5);
        }
        @media (max-width: $breakpoint-sm) {
          width: 100%;
        }
      }
    }
  }
}

.col-sm-6, .col-md-6, .col-xl-6 {
  .igdt, .site-compagnon, .offre-numerique {
    flex-direction: column-reverse;
    gap: 0;

    img {
      margin-top: 0;
      border-radius: 0 0 $radius-ml $radius-ml;
    }
  }

  .collection {
    gap: 2.4rem;
    align-items: flex-end;
    flex-direction: row;
    @media (max-width: $breakpoint-lg) {
      gap: 0;
      align-items: flex-start;
      flex-direction: column-reverse;
    }

    img {
      //margin-top: 0;

      @media (max-width: $breakpoint-xl) {
        width: 25rem;
        height: 25rem;
        border-radius: $radius-s $radius-s 0 0;
      }

      @media (max-width: $breakpoint-lg) {
        width: 30rem;
        height: 30rem;
        border-radius: 0 0 $radius-s $radius-s;
      }
    }
  }

  .rte {
    flex-direction: column;
    gap: 0;
  }

  .reseaux {
    .reseaux-liste .reseau {
      width: 100%;
      @media (max-width: $breakpoint-lg) {
        width: calc(50% - .8rem);
      }
      @media (max-width: $breakpoint-sm) {
        width: 100%;
      }
    }
  }

  .bloc-auteurs {
    .list-3-cards {
      flex-wrap: wrap;

      .card {
        width: calc(50% - 1.2rem);
        @media (max-width: $breakpoint-lg) {
          width: calc(100% / 3 - .7rem);
        }
        @media (max-width: $breakpoint-md) {
          width: calc(50% - 1.2rem);
        }
        @media (max-width: $breakpoint-sm) {
          width: 100%;
        }
      }
    }
  }

  .bloc-avis {
    .list-avis-cards {
      flex-wrap: wrap;

      .card {
        width: 100%;
        @media (max-width: $breakpoint-lg) {
          width: calc(100% / 3 - 1.2rem);
        }
        @media (max-width: $breakpoint-md) {
          width: calc(100% / 1.5);
        }
        @media (max-width: $breakpoint-sm) {
          width: 100%;
        }
      }
    }
  }
}

.col-sm-7, .col-md-7, .col-xl-7 {
  .reseaux {
    .reseaux-liste .reseau {
      width: calc(50% - .8rem);
      @media (max-width: $breakpoint-lg) {
        width: calc(50% - .8rem);
      }
      @media (max-width: $breakpoint-sm) {
        width: 100%;
      }
    }
  }

  .bloc-auteurs {
    .list-3-cards {
      flex-wrap: wrap;

      .card {
        width: calc(50% - 1.2rem);
        @media (max-width: $breakpoint-lg) {
          width: calc(100% / 3 - .7rem);
        }
        @media (max-width: $breakpoint-md) {
          width: calc(50% - 1.2rem);
        }
        @media (max-width: $breakpoint-sm) {
          width: 100%;
        }
      }
    }
  }

  .bloc-avis {
    .list-avis-cards {
      flex-wrap: wrap;

      .card {
        width: calc(100% / 1.5);
        @media (max-width: $breakpoint-lg) {
          width: calc(100% / 3 - 1.2rem);
        }
      }
    }
  }
}

/* lien souligné tyniMCE */
#contenu p a {
  text-decoration: $principale underline;
}

.titre-centre {
  text-align: center;
}

body.fancybox-active {
  overflow: hidden;
}