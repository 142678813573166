#chemin {
  position: relative;
  background-color: $blanc;
  overflow: hidden;
  padding: 1.2rem 1.1rem;
  font-size: 1.4rem;
  z-index: 1;
  @media (max-width: $breakpoint-sm) {
    font-size: 80%;
  }

  .container {
    ul {
      display: flex;
      gap: .8rem;
      flex-wrap: wrap;
      margin-bottom: 0;

      li {
        list-style: none;
      }
    }
  }

  a, .lien-off {
    color: $cta;
    text-decoration: underline;

    &:hover {
      color: $principale;
    }
  }
}