@mixin title-global {
  color: $principale;
  font-family: 'Inter', sans-serif;
  font-weight: $weight-bold;
  word-wrap: normal;
  hyphens: none;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.2;

  &.icon-souligne {
    align-items: baseline;
    &::before {
      top: .2rem;
    }
  }
}

@mixin h1 {
  position: relative;
  font-size: 4rem;
  @media (max-width: $breakpoint-md) {
    font-size: 2.8rem
  }
}

@mixin h2 {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 3.6rem;
  @media (max-width: $breakpoint-md) {
    font-size: 2.6rem
  }
}

@mixin h3 {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 3.2rem;
  @media (max-width: $breakpoint-md) {
    font-size: 2.4rem
  }
}

@mixin h4 {
  position: relative;
  font-size: 2.6rem;
  @media (max-width: $breakpoint-md) {
    font-size: 2rem
  }
}

@mixin h5 {
  position: relative;
  font-size: 2rem;
  @media (max-width: $breakpoint-md) {
    font-size: 2rem
  }
}

@mixin h6 {
  font-size: 1.8rem;
  @media (max-width: $breakpoint-md) {
    font-size: 2rem
  }
}