.popin-element {
  .close {
    position: absolute;
    top: 2.4rem;
    right: 1.8rem;

    .icon {
      &::before {
        font-size: 1.4rem;
      }
    }
  }
}