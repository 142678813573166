.reseaux {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  background-color: white;
  border: $border-clear;
  border-radius: $radius-ml;
  padding: 4rem;
  @media (max-width: $breakpoint-sm) {
    padding: 4rem 2rem;
  }

  h2 {
    margin: 0 auto;
    @media (max-width: $breakpoint-sm) {
      align-items: center !important;
    }
  }

  .reseaux-liste {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 1.6rem;

    .reseau {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: calc(100% / 3 - 1.07rem);
      font-weight: $weight-medium;
      @media (max-width: $breakpoint-xl) {
        width: calc(50% - 1.07rem);
      }
      @media (max-width: $breakpoint-sm) {
        width: 100%;
      }

      .reseau-img {
        position: relative;
        background-color: $blanc;
        border: $border-clear;
        border-radius: $radius-s;
        width: 8rem;
        height: 8rem;
        min-height: 8rem;
        margin-bottom: -4rem;
        z-index: 1;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          border-radius: $radius-s;
        }
      }

      .reseau-img-vide {
        position: relative;
        width: 8rem;
        height: 8rem;
        min-height: 8rem;
        margin-bottom: -4rem;
        z-index: 1;
        visibility: hidden;
      }

      .informations {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        border: $border-clear;
        border-radius: $radius-ml;
        background-color: $blanc;
        padding: 6rem 1.6rem 1.8rem 1.6rem;
        height: 100%;
        width: 100%;

        .h6 {
          margin-bottom: .8rem;
        }

        .pastilles-reseaux {
          @include flex-center;
          flex-wrap: wrap;
          gap: 2.4rem;
          margin-top: 3.8rem;
        }
      }
    }
  }
}
