.bloc-carrousel-produits-article, .bloc-carrousel-produits-push {

  .card-product {
    width: 26.22rem;
    @media (max-width: $breakpoint-lg) {
      min-width: 22.7rem;
    }
    @media (max-width: $breakpoint-md) {
      min-width: 21.9rem;
    }
    @media (max-width: $breakpoint-sm) {
      width: calc(50% - .6rem);
      min-width: unset;
    }
  }

  .video-card {
    display: flex;
    width: calc(40% - 2rem);
    flex-direction: column;

    .transcription-video {
      margin-top: 1rem;
    }
  }

  .card-article {
    width: calc(40% - 2rem);
    min-width: 36rem;
    @media (max-width: $breakpoint-xs) {
      min-width: unset;
      width: 100%;
    }
  }

  .card-push {
    width: 54.74rem;
    height: auto;
    padding: 0;
    //order: 2;
    @media (max-width: $breakpoint-xl) {
      //order: 3;
      width: calc(50% - 1.15rem);
    }
    @media (max-width: $breakpoint-lg) {
      width: calc(50% - 1.2rem);
      max-height: 51.6rem;
    }
    @media (max-width: $breakpoint-sm) {
      width: 100%;
      max-height: unset;
    }
  }
}

.bloc-carrousel-produits .stitre-carrousel {
  margin-top: 3.2rem;
  text-align: center;
  @media (max-width: $breakpoint-sm) {
    text-align: left;
  }
}

.bloc-carrousel-articles .h2,
.bloc-carrousel-push .h2 {
  margin-bottom: 1.4rem;
}

.bloc-carrousel-articles {
  max-width: 112rem;
  margin-left: auto;
  margin-right: auto;

  .swiper-wrapper {
    margin-bottom: .6rem;

    .swiper-slide {
      &:last-of-type {
        margin-right: 0 !important;
      }
    }
  }

  .multi-slider {
    &.no-control + .button {
      margin-top: 1.4rem;
    }

    &:not(.no-control) + .button {
      margin-top: 4rem;
    }

    .swiper-button-prev,
    .swiper-button-next {
      margin-top: -7rem;
    }

    .swiper-button-prev {
      left: -9rem;
      @media (max-width: $breakpoint-xl) {
        left: -2.5rem;
      }
    }

    .swiper-button-next {
      right: -9rem;
      @media (max-width: $breakpoint-xl) {
        right: -2.5rem;
      }
    }
  }

  .all-actus {
    margin-top: 4.4rem;
  }
}
