.missions-box {
  position: relative;
  text-align: center;
  padding: 5.2rem 1.2rem 6rem;
  border-radius: 2.8rem;
  border: .1rem solid $neutre_gris_300;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background-color: rgba(40, 50, 68, .4);
    border-radius: 2.4rem;
  }

  .h3 {
    justify-content: center;
    color: #fff !important;
    z-index: 1;
  }

  p {
    position: relative;
    color: #fff;
    margin-top: 1.6rem;
    z-index: 1;
  }

  .button {
    margin: 1.6rem auto 4rem;
    z-index: 1;
    @media (max-width: $breakpoint-sm) {
      width: fit-content;
    }
  }
}

.list_card_mission {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 1.6rem;
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: -6rem;
  z-index: 1;
  @media (max-width: $breakpoint-sm) {
    flex-direction: column;
    gap: 1.2rem;
  }

  .card-mission {
    display: flex;
    flex-direction: column;
    width: calc(100% / 3 - 2rem);
    padding: 1.6rem;
    @media (max-width: $breakpoint-sm) {
      width: calc(100% - 3rem);
      margin-left: auto;
      margin-right: auto;
    }

    .h6 {
      margin-bottom: .8rem;
    }

    p {
      margin-bottom: 2rem;
    }

    .link-element {
      margin-top: auto;
      margin-left: auto;
    }
  }
}