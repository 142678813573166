.rassurances {
  margin-top: 4rem;
  margin-bottom: 3rem;

  .slick-slide {
    margin-right: 2rem;
    margin-left: 2rem;

    .s-link {
      @include flex-between;
      text-align: center;
      /*width: calc(20% - 1rem);*/

      .bloc-img {
        position: relative;
        display: inline-block;
        overflow: hidden;
        padding: 1rem 0;

        .icon {
          display: block;
          height: 8rem;
          line-height: 1;
          transition: all .3s ease-in-out;
        }
      }
      &:hover,
      &:focus {
        .bloc-img {
          img {
            transform: scale(1.1);
            transform: rotateY(180deg);
          }
        }
      }


      .h2 {
        margin-bottom: 1rem;
        transition: color .3s ease-in-out;
      }

      &:hover .h2 {
        color: $principale;
      }
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .rassurances {
    margin-top: 6rem;
  }
}

@media (max-width: $breakpoint-md) {
  .page-home .rassurances {
    margin-top: 6rem;
  }
}

@media (max-width: $breakpoint-xs) {
  .page-home .rassurances {
    margin-top: 2rem;
  }
}