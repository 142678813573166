.tag-element {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: $radius-l;
  font-size: $font-s;
  font-weight: $weight-normal;
  padding: .6rem 1.2rem;
  line-height: 1;
  transition: all .3s;

  &.hidden {
    display: none;
  }

  &.tag-element-standart {
    background-color: $blanc;
    color: $principale;
  }

  &.tag-element-success {
    background-color: $succes_fond;
    color: $principale;
    border: $border-success;
    border-radius: $radius-xs;
  }

  &.tag-element-termine {
    background-color: $succes_fond;
    color: $succes;
    font-weight: 700;
    border: $border-success;
  }

  &.tag-element-collection {
    background-color: $collection_fond;
    color: $principale;
    border: $border-collection;
  }

  &.tag-element-prescripteur {
    background-color: #fff;
    color: $prescripteur;
    border: $border-prescripteur;
  }

  &.tag-element-category {
    background-color: $blanc;
    border: $border-mn;
  }

  &.tag-element-clear-border {
    background-color: $blanc;
    color: $principale;
    border: $border-clear;
  }

  &.tag-element-border-grey {
    background-color: $neutre_gris_100;
    color: $principale;
    border: $border-clear;
  }

  &.tag-element-semibold {
    background-color: $blanc;
    color: $principale;
    font-weight: $weight-semibold;
  }

  &.tag-element-bold-border {
    border: $border-clear;
    font-weight: $weight-bold;
    text-transform: uppercase;
  }

  &.tag-element-clear-bold-border {
    color: $principale;
    border: $border-grey;
    font-weight: $weight-bold;
    background-color: $neutre_gris_50;
    text-transform: uppercase;
  }

  &.tag-element-red {
    color: $blanc;
    font-weight: $weight-bold;
    background-color: $rouge;
    text-transform: uppercase;
    padding: .8rem;
    border-radius: $radius-xs;
  }

  &.tag-element-orange {
    color: $blanc;
    font-weight: $weight-bold;
    background-color: $orange;
    text-transform: uppercase;
    padding: .8rem;
    border-radius: $radius-xs;
  }

  &.tag-element-bold-grey {
    color: $principale;
    background-color: $neutre_gris_300;
    font-weight: $weight-bold;
    text-transform: uppercase;
  }

  &.tag-element-bold-border-blue {
    color: $principale;
    background-color: $neutre_bleu_300;
    font-weight: $weight-bold;
    text-transform: uppercase;
  }

  &.tag-element-bold-blue-clear {
    color: $blanc;
    background-color: $neutre_bleu_300;
    font-weight: $weight-bold;
    text-transform: uppercase;
  }

  &.tag-element-uppercase-bold-ressource {
    background-color: $ressource_fond;
    border: $border-ressource;
    font-weight: $weight-bold;
    text-transform: uppercase;
  }

  &.tag-element-uppercase-bold-collection {
    background-color: $collection_fond;
    border: $border-collection;
    font-weight: $weight-bold;
    text-transform: uppercase;
  }

  &.tag-element-uppercase-bold-compagnon {
    background-color: $compagnon_fond;
    border: $border-compagnon;
    font-weight: $weight-bold;
    text-transform: uppercase;
  }

  &.tag-element-mini {
    background-color: $principale;
    color: $blanc;
    border-radius: $radius-xxl;
    font-size: $font-xxs;
    padding: .4rem .7rem;
  }

  &.tag-element-promo {
    background-color: $neutre_gris_50;
    color: $principale;
    border: $border-clear;
    border-radius: $radius-xs;
    font-size: $font-xs;
    font-weight: 600;
    padding: .8rem 1.8rem;
    padding: .8rem 1.8rem;
  }

  &.tag-element-availability-on, &.tag-element-availability-off, &.tag-element-availability-none {
    @include flex-center;
    gap: .8rem;
    background-color: $neutre_gris_50;
    color: $principale;
    border: $border-clear;
    border-radius: $radius-xs;
    padding: .8rem !important;

    &:before {
      content: "";
      border-radius: 50%;
      width: 1.2rem;
      height: 1.2rem;
    }

    span {
      text-wrap: nowrap;
    }
  }

  &.tag-element-availability-none {
    &:before {
      display: none;
    }
  }

  &.tag-element-availability-on {
    &:before {
      background-color: $vert;
    }
  }

  &.tag-element-availability-off {
    &:before {
      background-color: $rouge_nathan;
    }
  }
}

a.tag-element.tag-element-semibold:hover {
  background-color: #f1feff;
}

a.tag-element {
  &:hover {
    text-decoration: underline $principale;
  }
}
