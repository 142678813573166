//////////////////////////////////////////* Screen Reader Only *//////////////////////////////////////////

/* Tout le temps */
.sr-only{
  border: 0 !important;
  clip: rect(1px,1px,1px,1px) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
/* Au focus */
.sr-only-focusable:focus{
  clip: auto !important;
  clip-path: none !important;
  height: auto !important;
  margin: auto !important;
  overflow: visible !important;
  width: auto !important;
  white-space: normal !important;
}

//////////////////////////////////////* Focus interactive elements *///////////////////////////////////////
.skip-focus, a:focus, button:focus, input:focus, .lien-off:focus, .lien-off2:focus, select:focus, textarea:focus, .custom-select:focus {
  outline: none !important;
}

.skip-focus, a:focus-visible, button:focus-visible, input:focus-visible, .lien-off:focus-visible, .lien-off2:focus-visible, select:focus-visible, textarea:focus-visible,.custom-select:focus-visible, [tabindex="0"]:focus-visible {
  outline: .2rem solid $principale !important;
  border-color: transparent !important;
}

///////////////////////////////////////////* Focus skip link *////////////////////////////////////////////

.skip-to-content-link {
  position: absolute;
  left: 0;
  height: 30px;
  background: $principale;
  color: $blanc !important;
  padding: 8px;
  z-index: 20000;
  transform: translateY(-100%);
  transition: transform 0.3s;
}

.skip-to-content-link:focus {
  transform: translateY(0%);
}