/* SELECT 2 */
.select2-c {
  display: inline-block;
  width: calc(100% / 5 - 1.3rem);
  height: 4rem;
  @media (max-width: $breakpoint-xl) {
    width: calc(20% - 1.3rem);
  }
  @media (max-width: $breakpoint-lg) {
    width: calc(25% - 1.2rem);
  }
  @media (max-width: $breakpoint-md) {
    width: calc(100% / 3 - 1.1rem);
  }
  @media (max-width: $breakpoint-sm) {
    width: calc(50% - .8rem);
  }
  @media (max-width: $breakpoint-xs) {
    width: 100%;
  }
}

.select2-container {
  display: inline-block;
  vertical-align: middle;
}

.select2-container.select2-container--default[style*="top: 0"] {
  opacity: 0;
}

.select2-dropdown {
  border-color: $neutre_gris_300;
}

.select2-container--default {
  &.select2-container--focus .select2-selection--multiple {
    display: flex;
    align-items: center;
    height: 4rem;
    color: $noir;
    border: .1rem solid $neutre_gris_300;
  }

  .select2-search--inline .select2-search__field {
    width: 100% !important;
    height: 4rem;
    pointer-events: none;
    margin-left: 0;
    margin-top: 0;
    padding-left: 1.2rem;
    padding-top: 0;
    line-height: 2.6;

    &:focus-visible {
      outline: none !important;
    }

    &::placeholder {
      color: $principale;
      opacity: 1;
    }
  }

  .select2-selection--multiple {
    display: flex;
    align-items: center;
    height: 4rem;
    border: .1rem solid $neutre_gris_300;
    background-color: $neutre_gris_100;
    border-radius: 2.4rem;
    padding-bottom: 0;
    cursor: pointer;
    width: 100%;

    .counter {
      margin-left: 1rem;
    }

    .select2-search {
      width: 100%;
    }

    .select2-selection__rendered {
      display: none;
    }
  }

  .select2-results__option--highlighted.select2-results__option--selectable {
    color: $principale;
    background-color: $blanc;
  }
}

.select2-results__option[data-type="secondaire"]{
  margin-left: 4rem;
}

.select2-results__option--selectable {
  position: relative;
  margin-left: 2.5rem;
  font-size: 1.4rem;

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    left: -2rem;
    display: block;
    width: 1.4rem;
    height: 1.4rem;
    transform: translateY(-50%);
  }

  &::before {
    content: '';
    display: block;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.6rem;
    width: 1.6rem;
    height: 1.6rem;
    margin: 0;
    text-align: center;
    color: #333;
    border: .1rem solid rgba(0, 0, 0, .2);
    background: #fff;
  }

  &.select2-results__option--selected {
    background-color: transparent;

    &::after {
      content: "\FA2B" !important;
      font-family: kfont;
      color: $principale;
      text-rendering: auto;
      font-size: .9rem;
      margin-left: .2rem;
      margin-top: .2rem;
    }
  }
}
