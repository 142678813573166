body .bloc-avis {
  .h3 {
    justify-content: center;
    margin-bottom: 4rem !important;
  }

  .list-avis-cards {
    &:not(:has(.multi-slider)) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 3.8rem 2.4rem;

    }

    .multi-slider {
      .card {
        margin-top: 2rem;
      }
    }

    .card {
      position: relative;
      height: auto;
      width: calc(100% / 3 - 1.6rem);
      padding: 3.5rem 1.5rem 1.5rem;
      @media (max-width: $breakpoint-xl) {
        width: calc(50% - 1.6rem);
      }
      @media (max-width: $breakpoint-md) {
        width: 100%;
      }

      .icon-gsn-citation {
        position: absolute;
        top: -3rem;
        left: 1.5rem;
        font-size: 4.8rem;

        &::before {
          color: $neutre_bleu_300;
        }
      }

      .flex-avis {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;

        .desc-card-avis {
          @include line-clamp(3);
        }

        .link-element {
          margin-top: .8rem;
          margin-left: auto;
        }

        img {
          margin-right: 1rem;
        }
      }
    }
  }
}
