.igdt {
  display: flex;
  justify-content: space-between;
  gap: 6rem;
  background-color: white;
  border: $border-clear;
  border-radius: $radius-ml;
  padding: 0 4rem;
  overflow: hidden;
  @media (max-width: $breakpoint-lg) {
    flex-direction: column-reverse;
    justify-content: flex-end;
    gap: 0;
  }
  @media (max-width: $breakpoint-sm) {
    padding: 0 2rem;
  }

  &.droite {
    .img-igdt {
      @media (min-width: $breakpoint-lg) {
        margin-left: auto;
      }
    }
  }

  &.gauche {
    flex-direction: row-reverse;
    @media (max-width: $breakpoint-lg) {
      flex-direction: column-reverse;
    }

    .contenu-igdt {
      margin-right: auto;
    }
  }

  .contenu-igdt {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1.6rem;
    padding: 4rem 0;

    &:has(h2.graphiti-small) {
      padding-left: 8rem;
      @media (max-width: $breakpoint-sm) {
        padding-left: 0;
      }
    }

    .sous-titre-igdt {
      font-size: 1.8rem;
      font-weight: $weight-bold;
    }

    .flex-button {
      display: flex;
      flex-wrap: wrap;
      gap: .8rem;
    }
  }
}
