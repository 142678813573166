.page-home {
  .home-principale {
    margin-top: 4.8rem;

    h1 {
      flex-direction: column;
      margin-bottom: .8rem;
      .stitre-h1 {
        font-size: 1.6rem;
        font-weight: 400;
      }
    }

    .main-menu-home {
      margin-top: 4rem;

      .level1 {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
        gap: 1.6rem;
        padding: 0;

        .menu-item {
          &:has(.liens-principaux) {
            width: calc(50% - .8rem);
            @media (max-width: $breakpoint-sm) {
              width: 100%;
            }
          }

          &:has(.liens-secondaire) {
            width: calc((100% / 3) - 1.1rem);
            @media (max-width: $breakpoint-sm) {
              width: calc((100% / 2) - 1.1rem);
            }
            @media (max-width: $breakpoint-xs) {
              width: 100%;
            }
          }

          .lien-menu-home {
            position: relative;
            border-radius: $radius-s;
            height: 100%;

            img {
              object-fit: cover;
            }

            .libelle {
              pointer-events: none;

              &::after {
                font-size: 2rem;
              }
            }
          }

          .liens-principaux {
            overflow: hidden;

            &:hover {
              img {
                transform: scale(1.1);
              }
            }

            &::after, &::before {
              content: '';
              position: absolute;
              bottom: 0;
              left: -1%;
              right: 0;
              border-bottom-left-radius: $radius-s;
              border-bottom-right-radius: $radius-s;
              width: 101%;
              height: 50%;
              z-index: 1;
            }


            &::after {
              background: linear-gradient(180deg, rgba(43, 59, 88, 0.00) 0%, #2B3B58 100%);
              opacity: 1;
            }

            &::before {
              background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFFFFF 100%);
              opacity: 0;
            }

            img {
              height: 100%;
              transition: transform .3s;
            }

            .libelle {
              position: absolute;
              bottom: 2.4rem;
              left: 0;
              right: 0;
              @include flex-between;
              color: $blanc;
              width: 100%;
              padding: 0 2.4rem;
              z-index: 2;
              transition: color .3s ease-in-out;

              &::after {
                color: $blanc;
                transition: color .3s ease-in-out;
              }
            }
          }

          .liens-secondaire {
            display: block;
            background-color: $blanc;
            border: $border-clear;
            padding: 1.6rem;
            text-decoration: none;

            &:hover {
              img {
                transform: scale(1.1);
              }

              .libelle {
                &::after {
                  color: $principale;
                }
              }
            }

            & > * {
              margin: 0 auto;
            }

            img {
              transition: transform .3s;
            }

            .libelle {
              gap: 2rem;
              text-align: center;
              margin-top: 2.2rem;

              .icon {
                position: relative;

                &::before {
                  position: relative;
                  z-index: 1;
                }

                .surligne {
                  bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}