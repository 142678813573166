/* Positionnement */
@mixin absolute-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@mixin flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@mixin cover-img {
  display: block;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
}

/* Limite le nombre de ligne du champ, NE PAS METTRE DE HAUTEUR */
@mixin line-clamp($nb-line:3) {
  display: -webkit-box;
  -webkit-line-clamp: $nb-line;
  line-clamp: $nb-line;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
}

@mixin sep($couleur:#999, $hauteur:.1rem, $largeur:100%) {
  display: block;
  width: $largeur;
  height: $hauteur;
  margin: 1rem auto;
  background-color: $couleur;
}

/* Effets css */

@mixin box-shadow {
  box-shadow: 0 .6rem .8rem 0 #cfcfcf;
}

@mixin  box-shadow-light {
  box-shadow: 0 .3rem .4rem 0 #cfcfcf;
}

/* Scrollbar */
@mixin scrollbar($scrollbar-color:rgba(0,0,0,0.2), $scrollbar-color-bg:transparent, $scrollbar-width:.8rem, $max-height:none){

  overflow-y: auto;
  max-height: $max-height;

  /* Works on Firefox */
  & {
    scrollbar-width: thin;
    scrollbar-color: $scrollbar-color $scrollbar-color-bg;
  }

  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: $scrollbar-width;
  }

  &::-webkit-scrollbar-track {
    background: $scrollbar-color-bg;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-color;
    /*border-radius: 50%;*/
    border: 0 solid $scrollbar-color-bg;
  }
}

//Bloc de texte

@mixin grey-box {
  background-color: $neutre_gris_50;
  border: $border-clear;
  border-radius: $radius-s;
}

@mixin beige-box {
  background-color: $neutre_beige_300;
  border: $border-clear;
  border-radius: $radius-s;
}