.hero {
  position: relative;
  background-color: $neutre_beige_300;
  padding: 0 0 10rem 0;
  margin-bottom: -5rem;
  overflow: hidden;

  &:has(.title-ec) {
    @media (max-width: $breakpoint-sm) {
      padding-bottom: 12rem;
    }
  }

  &.hub,
  &.carrefour {
    padding: 0 0 14rem 0;
  }

  &.hub {
    @media (max-width: $breakpoint-sm) {
      padding: 0 0 10rem 0;
    }
  }

  &.carrefour {
    @media (max-width: $breakpoint-sm) {
      padding: 0 0 9rem 0;
    }
  }

  .container {
    height: 100%;
    margin-top: 4rem;

    &:has(h1.carrefour),&:has(h1.hub) {
      margin-top: 6rem;
      @media (max-width: $breakpoint-sm) {
        margin-top: 4rem;
      }
    }
  }

  .hero-title {
    margin-top: 1.6rem;
  }

  .fav-hero, .partage-hero {
    width: 4rem;
    height: 4rem;
    box-shadow: none;

    &::after {
      font-size: 1.8rem;
    }
  }

  h1 {
    width: 100%;

    &.have-img {
      position: relative;
      z-index: 1;
      height: 100%;

      &.hub {
        width: 65%;
      }

      &.carrefour {
        width: 35%;
      }
    }
  }

  ~ .cms,
  ~ #contenu {
    position: relative;
    background-color: $blanc;
    border-radius: 4rem 4rem 0 0;
    padding-top: 6rem;
    z-index: 2;
    @media (max-width: $breakpoint-sm) {
      padding-top: 4rem;
      border-radius: 1.6rem 1.6rem 0 0;
    }
  }

  .article-hero {
    margin-top: 1.6rem;

    & > * {
      margin-bottom: 1.6rem;
    }

    .flex-tag-publication {
      .tags-hero {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: .8rem;

        .tags-line {
          display: flex;
          flex-wrap: wrap;
          gap: .8rem;
        }
      }
    }

    .date-publi {
      text-align: right;
    }
  }

  .flex-titre-fav {
    @include flex-between;
    gap: 1.2rem;
  }

  .img-hero {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 65%;
    mask-image: linear-gradient(to left, rgba(0, 0, 0, 1) 46.62%, rgba(0, 0, 0, 0.08) 92.66%, rgba(0, 0, 0, 0) 98.1%);
    @media (max-width: $breakpoint-sm) {
      display: none;
    }

    &.hub {
      width: 35%;
    }

    &.carrefour {
      width: 65%;
    }

    img {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
    }
  }

  .hero-produit {
    display: flex;
    align-items: center;
    gap: .5rem;
    margin-top: 3.2rem;
    margin-bottom: 6rem;

    &.hero-produit-light {
      margin-top: -1.8rem;
      margin-bottom: -3rem;
    }

    &.hero-produit-medium {
      margin-top: -2rem;
      margin-bottom: 4.4rem;
    }

    .flex-hero-produit {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      .vue-produit {
        display: flex;
        align-items: center;
        gap: .8rem;
        height: fit-content;

        label {
          font-weight: 500;
        }
      }

      .action-produit {
        @include flex-between;
        gap: 1.6rem;

        .action-produit-lien {
          @include flex-between;
          gap: 1.4rem;
          background-color: $blanc;
          border: $border-clear;
          border-radius: $radius-xxl;
          padding: 1rem 1.6rem;
          transition: border .3s ease-in-out;

          &:hover {
            border: $border-main;
          }

          &::after {
            content: "\FA2C";
            font-family: kfont, sans-serif;
            font-size: 1.2rem;
          }

          &.desktop {
            @media (max-width: $breakpoint-sm) {
              display: none;
            }
          }

          &.mobile {
            display: none;
            @media (max-width: $breakpoint-sm) {
              display: flex;
            }
          }
        }

        .produit-action-button {
          @include flex-between;
          gap: .8rem;
        }
      }
    }
  }
}

.block-connexion {
  .h2 {
    justify-content: center;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
  }

  .custom-form {
    input {
      padding-top: .7rem;
      margin-top: .4rem;
      margin-bottom: 1.4rem;
      background-color: #fff;
    }

    button[type="submit"] {
      margin-top: 1.6rem;
      margin-left: auto;
      margin-right: auto;
    }

    .formline {
      margin-bottom: .8rem;
    }

    .mdp-oublie-c {
      text-align: center;
      margin-top: 1.1rem;
    }
  }
}

.page-auteur, .page-collection {
  .hero {
    padding: 0 0 6.6rem 0;

    .container {
      margin-top: 1.6rem;
    }
  }
}