.page-delegue-peda {
  .hero ~ #contenu {
    padding-top: 5.6rem;
    margin-bottom: 9rem;
  }

  .tooltip {
    pointer-events: none;
  }
}

.map-c {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.4rem;
  margin-top: 8rem;
  margin-bottom: 11rem;

  @media (max-width: $breakpoint-lg) {
    gap: 1.2rem;
  }

  @media (max-width: $breakpoint-md) {
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .map-container {
    border-radius: 1.6rem;
    border: .1rem solid $neutre_gris_300;
    background: $neutre_gris_50;
    padding: 3.2rem 4.2rem;
    width: calc(100% - 38rem);
    @media (max-width: $breakpoint-xl) {
      padding-left: 0;
      padding-right: 0;
    }
    @media (max-width: $breakpoint-lg) {
      width: calc(100% - 34rem);
    }
    @media (max-width: $breakpoint-md) {
      width: 100%;
    }
  }

  .right-box-map {
    width: 38rem;
    @media (max-width: $breakpoint-lg) {
      width: 34rem;
    }
    @media (max-width: $breakpoint-md) {
      order: -1;
    }

    .liste-delegues-sticky {
      position: sticky;
      top: 2rem;
    }

    @media (max-width: $breakpoint-lg) {
      .titre-departement {
        font-size: 2.6rem;
      }
    }
  }

  .help-box {
    gap: 1.3rem;
    border-radius: 1.6rem;
    border: .1rem solid $neutre_gris_300;
    background: $neutre_gris_50;
    padding: 2.1rem 1.6rem;

    .h3.icon {
      align-self: baseline;
      margin-top: 3px;
    }

    .h3 {
      text-align: left;
      margin-bottom: 1.3rem;
    }

  }

}

#map-svg {
  width: 100%;
  height: 100%;
  margin-top: 1rem;

  .land {
    fill: $neutre_gris_300;
    fill-opacity: 1;
    stroke: #fff;
    stroke-opacity: 1;
    stroke-width: 0.9;
    stroke-miterlimit: 3.97446823;
    stroke-dasharray: none;

    &:hover {
      fill: #c6c6c6;
      cursor: pointer;
    }

    &:focus,
    &:focus-visible {
      outline: none;
    }

    &.departement75,
    &.departement92,
    &.departement93,
    &.departement94 {
      fill: $neutre_bleu_300;

      &:not(.idf-zoom):hover {
        cursor: default;
        pointer-events: none;
      }

      &.idf-zoom:hover {
        fill: #c6c6c6;
      }
    }

    &.idf-zoom.active:hover,
    &.active {
      fill: $cta;
    }
  }

  .text-departement {
    pointer-events: none;

    &.active {
      display: none;
    }
  }

  @media (max-width: $breakpoint-sm) {
    .text-departement {
      display: none;
    }
  }
}

.text-departement {
  position: relative;
  font-size: 1.3rem;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  font-stretch: normal;
  fill: #283244;
  fill-opacity: 1;
  transition: opacity .3s ease-in-out;

  &.text-departement03 {
    transform: translate(4px, -10px);
  }

  &.text-departement04 {
    transform: translate(0, 7px);
  }

  &.text-departement05 {
    transform: translate(1px, -4px);
  }

  &.text-departement07 {
    transform: translate(1px, 20px);
  }

  &.text-departement08 {
    transform: translate(-3px, 6px);
  }

  &.text-departement09 {
    transform: translate(1px, -5px);
  }

  &.text-departement11 {
    transform: translate(-5px, -9px);
  }

  &.text-departement12 {
    transform: translate(0px, 5px);
  }

  &.text-departement13 {
    transform: translate(1px, -9px);
  }

  &.text-departement14 {
    transform: translate(1px, -8px);
  }

  &.text-departement15 {
    transform: translate(0, -5px);
  }

  &.text-departement16 {
    transform: translate(-2px, -3px);
  }

  &.text-departement18 {
    transform: translate(2px, 7px);
  }

  &.text-departement2A {
    transform: translate(6px, 17px);
  }

  &.text-departement2B {
    transform: translate(5px, 14px);
  }

  &.text-departement21 {
    transform: translate(0, 11px);
  }

  &.text-departement23 {
    transform: translate(1px, -4px);
  }

  &.text-departement26 {
    transform: translate(-3px, 17px);
  }

  &.text-departement28 {
    transform: translate(0px, 10px);
  }

  &.text-departement29 {
    transform: translate(5px, 3px);
  }

  &.text-departement30 {
    transform: translate(9px, -6px);
  }

  &.text-departement31 {
    transform: translate(10px, -8px);
  }

  &.text-departement32 {
    transform: translate(-1px, -6px);
  }

  &.text-departement33 {
    transform: translate(-1px, 14px);
  }

  &.text-departement34 {
    transform: translate(-1px, -6px);
  }

  &.text-departement35 {
    transform: translate(1px, 10px);
  }

  &.text-departement39 {
    transform: translate(2px, 15px);
  }

  &.text-departement42 {
    transform: translate(-6px, 6px);
  }

  &.text-departement43 {
    transform: translate(0, -9px);
  }

  &.text-departement45 {
    transform: translate(0, -4px);
  }

  &.text-departement48 {
    transform: translate(0, 6px);
  }

  &.text-departement50 {
    transform: translate(1px, 15px);
  }

  &.text-departement51 {
    transform: translate(2px, -5px);
  }

  &.text-departement52 {
    transform: translate(0, 11px);
  }

  &.text-departement54 {
    transform: translate(-1px, 21px);
  }

  &.text-departement55 {
    transform: translate(0, 18px);
  }

  &.text-departement56 {
    transform: translate(0, -8px);
  }

  &.text-departement57 {
    transform: translate(-7px, -3px);
  }

  &.text-departement59 {
    transform: translate(10px, 12px);
  }

  &.text-departement60 {
    transform: translate(0, -5px);
  }

  &.text-departement61 {
    transform: translate(6px, -17px);
  }

  &.text-departement62 {
    transform: translate(-6px, 1px);
  }

  &.text-departement64 {
    transform: translate(6px, -17px);
  }

  &.text-departement65 {
    transform: translate(0, 14px);
  }

  &.text-departement66 {
    transform: translate(6px, -9px);
  }

  &.text-departement68 {
    transform: translate(0, 11px);
  }

  &.text-departement69 {
    transform: translate(-3px, 12px);
  }

  &.text-departement70 {
    transform: translate(2px, -6px);
  }

  &.text-departement71 {
    transform: translate(-1px, -5px);
  }

  &.text-departement73 {
    transform: translate(6px, -3px);
  }

  &.text-departement74 {
    transform: translate(3px, -3px);
  }

  &.text-departement75 {
    transform: translate(-11px, 5px);
  }

  &.text-departement76 {
    transform: translate(4px, -5px);
  }

  &.text-departement77 {
    transform: translate(-1px, 5px);
  }

  &.text-departement80 {
    transform: translate(0, -5px);
  }

  &.text-departement81 {
    transform: translate(-2px, -4px);
  }

  &.text-departement82 {
    transform: translate(-6px, -5px);
  }

  &.text-departement83 {
    transform: translate(-1px, -3px);
  }

  &.text-departement85 {
    transform: translate(1px, -5px);
  }

  &.text-departement86 {
    transform: translate(-4px, 13px);
  }

  &.text-departement87 {
    transform: translate(-2px, 9px);
  }

  &.text-departement88 {
    transform: translate(8px, -12px);
  }

  &.text-departement89 {
    transform: translate(0px, 5px);
  }

  &.text-departement90 {
    transform: translate(0, 4px);
    font-size: 10px;
  }

  &.text-departement91 {
    transform: translate(1px, 4px);
  }

  &.text-departement92 {
    transform: translate(-17px, 34px);
  }

  &.text-departement93 {
    transform: translate(6px, -22px);
  }

  &.text-departement94 {
    transform: translate(4px, 20px);
  }

  &.text-departement95 {
    transform: translate(6px, -7px);
    font-size: 1.2rem;
  }

  &.text-departement976 {
    transform: translate(85px, 105px);
  }

  &.text-departement987 {
    transform: translate(373px, 593px);
  }

  &.text-departement988 {
    transform: translate(465px, 595px);
  }
}

.tooltip-departement {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  padding: .8rem 1.2rem;
  border: 1px solid $neutre_gris_300;
  border-radius: .4rem;
  background-color: #fff;
  white-space: nowrap;
}

.text-idf,
.text-dom {
  font-size: 1.2rem;
}

.right-box-map {
  position: relative;

  .h3.graphiti-small {
    font-size: 1.8rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
    @media (max-width: $breakpoint-lg) {
      margin-top: 2rem;
    }
  }
}

.liste-delegues-c {
  .h3 {
    width: 100%;
    margin-top: 8rem;

    &::after {
      font-size: 2.5rem;
      margin-left: auto;
      margin-right: 3rem;
      transform: rotate(0deg);
      transition: transform .3s, margin-right .3s;
      @media (max-width: $breakpoint-xs) {
        font-size: 1.8rem;
        margin-right: 0;
      }
    }

    &.active::after {
      transform: rotate(90deg);
    }

    @media (max-width: $breakpoint-md) {
      margin-top: 5rem;
    }
    @media (max-width: $breakpoint-xs) {
      font-size: 1.6rem;
    }
  }

  .liste-delegues {
    margin-top: 3rem;
  }
}

.card-delegue-container {
  display: flex;
  gap: .6rem;
  @media (max-width: $breakpoint-xs) {
    flex-direction: column;
  }

  .img-block {
    width: 10rem;
    height: 10rem;
    border-radius: .8rem;
    @media (max-width: $breakpoint-lg) {
      width: 7rem;
      height: 7rem;
    }

    &.img-block-defaut {
      @include flex-center;
      background-color: $neutre_gris_300;

      .icon {
        font-size: 4.8rem;
      }
    }

    img {
      object-fit: contain;
      border-radius: .8rem;
    }
  }

  .card-delegue {
    display: flex;
    flex-direction: column;
    gap: .6rem;
    width: calc(100% - 10rem);
    border-radius: 0.8rem;
    border: 1px solid $neutre_gris_300;
    background-color: $neutre_gris_50;
    padding: .8rem;
    @media (max-width: $breakpoint-lg) {
      width: calc(100% - 7rem);
    }

    .name-delegue {
      font-weight: 700;
    }

    .icon {
      font-size: 2.4rem;
      margin-right: .6rem;
    }

    a {
      text-decoration: underline;
      @media (max-width: $breakpoint-lg) {
        font-size: 14px;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}