.rte {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  background-color: white;
  border-radius: $radius-ml;
  border: $border-clear;
  padding: 4rem;
  @media (max-width: $breakpoint-sm) {
    padding: 4rem 2rem;
  }

  &:has(h2.graphiti-small) {
    padding-left: 12rem;
    @media (max-width: $breakpoint-sm) {
      padding-left: 2rem;
    }
  }

  .sous-titre-rte {
    font-size: 1.8rem;
    font-weight: $weight-bold;
  }

  .flex-button {
    display: flex;
    flex-wrap: wrap;
    gap: .8rem;
  }

  .fancybox-content {
    max-width: 40rem;
  }
}

.popin-avis {
  max-width: 80rem;
}