.bloc-carrousel-texte-image {
  h2 + p {
    margin-top: 2.4rem;
  }

  .h4 {
    margin: 2.4rem 0 !important;
  }

  .liste-categorie {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2.4rem 1.2rem;
    margin-top: 2.4rem;

    &.liste-centree {
      justify-content: center;
    }

    .flex-categories {
      border: $border-clear;
      border-radius: $radius-s;
      padding: 1.2rem 0;
      @media (max-width: $breakpoint-xs) {
        width: 100%;
      }

      h3 {
        margin-bottom: .4rem;
        padding: 0 .8rem;
        font-size: 2rem;
      }

      .flex-card {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1.4rem;

        .card-citl {
          display: flex;
          flex-direction: column;
          width: calc((142.3rem - 1.5rem) / 5 - 1.16rem);
          @media (max-width: $breakpoint-xxl) {
            width: calc((132rem - 1.5rem) / 5 - 1.16rem);
          }
          @media (max-width: $breakpoint-xl) {
            width: calc((114rem - 1.5rem) / 4 - 1.1rem);
          }
          @media (max-width: $breakpoint-lg) {
            width: calc((96rem - 1.5rem) / 4 - 1.1rem);
          }
          @media (max-width: $breakpoint-md) {
            width: calc((72rem - 1.5rem) / 3 - 1rem);
          }
          @media (max-width: $breakpoint-sm) {
            width: calc((54rem - 1.5rem) / 2 - .8rem);
          }
          @media (max-width: $breakpoint-xs) {
            width: 100%;
            max-width: 27rem;
            margin: 0 auto;
          }

          .img-card-c {
            position: relative;
            margin-bottom: 1.6rem;
            padding: 2rem 1rem .4rem 1rem;

            .lien-ean {
              width: 100%;
            }

            img {
              width: 100%;
              object-fit: contain;
              height: 28rem;

              &.cover {
                object-fit: cover;
              }
            }

            .image-perso {
              height: unset;
            }

            .tag-element {
              position: absolute;
              left: .8rem;
              top: 2rem;
              transform: translateY(-50%);
            }
          }

          .text-c {
            padding: 0 .8rem;

            .link-element {
              margin-left: .4rem;

              &:not(:last-of-type) {
                margin-bottom: 1.6rem;
              }

              .icon {
                position: relative;
                width: 2rem;

                &::before {
                  font-size: 1.8rem;
                }

                &.icon-gsn-livre_marque_page {
                  .surligne {
                    top: 2rem;
                  }

                  &::before {
                    font-size: 2.4rem;
                  }
                }

                &.icon-gsn-ordinateur::before {
                  font-size: 1.8rem;
                }

                &.icon-gsn-play::before {
                  padding-left: .4rem;
                }

                &.icon-gsn-plus::before {
                  font-size: 1.6rem;
                  padding-left: .2rem;
                }

                &.icon-gsn-compagnon::before {
                  font-size: 2rem;
                }

                .surligne {
                  z-index: -1;
                  top: 1.4rem;
                  left: -.2rem;
                }
              }
            }

            .desc {
              margin-top: 1.6rem;
            }
          }
        }
      }
    }
  }
}