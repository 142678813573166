#results-search {
  #contenu {
    padding-top: 0;
    background-color: transparent;
  }

  .header-tabs {
    display: flex;
    width: 100%;
    margin-top: -3rem;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: $breakpoint-md) {
      display: block;
      position: relative;
      max-width: 28rem;
      .nav-tabs {
        padding-left: 0;
        flex-wrap: nowrap;
      }
      .swiper-button-prev {
        left: -5.5rem;
      }
      .swiper-button-next {
        right: -5.5rem;
      }
    }

    @media (max-width: $breakpoint-xs) {
      max-width: 20rem;
    }

    .swiper-container {
      display: flex;
      width: 100%;
      padding-top: 0;
      overflow: visible;
      @media (max-width: $breakpoint-md) {
        overflow: hidden;
        padding-top: .5rem;
      }
    }

    li {
      flex: 1 0 0;
      border: .1rem solid $neutre_gris_300;
      margin-right: -.1rem;
      //height: auto;
      height: 15rem; // Nécessaire si plusieurs blocs n'ont pas de résulat
      @media (max-width: $breakpoint-md) {
        height: 100%;
        flex: auto;
        flex-shrink: 0;
        .button {
          box-shadow: none;
        }
      }

      &:first-child {
        border-radius: .8rem 0 0 .8rem;
        @media (max-width: $breakpoint-md) {
          border-radius: .8rem;
        }

        .card-result {
          border-radius: .8rem 0 0 .8rem;
          margin-left: 0;
          @media (max-width: $breakpoint-md) {
            border-radius: .8rem;
          }
        }
      }

      @media (max-width: $breakpoint-md) {
        &:nth-child(2) .card-result {
          border-radius: 0 .8rem 0 0;
        }
        &:nth-child(3) .card-result {
          border-radius: 0 0 0 .8rem;
        }
      }

      &:last-child {
        border-radius: 0 .8rem .8rem 0;
        @media (max-width: $breakpoint-md) {
          border-radius: .8rem;
        }

        .card-result {
          border-radius: 0 .8rem .8rem 0;
          margin-left: 0;
          @media (max-width: $breakpoint-md) {
            border-radius: .8rem;
          }
        }
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
      @media (max-width: $breakpoint-md) {
        display: flex;
      }
    }
  }
}

.card-result {
  background-color: $neutre_gris_50;
  transition: all .3s;

  .card {
    transition: border-color .3s;

    &:hover {
      border-color: $principale;
    }
  }

  //@media (max-width: $breakpoint-xl) {
  //  padding: .5rem;
  //}
  @media (max-width: $breakpoint-md) {
    border-radius: 0 !important;
  }
}

.loader-resultats {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 1.6rem;

  &.hidden {
    display: none;
  }

  .text-loader {
    margin-top: 1.6rem;
  }
}

.card-result.card-result-tab {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  text-align: center;
  border-radius: 0;
  border-top: .7rem solid transparent;
  box-shadow: 0 .2rem 3.6rem 0 rgba(0, 0, 0, .06);

  &.active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100% + 1.4rem);
    position: relative;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-top-color: $principale;
    background-color: #fff;
    margin-top: -.7rem;
    border-left: none;
    border-right: none;
    @include box-shadow;
    z-index: 1;
    @media (max-width: $breakpoint-md) {
      margin-top: 0;
    }

    .h6 {
      font-size: 2.6rem;
      margin-bottom: 1.2rem;
      @media (max-width: $breakpoint-md) {
        margin-bottom: 0;
      }
      @media (max-width: $breakpoint-xs) {
        font-size: 2rem;
      }
    }

    .text-inactif {
      display: none;
    }

    .link-icon-chevron::after {
      content: none;
    }
  }

  .h6 {
    justify-content: center;
    width: 100%;
  }

  .result-item {
    display: flex;
    align-items: center;
    text-align: left;
    width: 100%;
    background-color: white;
    border: .1rem solid $neutre_gris_100;
    padding: 1rem;
    margin-top: 1rem;
    @media (max-width: $breakpoint-lg) {
      gap: .5rem;
    }

    img {
      margin-right: .8rem;
      aspect-ratio: 72/63;
      width: 100%;
      height: auto;
      max-width: 7.2rem;
      object-fit: contain;

      &.cover {
        object-fit: cover;
      }

      @media (max-width: $breakpoint-lg) {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .result-item-titre {
      font-weight: 600;
      font-size: 1.4rem;
      @include line-clamp(2);
    }

    .edition {
      font-size: $font-xxs;
      margin-top: .8rem;
    }
  }

  .link-element {
    margin-left: auto;
    margin-right: auto;

    @media (max-width: $breakpoint-md) {
      margin-bottom: .8rem;
    }

    .value {
      margin-right: .8rem;
    }

    .surligne {
      &::after {
        font-size: 1rem;
      }
    }
  }

  @media (max-width: $breakpoint-md) {
    .text-inactif {
      display: none !important;
    }
    .link-icon-chevron::after {
      content: none;
    }
    .h6 {
      font-size: 2.6rem;
      margin-top: .5rem;
    }
  }
  @media (max-width: $breakpoint-xs) {
    .h6 {
      font-size: 2rem;
    }
  }
}

.results-list {
  display: flex;
  flex-wrap: wrap;
  gap: 2.4rem;
  margin-top: 3.2rem;
  width: 100%;

  .card-result {
    .favorite {
      position: absolute;
      right: 1rem;
      bottom: 1.5rem;
      font-size: 2rem;
    }
  }
}

.results-list-produit,
#tab-produits .results-list {
  .card-result {
    width: calc(20% - 1.92rem);
  }

  @media (max-width: $breakpoint-lg) {
    gap: 2rem 1rem;
    .card-result {
      width: calc(25% - .8rem);
    }
    .card-product {
      width: 100%;
    }
  }

  @media (max-width: $breakpoint-md) {
    .card-result {
      width: calc(100% / 3 - .7rem);
    }
  }

  @media (max-width: $breakpoint-sm) {
    .card-result {
      width: calc(50% - .5rem);
    }
  }

  @media (max-width: 400px) {
    .card-result {
      width: 100%;
    }
  }
}

.results-list-article,
.results-list-collection,
.results-list-sc,
#tab-ressources-actus .results-list,
#tab-collections .results-list,
#tab-sites-compagnons .results-list {
  .card-result {
    width: calc(100% / 3 - 1.6rem);
  }

  @media (max-width: $breakpoint-lg) {
    gap: 2rem 1rem;

    .card-result {
      width: calc(50% - .5rem);
    }
  }

  @media (max-width: $breakpoint-sm) {
    .card-result {
      width: 100%;
    }
  }
}

/* Filtres */
.filters-list {
  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.6rem;
    margin-top: 3.4rem;
    padding: 0;
  }

  .more-filters-container {
    position: relative;
    display: flex;
    height: 4rem;
    margin-left: auto;
  }

  .more-filters {
    margin-left: auto;
    height: auto;
    padding: 0 1.8rem;

    &::after {
      content: "\FA36";
    }
  }

  .more-filters-reset {
    position: absolute;
    top: -1rem;
    right: 0;
    display: none;
    justify-content: center;
    align-items: center;
    width: 2.4rem;
    height: 2.4rem;
    font-size: 1rem;
    color: $principale;
    background-color: #fff;
    border: .1rem solid $principale;
    border-radius: 50%;
    cursor: pointer;
    transition: all .3s;

    &:hover {
      color: #fff;
      background-color: $principale;
    }
  }

  .select2 {
    width: 100% !important;
  }

  .nb-filtes-actifs {
    display: none;
    width: 3rem;
    font-size: 1.2rem;
    text-align: center;
    padding: .4rem .7rem;
    color: $principale;
    background-color: $neutre_gris_300;
    border-radius: 4.8rem;
    transition: background-color .3s;
  }

  .nb-filtes-actifs-principaux {
    position: absolute;
    right: 35px;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
  }
}

.filtres-actifs {
  display: flex;
  flex-wrap: wrap;
  gap: .6rem 1rem;
  margin-top: 1.6rem;
  padding-top: 1.6rem;
  border-top: .1rem solid $neutre_gris_300;

  .filtres-actifs-contenu {
    display: inline-flex;
    flex-wrap: wrap;
    gap: .4rem;

    &.hidden {
      display: none;
    }
  }

  .button-filtre {
    height: 4rem;
    padding: 0 1.8rem;
    cursor: default;

    &::after {
      content: none;
    }

    &:hover,
    &:focus {
      color: $cta;
      background-color: transparent;
      border: .1rem solid $cta;
    }

    .icon {
      transition: color .3s;
      cursor: pointer;

      &::before {
        font-size: 1.4rem;
      }

      &:hover {
        color: $rouge_nathan;
      }
    }
  }
}