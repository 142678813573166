.menu_hub.menu_hub_minisite {
  top: -6.5rem;
  margin-bottom: -2.6rem !important;
  transform: none;
  @media (max-width: $breakpoint-sm) {
    ~ #contenu {
      padding-top: 6rem;
    }
  }
  @media (max-width: $breakpoint-xs) {
    nav {
      width: 30rem;
    }
  }

  .slide-menu-hub {
    @media (min-width: $breakpoint-sm) {
      overflow: visible;
    }
  }

  .liste_menu_hub {
    @media (min-width: $breakpoint-sm) {
      gap: 2rem;
    }
    @media (max-width: $breakpoint-sm) {
      gap: 0;
      border: none;
    }

    li {
      @media (min-width: $breakpoint-sm) {
        width: unset;
      }
    }

    a {
      @media (max-width: $breakpoint-sm) {
        &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 1.3rem !important;
          display: block;
          background-color: $rouge_nathan;
          border-radius: 50rem;
          opacity: 0;
          width: 100%;
          height: .3rem;
          transform: none !important;
          transition: opacity .3s ease-in-out;
        }
      }

      &.current {
        @media (max-width: $breakpoint-sm) {
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }

  .swiper-button {
    @media (max-width: $breakpoint-sm) {
      display: flex !important;
    }
  }
}

.page-minisite {
  .h1 {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-top: 5.5rem;
    margin-bottom: 0;
    z-index: 1;

    img {
      max-width: 12rem;
      max-height: 4.5rem;
    }
  }
}