.bloc-ressources {
  background-color: $neutre_gris_50;
  border: $border-clear;
  border-radius: $radius-m;
  padding: 2.4rem;

  & > *:not(:last-child) {
    margin-bottom: 2.4rem;
  }

  .icon-souligne {
    &::after {
      bottom: 0;
      left: -.4rem;
      font-size: $font-s;
    }
  }

  .ressources {
    @include flex-between;
    width: 100%;
    padding: .8rem;
    border-radius: $radius-m;
    border: $border-clear;
    background: $blanc;
    @media (max-width: $breakpoint-xs) {
      flex-direction: column;
    }

    .ressource-col {
      display: flex;
      align-items: center;
      gap: 2.4rem;
    }

    .ressource-nom {
      margin-left: 2.4rem;
      margin-right: 1rem;
      font-weight: $weight-bold;
    }
  }
}