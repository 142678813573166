hr,
.sep {
  @include sep;
}

/* Séparation invisible CMS et BUILDER EditeurTexte */
.sep-vertical {
  display: inline-block;
  height: 2rem;
  width: .1rem;
  margin: 0 .8rem -.2rem;
  background-color: $principale;
}

.sep-horizontal {
  display: block;
  height: .1rem;
  width: 100%;
  background-color: #999;
}

hr.light,
.sep-horizontal-short {
  display: block;
  height: .1rem;
  width: 90%;
  min-width: 30rem;
  background-color: #999;
  margin: 3rem auto !important;
}

.sep-horizontal-mobile {
  display: none;
  width: 25rem;
  height: .1rem;
  background-color: #999;
  margin: 1.5rem auto 0;
  @media (max-width: $breakpoint-sm) {
    display: block;
  }
}

.grey-sep-full {
  height: .1rem;
  width: 100%;
  background-color: #999;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
