.page-404 {
  #contenu section {
    margin-bottom: 12rem;
  }

  .principale-404 {
    @include flex-center;

    @media (max-width: $breakpoint-sm) {
      flex-wrap: wrap;
    }

    .descriptions-404 {
      position: relative;
      background-color: $neutre_gris_50;
      border: $border-clear;
      border-radius: $radius-s;
      padding: 1.6rem;
      max-width: 50rem;
      min-width: 25rem;
      margin-right: -4rem;
      z-index: 1;
      @media (max-width: $breakpoint-md) {
        margin-right: -10rem;
      }
      @media (max-width: $breakpoint-sm) {
        margin-left: 4rem;
        margin-right: 4rem;
        margin-top: -4rem;
        order: 1;
      }
      @media (max-width: $breakpoint-sm) {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
      }

      & > *:not(:last-child) {
        margin-bottom: .8rem;
      }

      .description-1 {
        color: $rouge;
      }
    }

    img {
      max-width: 100%;
      max-height: 43rem;
      border-radius: $radius-s;
      object-fit: cover;
      margin-left: -4rem;
      @media (max-width: $breakpoint-sm) {
        margin-left: 0;
      }
    }
  }

  .recherche-404 {
    & > * {
      margin: 0 auto;
      text-align: center;
      justify-content: center;
    }

    .button {
      margin-top: .8rem;
    }
  }

  .decouverte-404 {
    .h6 {
      justify-content: center;
      padding-bottom: 1.6rem;
    }

    .liste-liens-404 {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      gap: 1.6rem;

      @media (max-width: $breakpoint-sm) {
        gap: .5rem;
      }

      .link-element {
        justify-content: space-between;
        border: $border-clear;
        border-radius: $radius-s;
        box-shadow: $box-shadow-alt-black;
        width: calc(100% / 3 - 1.6rem);
        padding: 1.6rem;
        font-weight: 700;

        @media (max-width: $breakpoint-sm) {
          width: calc(100% / 2 - .5rem);
          padding: 1.2rem;
        }

        @media (max-width: $breakpoint-xs) {
          width: 100%;
        }

        .surligne {
          right: 1rem;
          bottom: 1.1rem;
          font-size: 1rem;
        }
      }
    }
  }
}

