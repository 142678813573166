/* Page déclaration prescripteur */
.offre-prescripteur-contenu {
  .bloc-blanc {
    background-color: $neutre_gris_50;
  }

  .bloc-blanc-infos {
    display: flex;
    justify-content: space-between;
    padding: 1.6rem 3.2rem;
    @media (max-width: $breakpoint-xs) {
      flex-direction: column;
      gap: 2rem;
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: .6rem;
      flex: 1;
    }
  }

  .bloc-produit-prescripteur {
    display: flex;
    gap: 2.4rem;
    margin-bottom: 1.6rem;
  }

  .img-produit-prescripteur {
    width: fit-content;
    padding-left: 1.4rem;
    padding-right: 1.4rem;

    img {
      max-width: 13.5rem;
      aspect-ratio: 135/160;
      width: 100%;
      object-fit: contain;
    }
  }

  .produit-prescripteur-infos {
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
  }

  .produit-prescripteur-reserve {
    margin-bottom: 1.6rem;
  }

  .bloc-attestation-prescripteur {
    margin-top: 1rem;
    margin-bottom: 2.4rem;
    padding: 2.4rem;
    border: .1rem solid $principale;

    .h6 {
      text-align: center;
      text-transform: uppercase;
    }

    .sep {
      margin-top: 1.6rem;
      margin-bottom: 1.6rem;
    }

    .bloc-attestation-prescripteur-contenu {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
    }
  }

  .button {
    padding-left: 10rem;
    padding-right: 10rem;
  }
}

/* Page prescription enregistree, success */
.prescription-enregistree {
  .icon-check {
    font-size: 2.8rem;
    color: $enseignant;
    margin-right: .4rem;
  }
}

/* Popin ACA */
.popin-aca-c {
  .fancybox-bg {
    opacity: .5;
  }
}

#aca-modal {
  position: fixed;
  top: 50vh;
  left: 50vw;
  padding: 0;
  text-align: center;
  border-radius: .8rem;
  width: calc(100% - 2rem);
  max-width: 82rem;
  max-height: 72.6rem;
  transform: translate(-50%, -50%);

  @media (max-width: 575px) {
    max-height: 98vh;
  }

  @media (max-height: 700px) {
    max-height: 98vh;
  }

  .modal__overlay {
    overflow-y: auto;
  }

  .modal__close {
    position: absolute;
  }

  .modal__header,
  .modal__content > .acaTitle,
  .acaSplit > div:first-child {
    background-color: $neutre_beige_300;
  }

  .modal__header {
    padding: 1.5rem 5.6rem 1.6rem;
    @media (max-width: $breakpoint-xs) {
      padding: 1.6rem;
    }

    > p {
      display: flex;
      align-items: center;
      gap: .8rem;
      text-align: left;
      padding: 1.2rem 1.6rem;
      margin-top: 3rem;
      border-radius: .8rem;
      background-color: $neutre_gris_50;

      &::before {
        content: '\FD71';
        font-family: kfont;
        font-size: 4rem;
        margin-right: .6rem;
      }
    }
  }

  .modal__header--flex {
    display: flex;
    justify-content: space-between;

    > .acaButton--small:first-child {
      &::before {
        content: '\FA37';
        font-family: kfont;
        font-size: 1.6rem;
        transform: rotate(180deg);
        margin-right: 1rem;
      }
    }
  }

  .modal__content > .acaTitle {
    font-weight: 700;
    padding-left: .5rem;
    padding-right: .5rem;
    padding-top: 1.5rem;

    &::after {
      content: "";
      display: block;
      width: calc(100% - 11.2rem);
      height: .1rem;
      background-color: $neutre_gris_300;
      margin: 1.6rem auto 0;
    }
  }

  .acaForm__message--error {
    display: inline-flex;
    margin-bottom: .8rem;
    color: $danger;
    border: .1rem solid $danger;
    background-color: $danger_fond;
    border-radius: .4rem;
    padding: 1rem 5rem;
    margin-left: 5.6rem;
    margin-right: 5.6rem;

    @media (max-width: $breakpoint-xs) {
      margin-left: .6rem;
      margin-right: .6rem;
      padding: 1rem;
    }

    &::before {
      content: '\FA3B';
      font-family: kfont;
      font-size: 1.6rem;
      transform: rotate(180deg);
      margin-right: 1rem;
      margin-bottom: auto;
    }
  }

  .acaForm__message--success {
    display: inline-flex;
    color: $succes;
    border: .1rem solid $succes;
    background-color: $succes_fond;
    border-radius: .4rem;
    padding: 1rem 5rem;
    margin: 1rem 5.6rem .8rem;

    @media (max-width: $breakpoint-xs) {
      margin-left: .6rem;
      margin-right: .6rem;
      padding: 1rem;
    }

    &::before {
      content: '\FA2B';
      font-family: kfont;
      font-size: 1.6rem;
      margin-right: 1rem;
      margin-bottom: auto;
    }
  }

  .acaSplit {
    > div:first-child {
      padding-top: .5rem;
      padding-bottom: 1.6rem;
    }

    .acaForm__item .acaForm__item--automatic {
      display: flex;
      align-items: center;
      gap: 1.6rem;
      padding: 1.6rem 5.6rem;
      @media (max-width: $breakpoint-xs) {
        flex-direction: column;
        padding: 1.6rem;
      }

      > span {
        font-weight: 700;
        font-size: 2rem;
      }

      .acaForm__select,
      #academic_email_accountName {
        width: 50%;
        @media (max-width: $breakpoint-xs) {
          width: 100%;
        }
      }

      #academic_email_accountName,
      #academic_email_domainName {
        display: block;
        height: 5.5rem;
        background-color: #fff;
        border: .1rem solid #bbb;
        border-radius: .4rem;
        padding: .7rem 1.5rem .7rem 1.2rem;
        z-index: 1;
      }

      #academic_email_domainName {
        width: 100%;
      }
    }

    #professional_email_email {
      display: block;
      height: 5.5rem;
      background-color: #fff;
      border: .1rem solid #bbb;
      border-radius: .4rem;
      padding: .7rem 1.5rem .7rem 1.2rem;
      width: 40%;
      margin: 1.5rem auto;
      @media (max-width: $breakpoint-xs) {
        width: calc(100% - 3rem);
      }
    }

    #professional_email_save,
    #academic_email_save {
      position: relative;
      margin-bottom: 5rem;

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -2.6rem;
        width: 41.4rem;
        height: 4.8rem;
        padding: 0;
        transform: translateX(-50%);
        background-color: transparent;
        border: none;
        background-image: url('../img/theme/fake-btn-aca/cta-aca.png');
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
        @media (max-width: $breakpoint-xs) {
          width: 30rem;
        }
      }
    }

    .tUnderline {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    .acaSplit__separator {
      font-size: 1.8rem;
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 1.8rem;
      margin-bottom: 1.2rem;
    }
  }

  .acaForm--file {
    margin-top: 1.5rem;
    margin-bottom: .5rem;
  }

  .modal__content {
    padding-bottom: 1rem;

    > *:last-child {
      padding-bottom: 2.4rem;
    }

    .acaContentTrigger[data-url="/api/manual"],
    .acaContentTrigger[data-url="/api/certificate/wait"],
    .acaContentTrigger[data-url="/api/certificate"],
    .acaContentTrigger[data-url="/api/professional"],
    .acaButton[data-custom-close="data-custom-close"],
    #certificate_submit_save {
      position: relative;
      margin-bottom: 5rem;
      margin-top: 1.5rem;
      color: transparent;

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -2.6rem;
        width: 41.4rem;
        height: 4.8rem;
        padding: 0;
        transform: translateX(-50%);
        background-color: transparent;
        border: none;
        background-image: url('../img/theme/fake-btn-aca/autre-methode.png');
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
        @media (max-width: $breakpoint-xs) {
          width: 30rem;
        }
      }
    }

    .acaContentTrigger[data-url="/api/professional"] {
      display: block;
    }

    .acaContentTrigger[data-url="/api/manual"]::after {
      bottom: -1rem;
      background-image: url('../img/theme/fake-btn-aca/attestation.png');
    }

    .acaText, .acaText--small {
      padding: 0 1rem;
    }

    .acaText:has(.acaContentTrigger[data-url="/api/certificate/wait"]) {
      padding-bottom: 0;
      margin-bottom: 2rem;
      border-bottom: .1rem solid $neutre_gris_300;
      width: calc(100% - 11.2rem);
      margin-left: auto;
      margin-right: auto;
    }

    .acaContentTrigger[data-url="/api/certificate/wait"]::after {
      background-image: url('../img/theme/fake-btn-aca/dl-attestation.png');
    }

    .acaContentTrigger[data-url="/api/certificate"] {
      margin-bottom: 12rem;

      &::after {
        background-image: url('../img/theme/fake-btn-aca/textarea-dl-attestation.png');
        background-size: contain;
        background-repeat: no-repeat;
        width: 70.8rem;
        height: 13.7rem;
        bottom: -11.5rem;
        @media (max-width: $breakpoint-xs) {
          width: 30rem;
        }
      }
    }

    .acaButton[data-custom-close="data-custom-close"]::after {
      background-image: url('../img/theme/fake-btn-aca/poursuivre-nav.png');
      bottom: -.5rem;
    }

    #certificate_submit_save::after {
      background-image: url('../img/theme/fake-btn-aca/envoyer.png');
    }
  }

  .Utils--no-mb {
    padding: 1.6rem 5.6rem 2.4rem 5.6rem;
    @media (max-width: $breakpoint-xs) {
      padding: 1.6rem 1.6rem 2.4rem 1.6rem;
    }
  }
}