.textcenter {
  text-align: center;
}

.textright {
  text-align: right;
}

.textleft {
  text-align: left;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mr-2 {
  margin-right: .5rem;
}

.mb--12 {
  margin-bottom: -1.2rem;
}

#cd-main * {
  &::-webkit-scrollbar {
    width: .6rem !important;
  }
}

#cd-main * {
  &::-webkit-scrollbar-thumb {
    background: $neutre_gris_600 !important;
    border-radius: 50rem !important;
  }
}

.collapse-box {
  margin-bottom: 4rem;

  .collapse-item {
    margin: 1rem auto 1rem 2.5rem
  }
}

.min-height {
  min-height: 60rem;
  @include flex-center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mw-500 {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
}

.mw-650 {
  max-width: 65rem;
  margin-left: auto;
  margin-right: auto;
}

.mw-920 {
  max-width: 92rem;
  margin-left: auto;
  margin-right: auto;
}

.mw-1000 {
  max-width: 100rem;
  margin-left: auto;
  margin-right: auto;
}

.minw-160 {
  min-width: 16rem;
}

.minw-180 {
  min-width: 18rem;
}

.minw-220 {
  min-width: 22rem;
}

.minw-235 {
  min-width: 23.5rem;
}

.bold {
  font-weight: 700;
}

.small {
  font-size: $font-xxs;
}

.desktop {
  display: block;
  @media (max-width: $breakpoint-sm) {
    display: none;
  }
}

.tablette {
  display: none;
  @media (max-width: $breakpoint-lg) {
    display: block;
  }
}

.mobile {
  display: none;
  @media (max-width: $breakpoint-sm) {
    display: block;
  }

  & * {
    display: none;
    @media (max-width: $breakpoint-sm) {
      display: block;
    }
  }
}

sup {
  top: -1rem;
  font-size: 70%;
}

sub {
  bottom: -.5rem;
  font-size: 70%;
}

#contenu section:not(.hero) {
  margin-bottom: 12rem;
  @media (max-width: $breakpoint-md) {
    margin-bottom: 8rem;
  }
  @media (max-width: $breakpoint-sm) {
    margin-bottom: 6rem;
  }
}

#contenu .grid-builder {
  .grid-builder-content {
    margin-bottom: 8.8rem;
    @media (max-width: $breakpoint-sm) {
      margin-bottom: 5.6rem;
    }

    .col {
      & > *:not(:last-child) {
        margin-bottom: 3.2rem;
        @media (max-width: $breakpoint-sm) {
          margin-bottom: 2.4rem;
        }
      }

      section {
        margin-bottom: 0;
      }
    }
  }
}

p {
  margin-bottom: 0;
}

.grey-block {
  background-color: #999;
  padding: 2rem;
}

.abs-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.btn-border-none {
  border: none;
}

/* Toast */
.alert-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;

  .alert {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    color: $blanc;

    &.alert-danger {
      background-color: #f35958;
    }

    &.alert-success {
      color: #155724;
      background-color: #d4edda;
    }
  }
}

/* Bloc quantité */
.qt-block {
  display: flex;
  align-items: center;
  text-align: center;

  .qt-manage,
  .input-qty-cart {
    display: inline-block;
    height: 4.8rem;
    width: 2rem;
    border: .1rem solid #999;
  }

  input.input-qty-cart {
    position: relative;
    width: 6rem;
    text-align: center;
    font-size: 2.2rem;
    padding-left: 0;
    padding-bottom: .4rem;
  }

  .qt-manage {
    @include flex-center;
    width: 3rem;
    color: $principale;
    background-color: $blanc;
    font-size: 3.5rem;
    font-weight: 400;
    cursor: pointer;
    transition: color .3s ease-in-out;

    &:hover {
      color: $cta;
    }

    &.less-qt {
      margin-right: -.1rem;

      span {
        font-size: 4rem;
        margin-top: -.8rem;
      }
    }

    &.more-qt {
      margin-left: -.1rem;
    }
  }
}

/* Pastille stock */
.stock {
  @include flex-center;
  font-size: 1.4rem;
  font-weight: 700;
  color: #999;

  > span {
    line-height: 0;
  }

  .icon-stock {
    display: inline-block;
    width: 1.4rem;
    height: 1.4rem;
    margin-left: 1rem;
    background-color: #7EBC00;
    border-radius: 50%;

    &.icon-outstock {
      background-color: #B20C0C;
    }
  }
}

/* Prix barré */
.prixbarre {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: .1rem;
    background-color: #333;
  }
}

// Permet de garder le ratio.
// Pour changer le ratio (carré par défaut), ajouter sur l'élément container avec la classe ratio un style="--ratio: 16/9"
.ratio {
  position: relative;
}

.ratio::before {
  content: '';
  display: block;
  padding-bottom: calc(100% / (var(--ratio, calc(1))));
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-size: contain;
}

.ratio.cover > * {
  object-fit: cover;
  background-size: cover;
}

/* cf mixin.scss */
.absolute-container {
  @include absolute-container;
}

.flex-center {
  @include flex-center;
}

.flex-between {
  @include flex-between;
}

.flex-around {
  @include flex-around;
}

.cover-img {
  @include cover-img;
}

.box-shadow-strong {
  @include box-shadow;
}

.box-shadow-light {
  @include box-shadow-light;
}

/* chemin img */
/* # {$base-img-url} */
$base-img-url: "../img/theme";

/*@media (min-width: $breakpoint-xl) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: $breakpoint-xl;
  }
}
@media (min-width: $breakpoint-xxl) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: $breakpoint-xxl;
  }
}*/

p:empty {
  display: none !important;
}

.icon {
  line-height: 0;
}

a {
  display: inline-flex;
}

/* Montrer / Masquer le mot de passe */

.mdp-bloc {
  position: relative;

  .eye-mdp {
    position: absolute;
    top: 3.5rem;
    right: 1rem;
    font-size: 3.5rem;
    line-height: 1;
    z-index: 9;
    cursor: pointer;
    transition: opacity .3s;

    &:hover {
      opacity: .6;
    }
  }

  .hide-mdp {
    display: none;
  }
}


/* Popin seo */
.popin-seo-box {
  max-width: 90rem;
  padding: 4rem 3rem;
  border-radius: .8rem;
  border: .1rem solid $neutre_gris_300;
  background-color: $neutre_bleu_100;
}

.vue-enseignant {
  .toggle-color {
    color: $enseignant !important;
  }

  .toggle-color-before {
    &::before {
      color: $enseignant !important;
    }
  }

  .toggle-color-after {
    &::after {
      color: $enseignant !important;
    }
  }

  .toggle-background {
    background-color: $enseignant !important;
  }

  .toggle-background-before {
    &::before {
      background-color: $enseignant !important;
    }
  }

  .toggle-background-after {
    &::after {
      background-color: $enseignant !important;
    }
  }

  .toggle-background-image-sm-before {
    &::before {
      background-image: url('../img/theme/graphiti/graphiti_small_vert.svg') !important;
    }
  }

  .toggle-background-image-xl-before {
    &::before {
      background-image: url('../img/theme/graphiti/graphiti_serre_vert.svg') !important;
    }
  }

  .toggle-border {
    border-color: $enseignant !important;
  }
}

.vue-gp {
  .toggle-color {
    color: $grand_public !important;
  }

  .toggle-color-before {
    &::before {
      color: $grand_public !important;
    }
  }

  .toggle-color-after {
    &::after {
      color: $grand_public !important;
    }
  }

  .toggle-background {
    background-color: $grand_public !important;
  }

  .toggle-background-before {
    &::before {
      background-color: $grand_public !important;
    }
  }

  .toggle-background-after {
    &::after {
      background-color: $grand_public !important;
    }
  }

  .toggle-background-image-sm-before {
    &::before {
      background-image: url('../img/theme/graphiti/graphiti_small_corail.svg') !important;
    }
  }

  .toggle-background-image-xl-before {
    &::before {
      background-image: url('../img/theme/graphiti/graphiti_serre_corail.svg') !important;
    }
  }

  .toggle-border {
    border-color: $grand_public !important;
  }
}

strong {
  font-weight: 700 !important;
}

.loader-nathan video {
  width: 25rem;
}

.icon::before {
  font-weight: 400;
}

.block-connexion {
  .obligatoire {
    font-size: $font-xxs;
    margin-bottom: .8rem;
  }
}