.inscription {
  display: flex;
  justify-content: center;
  gap: 10rem;
  background-color: white;
  border: $border-clear;
  border-radius: $radius-ml;

  max-height: 44rem;
  overflow: hidden;
  @media (max-width: $breakpoint-xl) {
    gap: 7rem;
  }
  @media (max-width: $breakpoint-lg) {
    flex-direction: column;
    max-height: unset;
    gap: 4.8rem;
  }

  .contenu-inscription {
    @include flex-between;
    flex-direction: column;
    gap: 2.4rem;
    text-align: center;
    padding: 4rem 0 4rem 4rem;
    @media (max-width: $breakpoint-xl) {
      padding: 4rem 4rem 0 4rem;
    }
    @media (max-width: $breakpoint-sm) {
      align-items: flex-start;
      text-align: left;
      padding: 4rem 2rem 0 2rem;
    }

    .titre-inscription {
      @include flex-center;
      gap: 1.6rem;

      .logo-inscription {
        width: 12.5rem;
        height: auto;
        @media (max-width: $breakpoint-xs) {
          width: 10rem;
        }
      }
    }

    .liens-inscription {
      @include flex-center;
      flex-direction: column;
      gap: 1rem;
      @media (max-width: $breakpoint-sm) {
        margin: 0 auto;
      }
    }
  }

  .avantages-inscription {
    display: flex;
    background-color: $blanc;
    border-radius: $radius-ml;
    width: 72%;
    @media (max-width: $breakpoint-xl) {
      padding: 4rem 0;
    }
    @media (max-width: $breakpoint-lg) {
      width: 100%;
    }

    .box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 7.2rem 1.6rem;
      margin: auto;
      max-width: 42rem;
      padding: 4rem 0;
      @media (max-width: $breakpoint-md) {
        gap: 3.2rem 1.6rem;
      }
      @media (max-width: $breakpoint-xs) {
        flex-direction: column;
        gap: 3.2rem;
      }

      .groupe-inscription {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 1.6rem;
        width: calc(50% - .8rem);
        text-align: center;

        & > * {
          max-width: 18rem;
        }

        .groupe-picto-inscription {
          img {
            height: 4rem;
            width: auto;
          }
        }
      }
    }
  }
}