.bloc-bio-interview {
  display: flex;
  justify-content: space-between;
  gap: 6rem;
  background-color: white;
  border: $border-clear;
  border-radius: $radius-ml;
  padding: 4rem;
  overflow: hidden;
  @media (max-width: $breakpoint-md) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 4rem;
    padding: 4rem 2rem;
  }

  .left-col {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.6rem;
    min-width: 28rem;

    h3 {
      text-align: center;
    }
  }

  .right-col {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }
}