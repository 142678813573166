.page-contact #contenu {
  margin-top: 3rem;
  margin-bottom: 3rem;

  .h3 {
    margin-bottom: 1.5rem;
  }

  .h4{
    .icon{
      font-size: 4rem;
      margin-right: .8rem;
    }
  }

  .required-label {
    font-size: 1.2rem;
    margin-top: 1rem;

    .icon {
      font-size: 3rem;
      line-height: 1;
    }
  }

  button[type='submit'] {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }

  .bloc-contact {
    margin-bottom: 2rem;
  }

  #map {
    min-height: 50rem;
  }
}
