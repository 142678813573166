.autocomplete-search-visible {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 0;
    @media (max-width: $breakpoint-xs) {
      content: none;
    }
  }

  #header {
    z-index: 10004;
    width: calc(100% + 1.7rem);
    padding-right: 1.7rem;
    @media (max-width: $breakpoint-lg) {
      width: 100%;
    }
    @media (max-width: $breakpoint-xs) {
      padding-right: 0;
    }
  }

  .panneau-recherche-c {
    z-index: 10003;
  }
}

.pages-container {
  margin-bottom: 4rem;
  @media (max-width: $breakpoint-md) {
    margin-bottom: 2rem;
  }
}

.panneau-recherche-c {
  position: absolute;
  top: 13.6rem;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 10;
  transform: translateY(-100%);
  opacity: 0;
  height: fit-content;
  max-height: calc(100vh - 13.6rem);
  overflow-y: scroll;
  transition: transform .5s ease-in-out, opacity .5s ease-in-out;
  @media (max-width: $breakpoint-md) {
    top: 12.6rem;
    max-height: calc(100vh - 12.6rem);
  }
  @media (max-width: $breakpoint-xs) {
    display: none;
  }
}

.panneau-recherche {
  padding: 4.8rem 6rem 4.8rem 10rem;
  border-bottom: .1rem solid $neutre_gris_300;
  @media (max-width: $breakpoint-xl) {
    padding: 3rem 2rem 1.5rem 2rem;
  }
  @media (max-width: $breakpoint-md) {
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  }

  .panneau-recherche-contenu {
    display: flex;
    gap: 4rem;
    @media (max-width: $breakpoint-xl) {
      gap: 2rem;
    }

    .card-product {
      .tag-element {
        top: 1rem;
      }

      .img-card-c img.cover {
        height: auto;
      }
    }
  }

  .h6 {
    margin-bottom: 1rem;
  }

  .col-left {
    width: 60%;

    .list-5-cards {
      gap: 1rem;

      .card {
        width: calc(100% / 5 - 1rem);
        height: auto;
        @media (max-width: $breakpoint-lg) {
          width: calc(25% - 1rem);
          &:nth-child(5) {
            display: none;
          }
        }
        @media (max-width: $breakpoint-md) {
          width: calc(100% / 3 - 1rem);
          &:nth-child(4) {
            display: none;
          }
        }
        @media (max-width: $breakpoint-sm) {
          width: calc(50% - 1rem);
          &:nth-child(3) {
            display: none;
          }
        }
      }
    }
  }

  .col-right {
    display: flex;
    width: 40%;
    gap: 4rem;
    @media (max-width: $breakpoint-xl) {
      gap: 2rem;
    }
    @media (max-width: $breakpoint-md) {
      flex-wrap: wrap;
    }

    .links-container {
      width: 50%;
      @media (max-width: $breakpoint-md) {
        width: 100%;
      }
    }

    .link-list {
      margin-bottom: 4rem;

      a:hover {
        text-decoration: underline;
      }

      @media (max-width: $breakpoint-md) {
        margin-bottom: 2rem;
      }
    }

    .lien-recherche {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;

      &:hover {
        text-decoration: underline;
      }
    }

    button {
      padding: .8rem 1rem;
      font-size: 1.5rem;
    }
  }
}

.links-tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;

  .button {
    padding: 1rem 1.2rem;
    height: unset;
  }

  @media (max-width: $breakpoint-md) {
    gap: .4rem;
    .button {
      font-size: 1.4rem;
      gap: .4rem;
      height: 4.2rem;
      padding: 1.05rem .9rem;
    }
  }
}
