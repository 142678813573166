.menu_hub {
  position: sticky;
  top: 4.6rem;
  z-index: 99;
  @include flex-center;
  gap: 1.6rem;
  margin-bottom: 2.8rem !important;
  transform: translateY(-50%);

  @media (max-width: $breakpoint-sm) {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .fav-button {
    @include flex-center;
    width: 5.6rem;
    min-width: 5.6rem;
    height: 5.6rem;
    border: $border-clear;

    .icon::before {
      font-size: 2rem;
    }
  }

  @media (max-width: $breakpoint-sm) {
    nav {
      display: inline-block;
      width: 32rem;
    }
  }

  @media (max-width: $breakpoint-xs) {
    gap: 1rem;
    nav {
      width: 22rem;
    }
  }

  #slider-nav-hub-mobile {
    display: none;
    @media (max-width: $breakpoint-sm) {
      display: block;
    }
  }

  .liste_menu_hub {
    @include flex-between;
    gap: 4rem;
    background-color: $blanc;
    border: $border-clear;
    border-radius: 50rem;
    padding: 0 3.2rem;
    margin: 0;

    .swiper-button {
      display: none;
    }

    @media (max-width: $breakpoint-md) {
      gap: 2rem;
      padding: 0 1.8rem;
    }
  }

  li {
    height: 100%;
    list-style: none;
    margin: 0;
    width: 100%;

    &.home_menu_hub {
      a {
        &::after {
          width: 80%;
          left: .4rem;
          bottom: 1rem;
          transform: rotate(-6deg);
        }

        .mobile-only {
          display: none;
          @media (max-width: $breakpoint-sm) {
            display: block;
          }
        }

        .icon {
          @media (max-width: $breakpoint-sm) {
            display: none;
          }

          &::before {
            display: flex;
            justify-content: center;
            width: 2.8rem;
            font-size: 2rem;
          }
        }
      }
    }

    a {
      position: relative;
      display: inline-flex;
      align-items: center;
      height: 100%;
      padding: 1.7rem 0;
      line-height: normal;
      text-wrap: nowrap;
      gap: .8rem;

      .icon {
        font-size: 1rem;
        transition: transform .3s;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 1.3rem;
        display: block;
        background-color: $rouge_nathan;
        border-radius: 50rem;
        opacity: 0;
        height: .3rem;
        transition: opacity .3s ease-in-out;
      }

      &:hover, &[aria-current="page"], &.current {
        &::after {
          opacity: 1;
        }
      }
    }
  }

  li {
    position: relative;

    &.smenu-open {
      a {
        &::after {
          opacity: 1;
          background-color: red;
        }
        .icon {
          transform: rotate(90deg);
        }
      }
    }
  }

  .slide-menu-hub .swiper-button {
    display: none;
  }

  @media (max-width: $breakpoint-sm) {
    .slide-menu-hub {
      display: block;
      text-align: center;
      background-color: $blanc;
      border: $border-clear;
      border-radius: 50rem;
      padding: 0;

      ul {
        margin-bottom: 0;
        padding-left: 0;
      }

      .swiper-button {
        display: block;
      }

      .swiper-button-prev::after,
      .swiper-button-next::after {
        font-family: kfont;
        font-size: 1.8rem;
      }

      .swiper-button-prev::after {
        content: "\EA25";
      }

      .swiper-button-next::after {
        content: "\EA26";
      }
    }
  }
}

.menu_hub_level2 {
  display: block;
  position: absolute;
  top: 6.4rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  box-shadow: 0 .2rem 2.8rem 0 rgba(0, 0, 0, .10);
  border: 1px solid $neutre_gris_300;
  border-radius: .8rem;
  padding: 1.6rem 1.6rem 0;
  opacity: 0;
  visibility: hidden;

  @media (max-width: $breakpoint-sm) {
    max-height: 25rem;
    overflow: auto;
  }

  li a {
    padding-top: 0;
  }

  &:has(.menu_hub_level3) {
    display: flex;
    gap: 2rem;
    width: 105rem;
    padding: 2.4rem 2.4rem 1rem;
    @media (max-width: $breakpoint-md) {
      flex-wrap: wrap;
      max-width: calc(100% - 3rem);
    }
    @media (max-width: $breakpoint-sm) {
      width: fit-content;
      padding: 1.2rem 1.4rem;
    }
    > li > a {
      font-size: 1.8rem;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

.menu_hub_level3 {
  padding-left: 0;
}

.liste_smenu_hub.smenu-open {
  .menu_hub_level2 {
    opacity: 1;
    visibility: visible;
  }
}

.page-hub .menu_hub .liste_menu_hub {
  @media (max-width: $breakpoint-sm) {
    display: none;
  }
}
