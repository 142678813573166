.collection {
  display: flex;
  justify-content: space-between;
  gap: 6rem;
  background-color: white;
  border: $border-clear;
  border-radius: $radius-ml;
  padding: 0 4rem;
  overflow: hidden;
  @media (max-width: $breakpoint-lg) {
    flex-direction: column-reverse;
    justify-content: flex-end;
    gap: 0;
  }
  @media (max-width: $breakpoint-sm) {
    padding: 0 2rem;
  }

  .contenu-collection {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1.6rem;
    padding: 4rem 0;

    &:has(h2.graphiti-small) {
      padding-left: 6rem;
      @media (max-width: $breakpoint-sm) {
        padding-left: 0;
      }
    }
  }
}
