.page-hub {
  .hero ~ #contenu {
    padding-top: 6rem;
    margin-top: -8rem;
    margin-bottom: 5rem;

    @media (max-width: 767px) {
      padding-top: 4rem;
    }
  }
}
