
.log-error {
  text-align: left;
  color: #d8000c;
  font-weight: 700;

  li {
    line-height: 2rem;
    margin: .5rem 0;
  }

  .picto {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    margin-right: .5rem;
    text-align: center;
    border: .1rem solid #d8000c;
    border-radius: 50%;
  }
}

.error-border {
  border: .2rem solid #d8000c !important;
}

.error {
  display: inline-block;
  color: $blanc;
  background-color: #a72430;
  margin: 1rem 0;
  padding: .8rem;
}

.success {
  display: inline-block;
  color: $noir;
  background-color: #00b229;
  margin: 1rem 0;
  padding: .8rem;
}

input[type="submit"],
.button[type="submit"] {
  cursor: pointer;
}

/* Focus effect et largeur sur les inputs + validation des champs */
.custom-form {
  .formline{
    position: relative;
    text-align: left;
    margin-left: auto;
    margin-bottom: 3rem;
    margin-right: auto;
  }
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="number"],
  input[type="date"],
  input[type="url"],
  select {
    display: block;
    height: 5.5rem;
    width: 100%;
    border: .1rem solid #bbb;
    border-radius: .4rem;
    padding: 1.5rem 1.5rem .7rem 1.2rem;
    z-index: 1;

    &:disabled {
      border-color: #eee;
    }

    &:focus {
      border-color: #a4afba;
      outline: none;
      box-shadow: transparent;
    }

    &.valid {
      box-shadow: 0 0 .15rem .1rem green;
    }

    &.invalid {
      box-shadow: 0 0 .15rem .1rem #781629;
    }
  }

  input[type="file"]{
    max-width: 100%;
  }

  input[type="date"]{
    background-color: #fff;
    padding: 1.2rem 1.5rem 1.1rem 1.2rem;
  }

  input[type="checkbox"],
  input[type="radio"]{
    cursor: pointer;
  }

  input[type="checkbox"] ~ label,
  input[type="radio"] ~ label {
    margin-left: .8rem;
    cursor: pointer;
    line-height: 1.3;
  }

  select {
    padding-top: .7rem;
    color: #888;
    background-color: $blanc;

    &.valid{
      color: #434447;
    }
    option {
      color: #434447;
      &:disabled{
        color: #888;
      }
    }
  }

  textarea {
    display: block;
    width: 100%;
    border: .1rem solid #bbb;
    border-radius: .4rem;
    padding: 1.5rem 1.5rem .7rem 1.2rem;
    resize: none;
    height: 15rem;
    margin-top: 1rem;
  }

  .icon {
    margin-right: .6rem;
  }

  .focus-effect {
    padding: .7rem 1.5rem;
    border: .1rem solid #999;
    position: relative;
    background: transparent;
  }

  .focus-effect ~ label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 5.5rem;
    width: 100%;
    border-radius: .4rem;
    background-color: $blanc;
    margin-bottom: 0;
    padding-left: 1.2rem;
    padding-top: 1.6rem;
    transition: all .2s linear;
    z-index: 0;
    letter-spacing: .5px;
  }

  .focus-effect:focus ~ label,
  .has-content.focus-effect ~ label {
    top: 0;
    font-size: 11px;
    color: #888;
    padding-top: .2rem;
    transition: all .2s linear;
  }

  em.valid,
  em.invalid,
  .error-form {
    //position: absolute;
    //left: 0;
    //bottom: -1.8rem;
    display: block;
    margin-top: .2rem;
    padding-left: 1.2rem;
    font-size: 1.2rem;
    font-family: 'OpenSans', sans-serif;
    font-style: italic;
  }

  .error-form,
  em.invalid {
    color: #781629;
  }

  em.invalid + em.valid {
    display: none;
  }

}

////////////////////////////////
//// Checkbox personnalisée ////
////////////////////////////////
.checkbox-custom {
  position: relative;
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
  }
  label {
    position: relative;
    padding-left: 2.5rem;
    cursor: pointer;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      font-size: 1.4rem;
      transform: translateY(-50%);
      width: 1.8rem;
      height: 1.8rem;
      color: transparent;
      background-color: $blanc;
      border: .1rem solid #bbb;
      padding-left: .1rem;
      line-height: 1.2;
    }
  }
  input[type="checkbox"]:checked ~ label{
    &::before {
      content: '\EA29';
      font-family: kfont;
      color: $principale;
    }
  }
  input[type="checkbox"]:disabled ~ label{
    cursor: default;
    &::before {
      color: #999;
      border-color: #999;
      background-color: #999;
    }
  }
  em{
    position: absolute;
    bottom: -1.6rem;
  }
}

////////////////////////////
//// Radio personnalisé ////
////////////////////////////
.radio-custom {
  position: relative;
  &.radio-inline{
    display: flex;
  }
  input[type="radio"] {
    position: absolute;
    opacity: 0;
  }
  label {
    position: relative;
    padding-left: 2.5rem;
    cursor: pointer;
    &::before {
      content: '';
      display: block;
      width: 1.8rem;
      height: 1.8rem;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background-color: #fff;
      border: .1rem solid #bbb;
      border-radius: 50%;
      padding-left: .2rem;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: .3rem;
      transform: translateY(-50%);
      border-radius: 50%;
      width: 1.2rem;
      height: 1.2rem;
      border: .1rem solid transparent;
      padding-left: .2rem;
    }
  }

  input[type="radio"]:checked ~ label{
    color: $principale;
    &::before{
      border-color: $principale;
    }
    &::after {
      background-color: $principale;
    }
  }
  em{
    position: absolute;
    bottom: -1.6rem;
  }
}

/////////////////////////////
//// select personnalisé ////
/////////////////////////////
.select-custom {
  position: relative;
  &::after {
    content: '\FA2C';
    font-family: 'kfont';
    font-size: 1.4rem;
    display: block;
    position: absolute;
    top: 50%;
    right: 1.8rem;
    pointer-events: none;
    transform: rotate(90deg) translateX(-65%);
  }
  select {
    background-color: #fff;
    padding: 1rem 4rem 1rem 1.4rem;
    appearance: none;
    cursor: pointer;
  }
}

