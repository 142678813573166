.recherche_carrefour {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70rem;
  font-size: $font-m;
  background-color: $blanc;
  border: $border-clear;
  border-radius: 50rem;
  padding: .8rem .8rem .8rem 3rem;
  z-index: 99;
  @media (max-width: $breakpoint-sm) {
    position: static;
    transform: unset;
    width: unset;
    margin: 0 1.6rem 3.2rem 1.6rem !important;
    border-radius: $radius-s;
    padding: 1.2rem;
  }

  .form_carrefour {
    @include flex-between;
    gap: 2rem;

    @media (max-width: $breakpoint-md) {
      gap: 2rem;
    }

    @media (max-width: $breakpoint-sm) {
      gap: 1.2rem;
      flex-direction: column;
    }

    .flex-select-carrefour {
      @include flex-between;
      gap: .8rem;
      @media (max-width: $breakpoint-xs) {
        flex-direction: column;
        width: 100%;
        gap: 1.2rem;
      }

      label {
        flex-shrink: 0;
      }

      .custom-select {
        width: unset;
        height: 100%;
        position: relative;
        z-index: 999;
        @media (max-width: $breakpoint-xs) {
          width: 100%;
        }

        &::before {
          content: "\FA2C";
          display: inline-block;
          font-family: kfont;
          position: absolute;
          right: 1.4rem;
          top: 50%;
          font-size: 1.2rem;
          color: #283244;
          transform: rotate(-.75turn) translateX(-50%);
          transition: color .3s ease-in-out;
        }

        .input-element {
          @include flex-between;
          @include line-clamp(2);
          gap: 4rem;
          font-size: $font-m;
          background-color: $neutre_bleu_100;
          border-radius: 50rem;
          //height: 4.8rem;
          min-width: 22rem;
          //padding-top: .7rem;
          //padding-bottom: .7rem;

          &::after {
            content: none;
            position: absolute;
            right: 1.4rem;
            top: 2.1rem;
          }
        }

        .select-items {
          max-height: 20rem;
          overflow-y: auto;

          &::-webkit-scrollbar {
            border-radius: $radius-s;
            width: .4rem;
          }

          &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 50rem;
          }

          .option-item {
            font-size: 1.6rem;
          }
        }
      }
    }

    .button {
      height: 4.8rem;
      width: fit-content;
      @media (max-width: $breakpoint-xs) {
        width: 100%;
      }
    }
  }
}