img.image-circle{
  display: block;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;

  &.image-circle-md{
    width: 5.6rem;
    height: 5.6rem;
  }

  &.image-circle-lg{
    width: 12.8rem;
    height: 12.8rem;
  }

  &.image-circle-xl{
    width: 16rem;
    height: 16rem;
  }
}