.link-element {
  position: relative;
  @include flex-center;
  gap: 1rem;
  width: fit-content;
  color: $principale;
  background-color: unset;
  border: none;
  font-size: $font-s;
  font-weight: $weight-medium;
  padding: 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline $principale;

    &::after {
      transform: translateX(.3rem);
    }
  }

  &::before, &::after {
    font-weight: $weight-normal;
    transition: transform .3s ease-in-out;
    z-index: 1;
  }

  &.link-bold {
    font-weight: $weight-bold;
  }

  &.link-disabled {
    color: #999;
    cursor: default;
    pointer-events: none;

    &:hover {
      text-decoration: none;
    }
  }

  &.link-reseaux {
    width: 5rem;
    height: 5rem;
    border: $border-dashed;
    border-radius: 50rem;
    overflow: hidden;
    transition: background-color .3s, border .3s ease-in-out;

    .icon {
      @include flex-center;

      &::before {
        color: $principale;
        font-size: 2.4rem;
        padding-left: .1rem;
        transition: color .2s ease-in-out;
      }

      &.icon-gsn-instagram {
        &::before {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
        }

        &::after {
          content: "";
          width: 5.2rem;
          height: 5.2rem;
          background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
          opacity: 0;
          transition: opacity .3s ease-in-out;
        }
      }

      &.icon-gsn-x, &.icon-gsn-youtube {
        &::before {
          font-size: 2rem;
        }
      }
    }

    &:hover {
      border: $border-invisible;
      text-decoration: none;

      &:has(.icon-gsn-facebook) {
        background-color: #1877F2;

        .icon::before {
          color: $blanc;
        }
      }

      &:has(.icon-gsn-x), &:has(.icon-gsn-tiktok) {
        background-color: #000;

        .icon::before {
          color: $blanc;
        }
      }

      &:has(.icon-gsn-linkedin) {
        background-color: #2868B2;

        .icon::before {
          color: $blanc;
        }
      }

      &:has(.icon-gsn-pinterest) {
        background-color: #E60023;

        .icon::before {
          color: $blanc;
        }
      }

      &:has(.icon-gsn-youtube) {
        background-color: #FF0000;

        .icon::before {
          color: $blanc;
        }
      }

      &:has(.icon-gsn-instagram) {
        .icon {
          position: relative;
          width: 5.2rem;
          height: 5.2rem;

          &::after {
            opacity: 1;
          }

          &::before {
            color: $blanc;
          }
        }

      }
    }
  }

  &.link-icon-fleche {
    &:hover {
      text-decoration: none;
    }

    &::after {
      content: "\FA37";
      font-family: kfont;
      color: $principale;
      line-height: 0;
    }
  }

  &.link-icon-fleche-before {
    &:hover {
      text-decoration: none;
    }

    &::before {
      content: "\FC37";
      font-family: kfont;
      color: $principale;
    }
  }

  &.link-icon-marque-page-before {
    &:hover {
      text-decoration: none;
    }

    &::before {
      content: "\EDA3";
      font-family: kfont;
      color: $principale;
    }
  }

  &.link-icon-infos-before {
    &:hover {
      text-decoration: none;
    }

    &::before {
      content: "\FA3C";
      font-family: kfont;
      font-size: 1.8rem;
      color: $principale;
    }
  }

  &.link-icon-fav-before {
    &:hover {
      text-decoration: none;
    }

    &::before {
      content: "\FA34";
      font-family: kfont;
      color: $principale;
    }
  }

  &.link-icon-ressource-before {
    &:hover {
      text-decoration: none;
    }

    &::before {
      content: "\FC3C";
      font-family: kfont;
      color: $principale;
    }
  }

  &.link-icon-commande-before {
    &:hover {
      text-decoration: none;
    }

    &::before {
      content: "\FA2D";
      font-family: kfont;
      color: $principale;
    }
  }

  &.link-icon-contact-before {
    &:hover {
      text-decoration: none;
    }

    &::before {
      content: "\FA2F";
      font-family: kfont;
      color: $principale;
    }
  }


  &.link-icon-chevron {
    &:hover {
      text-decoration: none;
    }

    &::after {
      content: "\FA2C";
      font-family: kfont;
      color: $principale;
      font-size: 1.4rem;
    }
  }

  &.link-icon-chevron {
    &:hover {
      text-decoration: none;
    }

    &::after {
      content: "\FA2C";
      font-family: kfont;
      color: $principale;
      font-size: 1.4rem;
    }
  }
}