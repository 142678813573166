.page-catalogue {
  .img-category-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    img {
      object-fit: contain;
    }
  }

  .h1 {
    font-size: 2.9rem;
    margin-bottom: 2rem;
  }

  .h2 {
    text-align: center;
    font-size: 2.8rem;
  }

  .desc-category-container {
    max-width: 80rem;
    text-align: center;
    color: $cta;
    line-height: 1.8;
    margin-left: auto;
    margin-right: auto;
  }
}

.listing-catalogue-c {
  padding-top: 4rem;
  padding-bottom: 7rem;
  margin-top: 3rem;
}

/* Colonne de gauche */
.filtres-col {
  .h2 {
    color: $maison_nathan;
    font-size: 2.2rem;
    font-weight: 800;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 1.5rem;
    margin-left: -1rem;
  }

  label {
    cursor: pointer;
  }

  hr {
    display: block;
    background-color: $maison_nathan;
    height: .1rem;
    width: calc(100% + 1rem);
    margin-left: -1rem;
    margin-top: 0;
    margin-bottom: 0;
    border: none;

    &.light {
      background-color: $enseignant;
      margin-bottom: .5rem;
    }
  }

  ul {
    list-style-type: none;
    padding-left: 1rem;
  }

  .filter-selection {
    input {
      margin-right: .5rem;
    }

    label {
      color: $cta;
    }

    input:checked + label {
      color: $principale;
    }
  }

  .filter-libelle-c {
    @include flex-between;
    padding-top: 1rem;
    padding-bottom: .5rem;
    font-size: 1.4rem;
    text-transform: uppercase;
    color: $principale;
    font-weight: 600;
    cursor: pointer;
    transition: color .3s ease-in-out;

    .icon {
      font-size: 2.5rem;
      margin-right: .5rem;
    }

    &:hover {
      color: $cta;
    }
  }

  .collapse-block.see-more {
    display: block;
    cursor: pointer;
    transition: transform .3s ease-in-out;

    > span {
      display: block;
      width: 1.3rem;
      height: .1rem;
      background-color: #999;
    }
  }

  .filter-form-c {
    height: auto;
    overflow: hidden;

    .filter-form {
      input {
        margin-left: 1.5rem;
        @media (max-width: $breakpoint-xl) {
          margin-left: .5rem;
        }
      }

      label {
        font-size: 1.4rem;
        padding-left: .3rem;
      }
    }
  }
}

.filter-block.closed {
  .see-more {
    transform: rotate(90deg);
  }

  .filter-form-c {
    height: 0;
  }
}

/* Colonne de droite */
.products-list-col {
  .header-products-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #999;
    padding: 1.1rem 1rem 1.1rem 3.3rem;
    margin-bottom: 4rem;
    font-size: 1.4rem;

    @media (max-width: $breakpoint-md) {
      flex-wrap: wrap;
    }

    .nb-products {
      font-weight: 600;
      @media (max-width: $breakpoint-md) {
        order: 2;
      }
    }

    .orga-products {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 55rem;

      label {
        font-weight: 600;
        margin-bottom: 0;
      }

      .select-c {
        position: relative;
        cursor: pointer;
        padding-right: 2rem;

        select {
          height: 2.6rem;
          color: #999;
          background-color: #fff;
          border: none;
          appearance: none;
          outline: 0;
          cursor: pointer;
          padding: 0 1.5rem 0 .5rem;
        }

        &::after {
          content: "\EA12";
          display: block;
          position: absolute;
          top: .5rem;
          right: 3rem;
          font-family: kfont;
          font-size: 1rem;
        }
      }

      .icon {
        font-size: 2.8rem;
        line-height: 1;
        color: #999;

        &.current {
          color: $principale;
        }

        + .icon {
          margin-left: .8rem;
        }
      }
    }
  }

  &.grid-list {
    .products-list-line {
      display: none;
    }
  }

  &.table-list {
    .products-list-line {
      display: block;
    }

    .products-liste-card {
      display: none;
    }
  }
}

/* Pagination */
.pagination-catalogue {
  margin-top: 3rem;
  text-align: center;

  .count {
    font-size: 1.8rem;
  }

  .button {
    padding: 1.6rem 2.8rem;
    margin-top: 1.5rem;
  }
}

.products-liste-card {
  @media (max-width: $breakpoint-xl) {
    margin-right: -.5rem;
    margin-left: -.5rem;
  }
}

.card-product {
  @media (max-width: $breakpoint-lg) {
    width: calc(100% / 3 - 1rem);
  }
  @media (max-width: $breakpoint-md) {
    width: calc(100% / 2 - 1rem);
  }
  @media (max-width: $breakpoint-xs) {
    width: 100%;
  }
}

.products-list-line .product-line {
  @media (max-width: $breakpoint-md) {
    flex-wrap: wrap;
    .product-name {
      width: calc(100% - 12rem);
    }

    .prix {
      order: 4;
    }

    .add-cart {
      order: 5;
    }
  }

  @media (max-width: $breakpoint-xs) {
    .stock {
      margin-top: .6rem;
      margin-bottom: .6rem;
    }

    .add-cart {
      width: 100%;
    }

  }
}

.products-list-col .header-products-list {
  @media (max-width: $breakpoint-sm) {
    padding-left: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 1.2rem;
  }
  @media (max-width: $breakpoint-xs) {
    .nb-products {
      position: absolute;
      right: 3rem;
    }

    .orga-products {
      flex-direction: column;
      align-items: flex-start;

      > div {
        margin-bottom: 1rem;
      }
    }
  }
}


@media (max-width: $breakpoint-sm) {
  .img-category-container-col {
    order: 2;
    margin-top: 2.5rem;
  }
}

