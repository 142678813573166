.page-collection {
  #contenu section:not(.hero) {
    margin-bottom: 6rem;
  }

  .hero ~ #contenu {
    padding-top: 4rem;
  }

  .collection-detail {
    .pbold {
      font-weight: $weight-bold;
    }

    .box {
      display: flex;
      align-items: center;
      gap: 2.4rem;
      margin-top: 3.2rem;
      @media (max-width: $breakpoint-sm) {
        flex-direction: column;
      }

      & > .image {
        @include flex-center;
        min-width: calc((100% / 3) - 2.4rem);
        max-width: calc((100% / 3) - 2.4rem);
        object-fit: contain;
        border: .1rem solid $neutre_gris_300;
        border-radius: $radius-s;
        @media (max-width: $breakpoint-sm) {
          max-width: 100%;
        }

        &.no-image {
          object-fit: cover;
          aspect-ratio: 1;
        }
      }

      .description {
        & > *:not(:last-child) {
          margin-bottom: 1.6rem;
        }

        .pbold {
          font-weight: $weight-bold;
          font-size: 1.8rem;
        }
      }
    }
  }

  .ouvrages-collection {
    h2 {
      margin-bottom: 2.4rem;
    }

    .liste-produits {
      margin-bottom: 2.4rem;
    }

    .button {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

