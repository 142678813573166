/* Affichage date et tags */
.tag-label,
.tag-actu {
  display: inline-block;
  font-size: 1.2rem;
  padding: .2rem .5rem;
  color: $blanc;
  background-color: $principale;

  + .tag-label {
    margin-left: .2rem;
  }
}

/*** Article actif ***/
.article-content {
  margin-top: 3rem;

  .img-article-c {
    position: relative;

    .date-article {
      top: 1rem;
      right: 1rem;
    }
  }

  .article-txt {
    margin-top: 2rem;
  }
}
