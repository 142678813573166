/* Liste avec nombre indéterminé d'éléments */
.list-cards {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;

  &:has(.gellule) {
    justify-content: center;
  }
}

.list-wrap {
  flex-wrap: wrap;
}

/* Liste composée de 2 éléments */
.list-2-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2.4rem;
  @media (max-width: $breakpoint-lg) {
    gap: 1rem;
  }
  @media (max-width: $breakpoint-md) {
    .card {
      width: calc(100% / 1.5);
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media (max-width: $breakpoint-xs) {
    .card {
      width: 100%;
    }
  }

  .card {
    width: calc(50% - 1.2rem);
    height: auto;
    position: relative;
  }
}

/* Liste composée de 3 éléments */
.list-3-cards {
  display: flex;
  justify-content: center;
  gap: 2.4rem;
  @media (max-width: $breakpoint-lg) {
    gap: 1rem;
  }
  @media (max-width: $breakpoint-md) {
    flex-wrap: wrap;
  }

  .card {
    height: auto;
    width: calc(100% / 3);
    @media (max-width: $breakpoint-md) {
      width: calc(100% / 1.5);
      margin-left: auto;
      margin-right: auto;
    }
    @media (max-width: $breakpoint-xs) {
      width: 100%;
    }
  }
}

/* Liste composée de 4 éléments */
.list-4-cards {
  display: flex;
  justify-content: center;
  gap: 2.4rem;

  .card {
    width: calc(25% - 1.8rem);
  }

  @media (max-width: $breakpoint-lg) {
    gap: 1rem;
  }
  @media (max-width: $breakpoint-md) {
    flex-wrap: wrap;
    .card {
      width: calc(100% / 1.5);
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media (max-width: $breakpoint-xs) {
    .card {
      width: 100%;
    }
  }
}

/* Liste composée de 5 éléments */
.list-5-cards {
  display: flex;
  gap: 2.4rem;

  .card {
    width: calc(100% / 5);
  }

  @media (max-width: $breakpoint-lg) {
    gap: 1rem;
  }
  @media (max-width: $breakpoint-md) {
    flex-wrap: wrap;
    .card {
      width: calc(100% / 1.5);
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media (max-width: $breakpoint-xs) {
    .card {
      width: 100%;
    }
  }
}

/* Bloc avis + bloc auteurs */
.card-shadow {
  height: 100%;
  padding: 1rem;
  border-radius: $radius-s;
  border: .1rem solid $neutre_gris_300;
  background: $blanc;
  box-shadow: $box-shadow-alt-black;
  transition: box-shadow .5s;

  .content-card-shadow {
    display: flex;
    align-items: center;
  }

  .profil-card-shadow {
    display: flex;
    align-items: center;

    .name-card-shadow {
      font-weight: 700;
      margin-right: .5rem;
    }

    .title-card-shadow {
      margin-left: .8rem;
    }
  }
}

//a.card-shadow:hover {
//  box-shadow: $box-shadow-black;
//}

/* Gelules */
ul:has(.gelule) {
  justify-content: center;
  gap: 1.6rem;
  @media (max-width: $breakpoint-sm) {
    justify-content: left;
    gap: .8rem;
  }

  li {
    list-style: none;
    margin: 0 !important;

    .tag-element.gelule {
      position: relative;
      padding-top: 1rem;
      padding-bottom: 1rem;
      border: .1rem solid $neutre_gris_300;

      &::after {
        content: "\FA2C";
        font-family: kfont, sans-serif;
        font-size: 1.3rem;
        margin-left: 1.4rem;
        margin-right: .8rem;
        transition: margin .3s;
        z-index: 1;
      }

      .surligne.surligne-after {
        opacity: 0;
        right: 1.5rem;
        bottom: 0.5rem;
        font-size: 1rem;
        transition: opacity .3s;
      }

      &:hover .surligne.surligne-after {
        opacity: 1;
      }
    }
  }

}

/* Bloc carte produit et articles */

.card-article {
  border: none;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: $border-clear;
    border-radius: .8rem;
    pointer-events: none;
    transition: background-color .3s, border-color .3s;
  }

  .event-article {
    font-weight: 700;

    p {
      display: flex;
      align-items: center;
      margin-top: 1.6rem;
    }

    .icon {
      font-size: 2.4rem;
      margin-right: .8rem;
    }
  }
}

.card-article,
.card-product {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  transition: background-color .3s;

  &:hover {
    &::after {
      border-color: $principale;
    }
  }

  .img-card-c {
    text-align: center;
    background-color: $blanc;
    border-radius: .8rem;

    .coin-image-pj {
      position: absolute;
      top: -.1rem;
      right: .1rem;

      &:hover {
        &::before {
          font-size: 28rem;
        }

        .coin-image-content {
          .desc {
            opacity: 1;
            font-size: 1.6rem;
          }
        }
      }

      &::before {
        content: "\ECE4";
        font-family: kfont;
        display: block;
        position: absolute;
        top: -1rem;
        right: -1rem;
        font-size: 12rem;
        color: #fff;
        clip-path: polygon(0 0, 100% 100%, 100% 0);
        transition: font-size .2s ease-in-out;
      }

      .coin-image-content {
        position: relative;
        margin-top: 1.4rem;
        margin-right: 1rem;
        font-weight: 700;
        border-radius: 2.8rem;

        .box {
          @include flex-end;

          .icon {
            margin-right: .5rem;
          }
        }

        .desc {
          text-align: right;
          font-size: 0;
          font-weight: 400;
          opacity: 0;
          margin-top: .8rem;
          line-height: 1.2;
          transition: font-size .2s, opacity .2s ease-in-out;
        }
      }
    }

    .flag-image-content {
      img {
        max-width: 5.6rem;
        max-height: 2.8rem;
        object-fit: contain;
        margin-right: .6rem;
      }

      .libelle {
        text-transform: uppercase;
        font-size: 1.4rem;
      }

    }
  }

  .text-card-c {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: $blanc;
    border-radius: 0 0 $radius-s $radius-s;
    padding: 1rem 1.6rem;

    .infos-card-c {
      .titre-card {
        @include line-clamp(2);
        max-height: 4.3rem;
        margin-bottom: .8rem;
        font-size: 1.8rem;
      }

      .stitre-card {
        font-size: $font-xxs;
        margin-top: .6rem;
        margin-bottom: .4rem;
      }
    }

    .tarif-card-c {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .tarif {
        font-size: 1.8rem;
      }
    }
  }

  .tag-promo {
    position: absolute;
    top: 0;
    left: .8rem;
    transform: translateY(-50%);
  }
}

.card-article:has(.text-card-c) .img-card-c,
.card-product:has(.text-card-c) .img-card-c {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.multi-slider:has(.tag-promo) {
  margin-top: 1.5rem;
}

.card-product {
  background-color: $neutre_gris_100;
  transition: border .3s ease-in-out;
  height: 100%;

  &:hover {
    border: $border-main;
  }

  .img-card-c {
    display: flex;
    padding: 1rem;

    img {
      aspect-ratio: 240/310;
      width: 100%;
      object-fit: contain;
      max-height: 31rem;

      &.cover {
        object-fit: cover;
        height: 30rem;
      }
    }
  }

  .text-card-c {
    background-color: $neutre_gris_100;
    padding: 1.2rem;
    height: 100%;
  }
}

.card-article {
  .img-card-c {
    position: relative;
    overflow: hidden;
    border-top-right-radius: $radius-s;
    width: 100%;
    height: 100%;
    max-height: 24.8rem;

    & > img {
      border-bottom: $border-clear;
      border-top-left-radius: .8rem;
      border-top-right-radius: .8rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;

      &.cover {
        object-fit: cover;
      }
    }

    .flag-images {
      position: absolute;
      top: .8rem;
      left: .8rem;
      display: flex;
      align-items: center;
      gap: .8rem;
      height: 4rem;
    }
  }

  .text-card-c {
    justify-content: space-between;
    padding: 1.7rem 1.6rem;
    height: 100%;
    @media (max-width: $breakpoint-sm) {
      padding: 1rem .8rem;
    }

    .header-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: $breakpoint-sm) {
        flex-wrap: wrap;
        gap: .6rem;
      }

      .icon {
        font-size: 1.8rem;
        line-height: 0;
      }

      .icon-gsn-cadenas {
        font-size: 2.2rem;
        line-height: 1.6rem;
      }

      .icon-gsn-cadenas-ferme-plus::before {
        font-size: 2.6rem;
        line-height: 1.6rem;
      }

      .icon + .icon {
        margin-left: .6rem;
      }

      .type-date-article {
        display: flex;
        align-items: center;
        gap: .8rem;
        cursor: default;
      }

      .icones-article {
        display: flex;
        align-items: baseline;
        gap: .4rem;
      }
    }

    .liste-tags {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      gap: .4rem;
      margin-top: .8rem;
      cursor: default;
    }

    .h6 {
      margin: .8rem 0;
    }

    .desc-card {
      @include line-clamp(3);
    }

    .link-element {
      display: flex;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  .link-icon-fleche .surligne {
    bottom: -.7rem;
  }

  &:hover .link-element::after {
    transform: translateX(.3rem);
  }

  .footer-card {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-top: 2rem;

    .icon {
      font-size: 2.2rem;
    }
  }
}

.card-push {
  width: calc(100% / 2.5 - 1.44rem);
  height: auto;
  padding: 0;
  @media (max-width: $breakpoint-xl) {
    width: calc(50% - .6rem);
  }
  @media (max-width: $breakpoint-md) {
    width: calc(100% * 2 / 3 - .4rem);
  }
  @media (max-width: $breakpoint-sm) {
    width: 100%;
  }

  .img-card-c {
    height: 100%;
    object-fit: cover;

    img {
      border-radius: .8rem;
      height: 100%;
      width: 100%;
      object-fit: cover;
      @media (max-width: $breakpoint-xl) {
        object-fit: contain;
      }
    }
  }
}

.card-collection {
  display: block;

  .card-collection-content {
    display: flex;
    justify-content: space-between;
    gap: 1.6rem;
    height: 100%;

    .left-col {
      width: calc(100% / 3 * 2);
      padding: .8rem;
    }

    .right-col {
      @include flex-center;
      width: calc(100% / 3);

      img {
        border-radius: $radius-xs;
      }
    }

    .h6 {
      padding: .8rem 0;
    }

    .text {
      @include line-clamp(3)
    }

  }

  .toogle-favoris {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
}

.card-compagnon {
  position: relative;
  padding-bottom: 2.4rem;

  .card {
    width: 100%;
    padding: 0;
  }

  .col-left {
    display: flex;
    align-items: center;
    padding: 1.6REM;
    background-color: $neutre_gris_100;
    border-top-left-radius: .8rem;
    border-bottom-left-radius: .8rem;

    img {
      max-width: 11.5rem;
      width: 100%;
      aspect-ratio: 11.6/15.9;
      object-fit: cover;
    }
  }

  .col-right {
    width: 100%;
    height: 100%;
    padding: 4.3rem 1.2rem 0;

    @media (max-width: $breakpoint-xs) {
      padding-top: 4.8rem;
    }

    .tag-element {
      margin-bottom: .8rem;
    }

    .h6 {
      margin-bottom: .6rem;
    }
  }

  .fav-button {
    position: absolute;
    right: .8rem;
    bottom: 3.2rem;
    @media (max-width: $breakpoint-xs) {
      top: .5rem;
      bottom: auto;
    }
  }

  &:hover {
    .card {
      border-color: $principale;
    }

    .button {
      color: $cta;
      background-color: #fff;
      border: .1rem solid $cta;

      &.button-icon-externe::after {
        color: $cta;
      }
    }
  }

}

.button-card-compagnon {
  position: relative;
  top: -2.4rem;
  left: 50%;
  transform: translateX(-50%);
  width: 28rem;
}

.bloc-gris {
  display: flex;
  align-items: center;
  padding: 1.6rem;
  margin-bottom: 3rem;
  background-color: $neutre_gris_50;
  border: .1rem solid $neutre_gris_300;
  border-radius: $radius-m;
}

.bloc-blanc {
  padding: 1.6rem;
  background-color: #fff;
  border: .1rem solid $neutre_gris_300;
  border-radius: $radius-s;

  .bloc-visible-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $breakpoint-sm) {
      align-items: flex-start;
    }
    gap: 2rem;
  }

  .bloc-blanc-content {
    display: flex;
    align-items: center;
    gap: 2.5rem;
    @media (max-width: $breakpoint-sm) {
      align-items: flex-start;
    }

    img {
      max-width: 9.4rem;
      width: 100%;
      aspect-ratio: 9.4/13.3;
      object-fit: cover;
      @media (max-width: $breakpoint-sm) {
        margin: 0 auto;
      }
    }
  }

  .see-more {
    display: flex;
    align-items: center;
    gap: .8rem;
    width: 100%;
    max-width: 14.2rem;
    font-weight: 700;
    text-align: left;
    cursor: pointer;

    &:hover {
      .text-close,
      .text-open {
        text-decoration: underline;
      }
    }
  }

  &.disabled {
    background-color: $neutre_gris_100;

    .bloc-blanc-content img {
      filter: grayscale(1);
    }

    .h5 {
      color: $neutre_gris_800;
    }

    .see-more {
      display: none;
    }
  }

  .text-close {
    display: none;
  }

  &.open {
    .text-open {
      display: none;
    }

    .text-close {
      display: inline-block;
    }
  }

  .bloc-extra-content {
    padding-top: 2rem;
  }
}

.bloc-see-more {
  .bloc-extra-content {
    height: 0;
    overflow: hidden;
  }

  &.open {
    .icon-caret-bottom {
      transform: rotate(-180deg);
    }
  }
}