.fav-button {
  @include flex-center;
  background-color: $blanc;
  border-radius: 50rem;
  width: 4rem;
  height: 4rem;
  transition: background-color .3s ease-in-out;

  &:hover {
    background-color: $neutre_gris_300;
  }

  &.active .icon::before {
    content: "\EA2B";
  }

  .icon::before {
    font-size: 2rem;
    color: $principale;
  }
}