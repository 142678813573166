/* Panneau latéral des filtres de recherche */

.lateral-filters {
  position: fixed;
  top: 0;
  right: 0;
  width: 52rem;
  height: 100vh;
  padding: 2.4rem 1.6rem;
  transform: translateX(100%);
  //transform: translateX(0);
  transition: transform .5s ease-in-out;
  background-color: #fff;
  border-top: .1rem solid $neutre_gris_300;
  border-left: .1rem solid $neutre_gris_300;
  margin-right: .4rem;
  z-index: 10003;
  overflow-y: auto;

  @media (max-width: $breakpoint-sm) {
    width: 42rem;
  }

  @media (max-width: $breakpoint-xs) {
    width: calc(100% - 5rem);
  }

  .filter-line {
    border-bottom: .1rem solid $neutre_gris_300;
    padding-bottom: .6rem;
  }

  .box {
    background-color: white;
  }

  .h5 {
    text-align: center;
    margin-bottom: 3rem;

    .icon {
      margin-left: .8rem;
    }
  }

  .filter-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    width: 100%;
    padding-top: .8rem;
    padding-bottom: .8rem;
    margin-top: 1.2rem;
    margin-bottom: .5rem;
    cursor: pointer;
  }

  .cta-box {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    margin-top: 3rem;

    .button {
      width: 100%;
    }

    @media (max-width: $breakpoint-xs) {
      gap: 1rem;
      flex-direction: column;
      .button {
        width: 100%;
        max-width: 24rem;
        margin-left: auto;
        margin-right: auto;
      }
    }

  }

  .compteur-filtre {
    display: inline-block;
    width: 3rem;
    font-size: 1.2rem;
    color: #fff;
    text-align: center;
    padding: .4rem .7rem;
    margin-left: .8rem;
    background-color: $principale;
    border-radius: 4.8rem;
  }
}

body.filters-visible {
  .lateral-filters {
    transform: translateX(0);
  }
}

.checkbox-container {
  display: flex;
  align-items: center;

  input {
    margin-right: .6rem;
  }

  label {
    cursor: pointer;
  }
}